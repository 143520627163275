export const statistics = () => ({
    timer: 0,
    realTimer: 0,
    isTimerPaused: true,
    isPenaltyStarted: false,
    isMatchEnded: false,
    currentPeriod: -1,
    periodsEndDates: {},
    hasGameStarted: false,
    fiveMinutesIntervals: [],
    score: {
        1: 0,
        2: 0,
    },
    hasPeriodEnded: {
        1: false,
        2: false,
        3: false,
        4: false,
    },
    goals: {
        1: [],
        2: [],
    },
    penaltyShootout: {
        1: [],
        2: [],
    },
    removals: {
        1: [],
        2: [],
    },
    majority: {
        1: {
            removed: 0,
            success: [],
        },
        2: {
            removed: 0,
            success: [],
        },
    },
    puckOwning: {
        1: [],
        2: [],
    },
    attacks: {
        1: [],
        2: [],
    },
    faceOffWins: {
        1: [],
        2: [],
    },
    removalEnds: {
        1: [],
        2: [],
    },
    delayedPenalty: {
        1: false,
        2: false,
    },
    shotsOnGoal: {
        1: [],
        2: [],
    },
    videoRefereesCount: 0,
    faceOffInProgress: false,
    compensationTime: {
        1: null,
        2: null,
        3: null,
        4: null,
    },
});
