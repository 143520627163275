import React from 'react';
import PropTypes from 'prop-types';

import { AnimationMap } from 'features/basketball/constants';
import { FreeThrow } from './common/FreeThrow';

export function FreeThrowAnimation(props) {
    const { team } = props;

    return (
        <FreeThrow
            team={team}
            title="action.freeThrow.animation"
            subText="statistics.freeThrows"
            animationName={AnimationMap.FreeThrow}
        />
    );
}

FreeThrowAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

FreeThrowAnimation.ANIMATION_NAME = AnimationMap.FreeThrow;
