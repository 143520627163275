import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { getTimeText } from 'features/handball/models/common';
import { AnimationMap, ModelMap } from 'features/rugby/constants';

export const LineOut = new Event({
    type: ModelMap.LineOut,
    animationName: AnimationMap.LineOut,
    pushToLog(log, eventData, store) {
        const { statistics } = store.translationData;
        const { currentPeriod } = statistics;

        log.push({
            component: 'DefaultLogEvent',
            text: i18n.t('log.lineOut'),
            period: currentPeriod,
            type: 'lineOut',
            timeText: getTimeText(eventData.time, statistics),
            team: eventData.team,
            time: eventData.time,
            id: eventData.id,
        });
    },
});
