import React from 'react';
import PropTypes from 'prop-types';

import { AnimationMap } from 'features/basketball/constants';
import { FreeThrow } from './common/FreeThrow';

export function FreeThrowCanceledAnimation(props) {
    const { team } = props;

    return (
        <FreeThrow
            team={team}
            title="action.freeThrowCanceled.animation"
            subText="statistics.freeThrows"
            animationName={AnimationMap.FreeThrowCanceled}
        />
    );
}

FreeThrowCanceledAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

FreeThrowCanceledAnimation.ANIMATION_NAME = AnimationMap.FreeThrowCanceled;
