import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/floorball/constants';
import { TitleCaption } from './common/TitleCaption';

export function PeriodStartAnimation(props) {
    const { period } = props;
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div ref={nodeRef}>
            <TitleCaption
                title={translate('period.start.animation')}
                caption={translate('period.byIndex', { period })}
            />
        </div>
    );
}

PeriodStartAnimation.propTypes = {
    period: PropTypes.number.isRequired,
};

PeriodStartAnimation.ANIMATION_NAME = AnimationMap.PeriodStart;
