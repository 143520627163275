import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/floorball/constants';

export const TimeoutEvent = new Event({
    type: ModelMap.Timeout,
    animationName: AnimationMap.Timeout,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isTimerPaused = true;
    },
});
