/* eslint-disable no-mixed-operators */
import { AnimationMap, ModelMap } from 'features/basketball3x3/constants';
import { Event } from 'features/common/models/event.model';
import { getLocalTime } from 'features/common/utils';

export const PeriodEndEvent = new Event({
    type: ModelMap.PeriodEnd,
    // renames: ['period'],
    animationName: AnimationMap.PeriodEnd,
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        statistics.isTimerPaused = true;
        statistics.periodsEndDates[evtData.period] = getLocalTime(evtData.regtime);
        statistics.timer = 0;
    },
    pushAdditionalEvents(evtData, store) {
        const { info, statistics } = store.translationData;
        // let someVar;
        // eslint-disable-next-line no-cond-assign
        // const { periodDuration } = (someVar = info) === null || undefined === someVar
        //     ? undefined
        //     : someVar.extra;
        const { periodDuration } = info.extra;
        const { periodsEndDates } = statistics;
        const periodsEndDatesLen = Object.keys(periodsEndDates).length;

        return (periodDuration === 20 && periodsEndDatesLen === 1) ||
            ((periodDuration === 10 || periodDuration === 12) && periodsEndDatesLen < 4)
            ? [{ ...evtData, type: ModelMap.PeriodCountdown }]
            : [];
    },
});
