import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import gsap from 'gsap';

import { useCallbackTL, useNodeTimeline } from 'hooks';
// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/volleyball/constants';
import { FieldText } from './common/FieldText';
import { HalfRectangle } from './common/HalfRectangle';

export function ScoreAnimation(props) {
    const { team, scoreType } = props;
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;
    const { scoreChanges } = statistics;
    const lastData = scoreChanges[scoreChanges.length - 1];
    const score = {
        [homeTeam]: lastData.score1,
        [awayTeam]: lastData.score2,
    };

    const textRef = useRef(null);
    const textRect = useRef(null);

    const textTimeLine = useRef(
        gsap.timeline({
            repeat: 0,
        }),
    );

    const scoreRef = useRef(null);
    const homeRef = useRef(null);
    const awayRef = useRef(null);

    const teamRef = team === homeTeam ? homeRef : awayRef;
    const scoreDifference = scoreType === 5 ? 2 : 1;
    const initialScore = {
        ...score,
        [team]: score[team] - scoreDifference,
    };

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);
    useEffect(() => {
        textTimeLine.current
            .to([textRef.current, textRect.current], {
                opacity: 0,
                delay: 2,
                duration: 0.2,
            })
            .fromTo(
                scoreRef.current,
                {
                    opacity: 0,
                    scale: 0,
                    display: 'none',
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 0.5,
                    display: 'flex',
                },
            )
            .fromTo(teamRef.current, { scale: 1 }, { scale: 0.5, opacity: 0 })
            .set(teamRef.current, { innerText: initialScore[team] + scoreDifference })
            .to(teamRef.current, { scale: 1, opacity: 1 });

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            textTimeLine.current.kill();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeRef]);

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} isAnimated={false} ref={textRect} />
            <FieldText
                ref={textRef}
                title="+1"
                team={team}
                icon="score"
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <div ref={scoreRef} className="vb-score">
                <p ref={homeRef} className="z-10 min-width-20" style={{ opacity: team === 1 ? 1 : 0.5 }}>
                    {initialScore[homeTeam]}
                </p>
                <p>:</p>
                <p ref={awayRef} className="z-10 min-width-20" style={{ opacity: team === 1 ? 0.5 : 1 }}>
                    {initialScore[awayTeam]}
                </p>
            </div>
        </div>
    );
}

ScoreAnimation.propTypes = {
    team: PropTypes.number.isRequired,
    scoreType: PropTypes.number.isRequired,
};

ScoreAnimation.ANIMATION_NAME = AnimationMap.Score;
