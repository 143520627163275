export const ModelMap = {
    BallPosition: 2681,
    IsBallPosition: -2112,
    BallPossession: 1113,
    Goal: 1100,
    CornerKick: [1101, -10007],
    CornerKickWithPosition: [2682],
    CornerKickWithCornerNumber: -10008,
    CornerWithoutAnimation: 1252,
    YellowCard: 1108,
    Substitution: 1111,
    RedCard: 1109,
    Injury: 1574,
    PeriodStart: 1118,
    PenaltyGoal: 1152,
    PenaltyMiss: 1154,
    Penalty: 1110,
    PenaltyShootoutStart: 1165,
    PenaltyShootoutEnd: 1166,
    MatchEnd: 1103,
    HalfTimeEnd: 1102,
    CompensationTime: 1104,
    TimerStarted: 1019,
    MatchDuration: 2011,
    Out: 1164,
    OutWithPosition: -10003,
    Offside: 1196,
    Foul: 1197,
    FreeKick: [1116, 1117, 1169],
    FreeKickSportLevel: [11110354, 11110355, 11110356],
    DangerousFreeKick: 1117,
    FreeKickWithPosition: -10002,
    FreeKickMiss: [1332, -1332],
    ShotOnGoal: 1114,
    ShotOnGoalWithPosition: -10004,
    ShotOnGoalMiss: 1115,
    ShotOnGoalMissWithPosition: -10005,
    Attack: 1106,
    DangerousAttack: 1107,
    TimerPaused: 1008,
    TimerPausedFalse: 1112,
    TimerPausedTrue: 1003,
    PlayersOnField: 1206,
    TimePausedPreventFalse: 1503,
    TimePausedPreventTrue: 1504,
    KickFromGoal: 1168,
    KickFromGoalWithPosition: -10006,
    VideoReferee: 1067,
    Fight: 1170,
};
