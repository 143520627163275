import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap, ModelMap } from '../constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { HalfRectangle } from './common/HalfRectangle';

export function FreeKicksAnimation(props) {
    const { type, team } = props;
    const translateTemplate = ((evtType) => {
        if (evtType === ModelMap.FreeKick) return 'action.freeKick.animation';
        if (evtType === ModelMap.ConversionPosition) return 'action.conversionPosition.animation';
        if (evtType === ModelMap.TwentyTwoMetreDropOut) return 'action.twentyTwoMetreDropOut.animation';
        return 'action.penaltyKick.animation';
    })(type);

    const [translate] = useTranslation();
    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate(translateTemplate)}
                team={team}
                isWithoutStats
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                isPercent
            />
            <FieldIcon animationName="freeKick" team={team} />
        </div>
    );
}

FreeKicksAnimation.propTypes = {
    type: PropTypes.number.isRequired,
    team: PropTypes.number.isRequired,
};

FreeKicksAnimation.ANIMATION_NAME = AnimationMap.FreeKick;
