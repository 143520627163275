import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { useEventsLoadingState } from './useEventsLoadingState';

export const useErrorConfig = () => {
    const [translate] = useTranslation('common');
    const { current } = useContext(RootMachineContext);
    const { isFailed, isDisabled } = useEventsLoadingState(RootMachineContext);
    const { error } = current.context;

    function handleRefresh() {
        window.location.reload(false);
    }

    return {
        translate,
        isFailed,
        isDisabled,
        error,
        handleRefresh,
    };
};
