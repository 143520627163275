import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';

export const TeamTimeoutEvent = new Event({
    type: ModelMap.TeamTimeout,
    animationName: AnimationMap.TeamTimeout,
    renames: ['team'],
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isTimerPaused = true;
    },
});
