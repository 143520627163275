import { AnimationMap, ModelMap } from 'features/basketball/constants';
import { Event } from 'features/common/models/event.model';
import { getFreeThrowStatus, getLastItemIndex } from './scoreChange.model';

export const FreeThrowCanceledEvent = new Event({
    type: ModelMap.FreeThrowCanceled,
    // renames: ['time', 'period'],
    animationName: AnimationMap.FreeThrowCanceled,
    calculate(store, evtData) {
        const { log, statistics } = store.translationData;
        getFreeThrowStatus(statistics, 'canceled');
        const lastFreeThrowIdx = getLastItemIndex(log, (t) => t.type === 'freeThrow');
        // eslint-disable-next-line no-param-reassign
        evtData.team = log[lastFreeThrowIdx].team;
    },
    isPreventProcessing(_, store) {
        const { statistics } = store.translationData;
        return !getFreeThrowStatus(statistics);
    },
});
