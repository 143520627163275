import React from 'react';

import { useTargetName } from 'hooks';
import { DefaultLoader } from './DefaultLoader';
import { ViviLoader } from './ViviLoader';

export const Loader = ({ className }) => {
    const {
        conditions: { isVivi },
    } = useTargetName();

    switch (true) {
        case isVivi:
            return <ViviLoader className={className} />;
        default:
            return <DefaultLoader className={className} />;
    }
};
