import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/football/constants';
import { ModelMap } from 'features/football/constants/model.constants';
import { useFreeKickStatistic } from 'features/football/hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { FreeKickCircles } from './common/FreeKickCircles';
import { HalfRectangle } from './common/HalfRectangle';

export function FreeKickAnimation(props) {
    const { team, type } = props;
    const [translate] = useTranslation();
    const rectRef = useRectTimeline();

    const nodeRef = useNodeTimeline();
    const { awayStat, homeStat, label: subtext } = useFreeKickStatistic();

    function getTitle() {
        if (type === ModelMap.FreeKick[1]) {
            return translate('action.dangerousFreeKick.animation');
        }
        return translate('action.freeKick.animation');
    }
    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={getTitle()}
                subText={subtext}
                homeStat={awayStat}
                awayStat={homeStat}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName="freeKick" team={team} />
            <FreeKickCircles team={team} circles={6} />
        </div>
    );
}

FreeKickAnimation.propTypes = {
    team: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
};

FreeKickAnimation.ANIMATION_NAME = AnimationMap.FreeKick;
