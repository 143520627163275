import { AnimationMap } from 'features/football/constants';
import { useAttackStatistic } from './useAttackStatistic';
import { useCornerKickStatistic } from './useCornerKickStatistic';
import { useFreeKickStatistic } from './useFreeKickStatistic';
import { useInjureStatistic } from './useInjureStatistic';
import { useKickFromGoalStatistic } from './useKickFromGoalStatistic';
import { useOffsideStatistic } from './useOffsideStatistic';
import { useOutStatistic } from './useOutStatistic';
import { usePenaltyGoalStatistic } from './usePenaltyGoalStatistic';
import { usePenaltyStatistic } from './usePenaltyStatistic';
import { useRedCardStatistic } from './useRedCardStatistic';
import { useSubstitutionStatistic } from './useSubstitutionStatistic';
import { useYellowCardStatistic } from './useYellowCardStatistic';

export const useStatisticHookMap = (name) => {
    const map = {
        [AnimationMap.Attack]: useAttackStatistic,
        [AnimationMap.BallPossession]: useAttackStatistic,
        [AnimationMap.DangerousAttack]: useAttackStatistic,
        [AnimationMap.CornerKick]: useCornerKickStatistic,
        [AnimationMap.FreeKickMiss]: useFreeKickStatistic,
        [AnimationMap.FreeKick]: useFreeKickStatistic,
        [AnimationMap.Injury]: useInjureStatistic,
        [AnimationMap.KickFromGoal]: useKickFromGoalStatistic,
        [AnimationMap.Offside]: useOffsideStatistic,
        [AnimationMap.Out]: useOutStatistic,
        [AnimationMap.Penalty]: usePenaltyStatistic,
        [AnimationMap.PenaltyGoal]: usePenaltyGoalStatistic,
        [AnimationMap.PenaltyMiss]: usePenaltyGoalStatistic,
        [AnimationMap.PenaltyShootoutEnd]: usePenaltyGoalStatistic,
        [AnimationMap.PenaltyShootoutStart]: usePenaltyGoalStatistic,
        [AnimationMap.RedCard]: useRedCardStatistic,
        [AnimationMap.ShotOnGoal]: useFreeKickStatistic.bind(null, 'statistics.shotsOnGoal'),
        [AnimationMap.ShotOnGoalMiss]: useFreeKickStatistic.bind(null, 'statistics.shotsOnGoal'),
        [AnimationMap.Substitution]: useSubstitutionStatistic,
        [AnimationMap.YellowCard]: useYellowCardStatistic,
    };

    return map[name] ? map[name]() : null;
};
