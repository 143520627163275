import { useEffect, useState } from 'react';

export function useContainerHeight(defaultHeight) {
    const [height, setHeight] = useState(defaultHeight);
    const windowHeight = window.innerHeight;
    useEffect(() => {
        const heightDeps = ['#navigation', '#event-tabs'];
        const deps = heightDeps.map((dep) => document.querySelector(dep)).filter((el) => !!el);
        const offsetHeight = deps.reduce((acc, cum) => acc + cum.offsetHeight, 0);
        setHeight(windowHeight - offsetHeight);
    }, [windowHeight]);

    return height;
}
