import { assign } from 'xstate';

import { hasLength } from 'lib';
import { getlastEventId } from 'features/common/services/event.service';

export const initialEvents = {
    invoke: {
        id: 'initialEvents-invoke',
        src: 'translationEvents',
        input: ({ context }) => ({
            translationId: context.translationId,
            lastEventId: context.lastEventId,
        }),
        onDone: [
            { target: 'initialEventsDelay', guard: 'hasGotNoInitialEvents' },
            {
                target: 'delay',
                actions: assign({
                    store: ({ context, event }) => {
                        context.eventProcessor.processInitialEvents(event.output);
                        const { log, statistics } = context.eventProcessor.store.translationData;
                        return {
                            ...context.store,
                            translationData: {
                                ...context.store.translationData,
                                log,
                                statistics: { ...statistics },
                            },
                        };
                    },
                    lastEventId: ({ context, event }) => {
                        if (hasLength(event.output)) {
                            return getlastEventId(event.output);
                        }
                        return context.lastEventId;
                    },
                }),
            },
        ],
        onError: {
            target: 'failure',
            actions: assign({ error: ({ event }) => event.error }),
        },
    },
};
