export const ModelMap = {
    Foul: -1121,
    Ace: -1120,
    Serve: -1119,
    PeriodEnd: -1102,
    MatchEnd: -1103,
    MatchCountdown: -2e4,
    PeriodCountdown: -20001,
    SetOpeningTeam: 1212,
    ServingTeam: 1171,
    Score: 1211,
    AcePlus: 1120,
    FoulPlus: 1121,
    PeriodStart: 1118,
    PlayersWarmup: 1031,
    Block: 1184,
    Timeout: 1128,
    ServePlus: 1119,
};
