import React, { Suspense, useCallback, useState, useTransition } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LoaderContextProvider } from 'context';

import { getPageStyles } from 'lib/getPageStyles';
import { useAddTargetNameClassToBody } from 'hooks';
import { Loader } from 'ui';
import { TARGETS } from 'features/common/constants';
import { routes } from './routes';

const pageStylesImports = {
    [TARGETS.MOSTBET]: async () => import('assets/scss/common/app/app--mostbet.scss'),
    [TARGETS.BETANDREAS]: async () => import('assets/scss/common/app/app--betandreas.scss'),
    [TARGETS.VIVI]: async () => import('assets/scss/common/app/app--vivi.scss'),
};

await getPageStyles(pageStylesImports);

export function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [, startTransition] = useTransition();

    const setLoading = useCallback(
        (loading) => {
            startTransition(() => {
                setIsLoading(loading);
            });
        },
        [startTransition],
    );

    useAddTargetNameClassToBody();

    return (
        <>
            {isLoading && <Loader className="app-loader" />}
            <LoaderContextProvider setLoading={setLoading}>
                <Suspense>
                    <Router>
                        <Switch>
                            {routes.map((route) => (
                                <Route key={route.name} path={route.path} component={route.component} />
                            ))}
                        </Switch>
                    </Router>
                </Suspense>
            </LoaderContextProvider>
        </>
    );
}
