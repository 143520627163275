export const WindowProportions = {
    defaultWidthProportion: 700,
    defaultHeightProportion: 378,
    defaulMobiletWidthProportion: 300,
    defaultMobileHeightProportion: 170,
    heightRatio() {
        return WindowProportions.defaultHeightProportion / WindowProportions.defaultWidthProportion;
    },
    heightMobileRatio() {
        return WindowProportions.defaulMobiletWidthProportion / WindowProportions.defaultMobileHeightProportion;
    },
};
