export const statistics = () => ({
    ballPossessionData: {
        1: [],
        2: [],
    },
    goals: {
        1: [],
        2: [],
    },
    penaltyShootout: {
        1: [],
        2: [],
    },
    attacks: {
        1: [],
        2: [],
    },
    dangerousAttacks: {
        1: [],
        2: [],
    },
    yellowCards: {
        1: [],
        2: [],
    },
    redCards: {
        1: [],
        2: [],
    },
    shotOnGoals: {
        1: [],
        2: [],
    },
    freeKicks: {
        1: [],
        2: [],
    },
    substitutions: {
        1: [],
        2: [],
    },
    missedGoalKicks: {
        1: [],
        2: [],
    },
    penalties: {
        1: [],
        2: [],
    },
    outs: {
        1: [],
        2: [],
    },
    cornerKicks: {
        1: [],
        2: [],
    },
    cornerKickWithPosition: null,
    fouls: {
        1: [],
        2: [],
    },
    kicksFromGoal: {
        1: [],
        2: [],
    },
    offsides: {
        1: [],
        2: [],
    },
    injuries: {
        1: [],
        2: [],
    },
    ballPoint: undefined,
    lastPossessionTeam: 0,
    lastActualPossessionTeam: undefined,
    lastBallPositionCalculateResult: 'unchanged',
    currentPeriod: -1,
    hasPeriodStarted: {
        1: false,
        2: false,
        3: false,
        4: false,
    },
    hasPeriodEnded: {
        1: false,
        2: false,
        3: false,
        4: false,
    },
    isOvertime: false,
    isPenaltyStarted: false,
    isTimerPaused: false,
    gameTimePenalty: false,
    isMatchEnded: false,
    timer: 0,
    matchDuration: 40,
    compensationTime: {
        1: null,
        2: null,
        3: null,
        4: null,
    },
});
