import { AnimationMap } from 'features/tableTennis/constants';
import { BouncesAnimation } from './bounces.animation';
import { BouncesScoreAnimation } from './bouncesScore.animation';
import { MatchCountdownAnimation } from './matchCountdown.animation';
import { MatchEndAnimation } from './matchEnd.animation';
import { PenaltyPointAnimation } from './penaltyPoint.animation';
import { RemovalAnimation } from './removal.animation';
import { ScoreAnimation } from './score.animation';
import { ServeAnimation } from './serve.animation';
import { SetEndAnimation } from './setEnd.animation';
import { SetStartAnimation } from './setStart.animation';
import { TeamTimeoutAnimation } from './teamTimeout.animation';
import { TimeoutAnimation } from './timeout.animation';
import { WarningAnimation } from './warning.animation';

export const animationFunctions = [
    ServeAnimation,
    BouncesAnimation,
    BouncesScoreAnimation,
    MatchCountdownAnimation,
    MatchEndAnimation,
    SetStartAnimation,
    SetEndAnimation,
    ScoreAnimation,
    PenaltyPointAnimation,
    TimeoutAnimation,
    TeamTimeoutAnimation,
    WarningAnimation,
    RemovalAnimation,
];

export const animationFnMap = {
    [AnimationMap.Serve]: ServeAnimation,
    [AnimationMap.Bounces]: BouncesAnimation,
    [AnimationMap.BouncesScore]: BouncesScoreAnimation,
    [AnimationMap.MatchCountdown]: MatchCountdownAnimation,
    [AnimationMap.MatchEnd]: MatchEndAnimation,
    [AnimationMap.PenaltyPoint]: PenaltyPointAnimation,
    [AnimationMap.Removal]: RemovalAnimation,
    [AnimationMap.SetEnd]: SetEndAnimation,
    [AnimationMap.SetStart]: SetStartAnimation,
    [AnimationMap.TeamTimeout]: TeamTimeoutAnimation,
    [AnimationMap.Timeout]: TimeoutAnimation,
    [AnimationMap.Warning]: WarningAnimation,
    [AnimationMap.Score]: ScoreAnimation,
};
