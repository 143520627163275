export const ModelMap = {
    MatchCountdown: -2e4,
    Score: -1180,
    BouncesScore: -11802681,
    Serve: -1174,
    SetStart: 1118,
    SetEnd: 1102,
    MatchEnd: -1103,
    TeamTimeout: -1128,
    Timeout: -11280,
    Warning: 1108,
    Removal: 1109,
    PenaltyPoint: -11804,
    Bounces: -2681,
};
