/* eslint-disable max-len */
import React from 'react';

export function Check(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 19">
            <path
                fillRule="evenodd"
                d="M26.167 1.939L9.439 18.667a.999.999 0 01-1.414 0L.833 11.475a.999.999 0 111.414-1.414l6.485 6.485L24.753.525a1 1 0 011.414 1.414z"
            />
        </svg>
    );
}
