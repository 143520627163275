import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/futsal/constants';
import { Circle } from './common/Circle';

export function PenaltyShootoutStartAnimation() {
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam: HOME, awayTeam: AWAY } = store;
    const { statistics } = store.translationData;
    const { penaltyShootout } = statistics;
    const homeLen = penaltyShootout[HOME].length;
    const awayLen = penaltyShootout[AWAY].length;

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <Circle
                homeStat={homeLen}
                awayStat={awayLen}
                title={translate('action.PenaltyShootoutStart.animation')}
                animationName="penaltySeries"
                ref={rectRef}
                withStats
            />
        </div>
    );
}

PenaltyShootoutStartAnimation.ANIMATION_NAME = AnimationMap.PenaltyShootoutStart;
