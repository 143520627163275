export const statistics = () => ({
    timer: 0,
    isTimerPaused: true,
    isMatchEnd: false,
    scoreChanges: [],
    colors: {
        1: { mainColor: '#B060F7', secondColor: '#B060F7' },
        2: { mainColor: '#F6A623', secondColor: '#F6A623' },
    },
    sets: [
        {
            number: 1,
            score: { 1: 0, 2: 0 },
            isStart: false,
            isEnd: false,
        },
    ],
    score: { 1: 0, 2: 0 },
    servesData: { currentTeam: 1, servePosition: 'right' },
    serves: { 1: [], 2: [] },
});
