import React from 'react';

import { AnimationMap } from 'features/basketball/constants';
import { IconText } from './common/IconText';

export function TimeoutAnimation() {
    return <IconText icon="timeout" text="action.timeout.animation" />;
}

TimeoutAnimation.ANIMATION_NAME = AnimationMap.Timeout;
