/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */

import * as React from 'react';
import PropTypes from 'prop-types';

import { useTargetName } from 'hooks';

const MostBetVariant = ({ subtype }) => {
    const colorMap = {
        yellow: '#F1E469',
        red: '#E4A6A6',
    };

    return (
        <svg width={28} height={25} viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1H1v14h5v-5h2v5h4V1Z" fill={colorMap[subtype]} />
            <path
                d="M14 6c-.35 0-.69.07-1 .18V0H0v16h4v8h8.48L17 18.35V9c0-1.65-1.35-3-3-3ZM2 14V2h9v12h-1v-3c0-1.65-1.35-3-3-3s-3 1.35-3 3v3H2Zm13 3.65L11.52 22H6V11c0-.55.45-1 1-1s1 .45 1 1v5h5V9c0-.55.45-1 1-1s1 .45 1 1v8.65Z"
                fill="#305E17"
            />
        </svg>
    );
};

const BetAndreasVariant = ({ subtype }) => {
    const colorMap = {
        yellow: '#FFD900',
        red: '#C94A61',
    };

    return (
        <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={colorMap[subtype]}
                d="M17.076 4.718 15.98 4.26v7.388l1.988-4.794a1.638 1.638 0 0 0-.892-2.136ZM1.12 7.745l4.059 9.778a1.644 1.644 0 0 0 1.48 1.03c.213 0 .434-.04.647-.13l6.03-2.496c.614-.253.99-.859 1.006-1.464a1.872 1.872 0 0 0-.106-.663l-4.091-9.777A1.598 1.598 0 0 0 8.665 3c-.213 0-.425.05-.63.123L2.013 5.618a1.631 1.631 0 0 0-.892 2.127Zm13.214-3.109A1.636 1.636 0 0 0 12.699 3h-1.187l2.823 6.824"
            />
        </svg>
    );
};
const ViviVariant = ({ subtype }) => {
    const colorMap = {
        yellow: '#FFC131',
        red: '#ED2E14',
    };

    return (
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd">
                <path
                    d="M.1 2.8C.1 1.32 1.32.1 2.8.1h3.9c1.48 0 2.7 1.22 2.7 2.7v4.4a.5.5 0 0 1-1 0V2.8c0-.92-.78-1.7-1.7-1.7H2.8c-.92 0-1.7.78-1.7 1.7v7.1c0 .92.78 1.7 1.7 1.7h1.4a.5.5 0 0 1 0 1H2.8A2.72 2.72 0 0 1 .1 9.9V2.8Z"
                    fill={colorMap[subtype]}
                />
                <path
                    d="M10.61 4.59a.5.5 0 0 1 .7-.08c.5.4 1.12.92 1.64 1.44 1.93 1.92 2.34 4.26 2.64 5.96.1.54.19 1.41.23 2.13a57.03 57.03 0 0 1 .08 1.23v.11a.5.5 0 0 1-1 .04v-.1a51.03 51.03 0 0 0-.07-1.21c-.05-.73-.13-1.55-.22-2.01v-.01c-.3-1.7-.69-3.76-2.36-5.44-.48-.48-1.07-.96-1.56-1.36a.5.5 0 0 1-.08-.7Zm.23 3.48a.5.5 0 0 1 .69.17 2.86 2.86 0 0 1 .36.94v.07l.01.02v.02l-.5.01h.5v1.1a.5.5 0 0 1 0 .07c-.17 1.14-1.6 1.46-2.27.67a6.02 6.02 0 0 0-.2-.18 22.65 22.65 0 0 0-.93-.79 3 3 0 0 0-.3-.21 1.15 1.15 0 0 0-.73-.06.64.64 0 0 0-.4.36c-.03.15-.04.26-.03.35a.94.94 0 0 0 .24.55l.14.15c1.2.8 1.73 1.54 2.15 2.2l.1.16c.36.56.61.96 1.19 1.3a.5.5 0 1 1-.52.86c-.78-.47-1.15-1.06-1.5-1.61l-.11-.18a5.8 5.8 0 0 0-1.98-1.99h-.01a2.6 2.6 0 0 1-.49-.63 1.93 1.93 0 0 1-.1-1.52c.2-.47.55-.8 1.01-.95a2.16 2.16 0 0 1 1.46.1c.15.08.33.2.49.33a13.73 13.73 0 0 1 1.28 1.1c.06.08.17.12.3.09.1-.03.19-.1.21-.21V9.3l-.02-.1c-.02-.08-.08-.24-.2-.45a.5.5 0 0 1 .16-.69Z"
                    fill="#444188"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

const Card = (props) => {
    const { classMap: icon } = useTargetName((targets) => ({
        [targets.MOSTBET]: <MostBetVariant {...props} />,
        [targets.BETANDREAS]: <BetAndreasVariant {...props} />,
        [targets.VIVI]: <ViviVariant {...props} />,
    }));

    return icon;
};
Card.propTypes = {
    subtype: PropTypes.string,
};
export default Card;
