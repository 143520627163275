/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/beachVolleyball/constants';
import { Event } from './event.model';

export const PeriodCountdownEvent = new Event({
    type: ModelMap.PeriodCountdown,
    animationName: AnimationMap.PeriodCountdown,
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        const { periodsEndDates } = statistics;
        const timeStamp = 12e4 - store.countdown.currentTime + periodsEndDates[evtData.period].getTime();

        store.countdown.countdown = Math.max(timeStamp, 0);
        store.countdown.enabled = true;
    },
    isPreventProcessing: (_, store) => !store.countdown.needToShow,
});
