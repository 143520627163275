/* eslint import/no-cycle: 0 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getQsValue } from 'lib';
import { CurrentScreen, CurrentScreenContext } from 'features/common/providers/CurrentScreenProvider';
import { CourseTab } from './CourseTab';
import { FieldTab } from './FieldTab';
import { StatisticsTab } from './StatisticsTab';

function TabHeader(props) {
    const { screen, ...rest } = props;
    switch (screen) {
        case `${CurrentScreen.field}`:
            return <FieldTab {...rest} screen={screen} />;
        case `${CurrentScreen.statistics}`:
            return <StatisticsTab {...rest} screen={screen} />;
        case `${CurrentScreen.course}`:
            return <CourseTab {...rest} screen={screen} />;
        default:
            throw new Error('Invalid Screen name');
    }
}

TabHeader.propTypes = {
    screen: PropTypes.string.isRequired,
};

export function TabHeaders(props) {
    const { tabs, isLoading } = props;
    const context = useContext(CurrentScreenContext);
    const { currentScreen, setCurrentScreen } = context;
    const screenFromQs = getQsValue('tab');

    const navCls = cx(
        'flex',
        'font-medium',
        'common-bg-secondary-light',
        'divide-r-2-white',
        'overflow-auto',
        'text-base',
        'sm:rounded-tl-12px',
        'sm:rounded-tr-12px',
        {
            'pointer-events-none': isLoading,
            'pointer-events-auto': !isLoading,
        },
    );
    if (screenFromQs) return null;
    return (
        <div style={{ opacity: `${isLoading ? 0.5 : 1}` }} id="navigation" className={navCls}>
            {tabs.map((tab) => {
                const isActive = currentScreen === tab.screen;
                const wrapperClasses = cx(
                    'flex',
                    'items-center',
                    'common-text-primary-light',
                    'cursor-pointer',
                    'p-2',
                    { 'bg-white': isActive },
                );

                const textClasses = cx(
                    'hidden',
                    'xs:flex',
                    { 'common-text-secondary-accent': !isActive },
                    { 'common-text-primary-accent': isActive },
                );
                return (
                    <TabHeader
                        key={tab.id}
                        screen={tab.screen}
                        setActiveScreen={setCurrentScreen}
                        wrapperClasses={wrapperClasses}
                        textClasses={textClasses}
                    />
                );
            })}
        </div>
    );
}

TabHeaders.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            screen: PropTypes.string,
        }),
    ).isRequired,
};
