export const Ve = (store, eventData) => {
    const { servesData, scoreChanges, sets } = store.translationData.statistics;
    const { score1, score2, period } = eventData;
    const currentSet = sets.find((set) => set.number === period);
    if (!currentSet.isStart) currentSet.isStart = true;
    currentSet.score[1] = score1;
    currentSet.score[2] = score2;
    let servingTeam;
    let wonTeam;
    const currentScore = scoreChanges[scoreChanges.length - 1];
    if (currentScore) {
        if (score1 + score2 === 0) {
            wonTeam = currentScore.win;
        } else if ((score1 === 0 && score2 === 1) || (score1 === 1 && score2 === 0)) {
            // if ((score1 === 0 && score2 === 1) || (score1 === 1 && score2 === 0)) {
            wonTeam = score1 > score2 ? 1 : 2;
            // } else {
            //     wonTeam = currentScore.score1 < score1 ? 1 : 2;
            // }

            // wonTeam = score1 === 0 && score2 === 1 || score1 === 1 && score2 === 0
            //     ? score1 > score2
            //         ? 1
            //         : 2
            //     : currentScore.score1 < score1
            //         ? 1 : 2;
        } else {
            wonTeam = currentScore.score1 < score1 ? 1 : 2;
        }
        servingTeam = currentScore.win;

        // (score1 + score2 === 0
        //     ? ({ win: wonTeam } = currentScore)
        //     : wonTeam = score1 === 0 && score2 === 1 || score1 === 1 && score2 === 0
        //         ? score1 > score2
        //             ? 1
        //             : 2
        //         : currentScore.score1 < score1
        //             ? 1 : 2, servingTeam = currentScore.win)
    } else {
        servingTeam = servesData.currentTeam;
        wonTeam = score1 > score2 ? 1 : 2;
    }
    // currentScore
    //     ? (score1 + score2 === 0
    //         ? ({ win: wonTeam } = currentScore)
    //         : wonTeam = score1 === 0 && score2 === 1 || score1 === 1 && score2 === 0
    //         ? score1 > score2
    //             ? 1
    //             : 2
    //         : currentScore.score1 < score1
    //             ? 1 : 2, servingTeam = currentScore.win)
    //     : (({ currentTeam: servingTeam } = servesData), wonTeam = score1 > score2 ? 1 : 2);

    servesData.currentTeam = wonTeam;
    servesData.servePosition = currentSet.score[wonTeam] % 2 === 0 ? 'right' : 'left';
    scoreChanges.push({
        id: eventData.id,
        type: eventData.type,
        regtime: eventData.regtime,
        period: eventData.period,
        servingTeam,
        win: wonTeam,
        score1,
        score2,
    });
};

export const Re = (scores) => Boolean(((teamScores) => Math.abs(teamScores[0] - teamScores[1]) === 2)(scores));
/* eslint-disable max-len,no-shadow */
export const qe = (score1, score2) =>
    !![score1, score2].some((e) => e === 30) ||
    (((e) => e.every((e) => e >= 20))([score1, score2])
        ? !!Re([score1, score2])
        : !!((e) => e.some((e) => e === 21))([score1, score2]));

export const ze = (sets, homeTeam, awayTeam) => {
    let t = 0;
    let a = 0;
    sets.forEach((set) => {
        // eslint-disable-next-line no-unused-expressions
        if (set.isEnd) set.score[homeTeam] > set.score[awayTeam] ? (t += 1) : (a += 1);
    });
    return Math.abs(t - a) === 2 || (t === 2 && a === 1) || (a === 2 && t === 1);
};
