/* eslint-disable max-len */
import * as React from 'react';

import { useTargetName } from 'hooks';

const BetAndreasVariant = (props) => (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4.619 5.618C2.836 7.4 1.939 9.66 1.485 11.455l8.06 8.058c1.794-.452 4.054-1.35 5.837-3.134 1.783-1.784 2.68-4.042 3.134-5.837l-8.06-8.057c-1.795.452-4.054 1.35-5.837 3.133Zm7.175 2.69.898-.896a.634.634 0 0 1 .896.895l-.896.898.449.449a.634.634 0 1 1-.897.897l-.448-.449-.899.897.448.449a.634.634 0 0 1-.896.896L10 11.896l-.896.897.448.449a.634.634 0 0 1-.897.896l-.449-.447-.898.896a.634.634 0 0 1-.895-.896l.895-.898-.448-.449a.634.634 0 1 1 .897-.896l.448.448.899-.897-.449-.448a.634.634 0 0 1 .897-.897l.448.448.897-.897-.448-.448a.634.634 0 0 1 .896-.897l.45.447Zm7.069.427c.293-2.19.16-4.832-.804-5.794-.964-.962-3.603-1.097-5.794-.803-.101.013-.208.029-.318.046l6.87 6.87.046-.319Zm-17.725 4.53c-.293 2.19-.16 4.832.804 5.794.672.673 2.166.94 3.753.94a15.544 15.544 0 0 0 2.358-.183l-6.87-6.87-.045.319Z"
            fill="#9B9BB3"
        />
    </svg>
);
const ViviVariant = (props) => (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9.63 1.12a10.37 10.37 0 0 0-8.51 8.51.5.5 0 0 1 .04.04l5.21 5.21a10.37 10.37 0 0 0 8.51-8.5L9.67 1.15a.5.5 0 0 1-.04-.04ZM10.9.98l4.12 4.12c.05-1.14-.08-2.28-.4-3.38a.47.47 0 0 0-.34-.34c-1.1-.32-2.24-.45-3.38-.4ZM5.1 15.02.98 10.9c-.05 1.14.08 2.28.4 3.38.05.18.16.3.34.34l-.14.48.14-.48c1.1.32 2.24.45 3.38.4ZM3.3 3.32A11.41 11.41 0 0 1 14.56.41c.5.14.88.52 1.02 1.02A11.41 11.41 0 0 1 1.44 15.58c-.5-.14-.88-.52-1.02-1.02a11.41 11.41 0 0 1 2.9-11.25M11.2 4.8c.2.2.2.51 0 .71l-.35.35.17.17a.5.5 0 1 1-.7.7l-.18-.16-.7.7.18.16a.5.5 0 1 1-.71.71l-.17-.17-.7.7.17.17a.5.5 0 0 1-.7.7l-.18-.17-.7.7.18.17a.5.5 0 1 1-.7.7l-.18-.16-.35.34a.5.5 0 1 1-.7-.7l.34-.35-.17-.17a.5.5 0 0 1 .71-.7l.17.16.7-.7-.17-.17a.5.5 0 1 1 .7-.7l.17.17.7-.7-.17-.17a.5.5 0 1 1 .7-.7l.18.16.7-.7-.18-.16a.5.5 0 1 1 .71-.71l.17.17.35-.35c.2-.2.51-.2.7 0Z"
            fill="#444188"
        />
    </svg>
);

const MostBetVariant = (props) => (
    <svg width="28" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.188 20c-1.7 0-3-.3-3.5-.8-1.1-1.2-1.5-9.6 3.7-14.8 3.3-3.3 8-4.4 11.3-4.4 1.7 0 3 .3 3.5.8.5.4.8 1.7.8 3.3.1 3.4-1.1 8.2-4.4 11.5-3.3 3.2-8.1 4.4-11.4 4.4Zm-2.1-2.2c.6.1 1.3.2 2.1.2s1.6-.1 2.4-.2l-4.5-4.5c-.4 2.1-.2 3.8 0 4.5Zm6.8-.6c2.2-.6 3.9-1.7 5.3-3 1.3-1.3 2.3-3 3.1-5.3l-1.1-1.1c-.4 1.6-1.2 3.8-2.9 5.5-1.6 1.6-3.8 2.5-5.5 2.9l1.1 1Zm-2.7-2.7c1.7-.3 4-1 5.7-2.7 1.4-1.4 2.3-3.3 2.7-5.7l-3.5-3.5c-2.3.7-4 1.8-5.4 3.1-1.3 1.4-2.4 3.2-3.1 5.3l3.6 3.5Zm11.6-7.9c.3-1.9.3-3.7.1-4.5-.3-.1-1-.2-2.1-.2-.8 0-1.6.1-2.5.2l4.5 4.5Zm-11.5 6c-.3 0-.5-.1-.7-.3l-1.1-1.1c-.4-.4-.4-1 0-1.4.2-.2.4-.3.7-.3.3 0 .5.1.7.3l1.1 1.1c.2.2.3.4.3.7 0 .3-.1.5-.3.7-.2.2-.5.3-.7.3Zm1.7-1.8c-.3 0-.5-.1-.7-.3l-1.1-1.2c-.4-.4-.4-1 0-1.4.2-.2.4-.3.7-.3.3 0 .5.1.7.3l1.1 1.2c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3Zm1.8-1.7c-.2 0-.5-.1-.7-.3l-1.1-1.2c-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7.2-.1.4-.2.7-.2.3 0 .5.1.7.3l1.1 1.1c.2.2.3.4.3.7 0 .3-.1.5-.3.7-.2.2-.4.3-.7.3Zm1.7-1.8c-.3 0-.5-.1-.7-.3l-1.1-1.2c-.4-.4-.4-1 0-1.4.2-.2.4-.3.7-.3.3 0 .5.1.7.3l1.1 1.1c.2.2.3.4.3.7 0 .3-.1.6-.3.8-.2.2-.4.3-.7.3Z"
            fill="#305D17"
        />
    </svg>
);

export const Ball = (props) => {
    const { classMap: icon } = useTargetName((targets) => ({
        [targets.MOSTBET]: <MostBetVariant {...props} />,
        [targets.BETANDREAS]: <BetAndreasVariant {...props} />,
        [targets.VIVI]: <ViviVariant {...props} />,
    }));

    return icon;
};
