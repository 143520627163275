import React from 'react';
import { useTranslation } from 'react-i18next';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';

import { useCallbackTL, useNodeTLCallback } from 'hooks';
import { animationDataMap } from 'features/handball/animations/data';

export function IconText(props) {
    const { text, subText, icon } = props;
    const [translate] = useTranslation();

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid',
            progressiveLoad: false,
        },
        animationData: animationDataMap[icon],
    });

    const nodeRef = useNodeTLCallback();
    useCallbackTL(nodeRef, { repeatDelay: 4 });

    return (
        <div ref={nodeRef}>
            <div className="handball-full_rectangle" />
            <div className="handball-playing_status">
                <div className="handball-playing_status--icon">
                    <Lottie lottieRef={lottieRef} width="100%" height="100%" />
                </div>
                <div className="handball-playing_status--text">
                    <p>{translate(text)}</p>
                </div>
                {subText && (
                    <div className="handball-playing_status--subtext">
                        <p>{subText}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

IconText.propTypes = {
    text: PropTypes.string.isRequired,
    subText: PropTypes.string,
    icon: PropTypes.string.isRequired,
};

IconText.defaultProps = {
    subText: null,
};
