import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/bigTennis/constants';
import { FullRectangle } from './common/FullRectangle';

export function MatchEndAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();

    return (
        <div className="text-white font-medium" ref={nodeRef}>
            <FullRectangle />
            <div className="abs-center items-center">
                <p className="animation__field--text-action">{translate('animation.matchEnd')}</p>
            </div>
        </div>
    );
}

MatchEndAnimation.ANIMATION_NAME = AnimationMap.MatchEnd;
