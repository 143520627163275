import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/volleyball/constants';
import { Event } from './event.model';
import { addAddnlEvents, calFn, logFn } from './score.model';

export const AceEvent = new Event({
    type: ModelMap.Ace,
    animationName: AnimationMap.Ace,
    // renames: ['period', 'score1', 'score2'],
    calculate(store, evtData) {
        const { log, statistics } = store.translationData;
        const { periods } = statistics;
        const { score } = periods.find((prd) => prd.number === evtData.period);
        const { team } = evtData;
        logFn(log, statistics, evtData, store);
        log.push({
            component: 'DefaultLogEvent',
            team,
            type: 'ace',
            time: 0,
            period: evtData.period,
            timeText: `${score[store.homeTeam]}:${score[store.awayTeam]}`,
            text: i18n.t('log.ace'),
            id: log.length,
            translucent: true,
        });
        calFn(store, evtData);
    },
    pushAdditionalEvents(evtData, store) {
        return addAddnlEvents(evtData, store);
    },
});
