import { statistics as statisticsInitStat } from './statistics.context';

export const stateContext = {
    store: {
        events: [],
        lastEventId: 0,
        homeTeam: 1,
        awayTeam: 2,
        timer: {
            period: null,
        },
        countdown: {
            currentTime: undefined,
            enabled: false,
            countdown: undefined,
            needToShow: true,
        },
        currentAnimation: {
            eventData: {},
            componentName: '',
        },
        translationData: {
            info: {},
            lastAction: {},
            log: [],
            statistics: statisticsInitStat(),
        },
    },
};
