import {
    AdvertisingTimeoutAnimation,
    AttackAnimation,
    EmptyGateAnimation,
    FaceOffAnimation,
    FaceOffPointAnimation,
    FightAnimation,
    GoalAnimation,
    GoalkeeperInGateAnimation,
    MatchCountdownAnimation,
    MatchEndAnimation,
    PenaltyAnimation,
    PenaltyEndAnimation,
    PenaltyGoalAnimation,
    PenaltyOffTargetAnimation,
    PenaltyShotAnimation,
    PenaltyStartAnimation,
    PeriodCountdownAnimation,
    PeriodEndAnimation,
    PeriodStartAnimation,
    PlayersOnFieldAnimation,
    PlayersWarmUpAnimation,
    RemovalAnimation,
    RemovalEndAnimation,
    ShotOnGoalAnimation,
    TeamTimeoutAnimation,
    TimeoutAnimation,
    VideoRefereeAnimation,
} from 'features/iceHockey/animations';
import { AnimationMap } from 'features/iceHockey/constants';

export const animationFunctions = [
    AdvertisingTimeoutAnimation,
    AttackAnimation,
    EmptyGateAnimation,
    FaceOffAnimation,
    FaceOffPointAnimation,
    FightAnimation,
    GoalAnimation,
    GoalkeeperInGateAnimation,
    MatchCountdownAnimation,
    MatchEndAnimation,
    PenaltyAnimation,
    PenaltyEndAnimation,
    PenaltyGoalAnimation,
    PenaltyOffTargetAnimation,
    PenaltyShotAnimation,
    PenaltyStartAnimation,
    PeriodCountdownAnimation,
    PeriodStartAnimation,
    PeriodEndAnimation,
    PlayersOnFieldAnimation,
    PlayersWarmUpAnimation,
    RemovalAnimation,
    RemovalEndAnimation,
    ShotOnGoalAnimation,
    TeamTimeoutAnimation,
    TimeoutAnimation,
    VideoRefereeAnimation,
];
export const animationFnMap = {
    [AnimationMap.Timeout]: TimeoutAnimation,
    [AnimationMap.Attack]: AttackAnimation,
    [AnimationMap.PeriodCountdown]: PeriodCountdownAnimation,
    [AnimationMap.AdvertisingTimeout]: AdvertisingTimeoutAnimation,
    [AnimationMap.EmptyGate]: EmptyGateAnimation,
    [AnimationMap.FaceOff]: FaceOffAnimation,
    [AnimationMap.FaceOffPoint]: FaceOffPointAnimation,
    [AnimationMap.Fight]: FightAnimation,
    [AnimationMap.Goal]: GoalAnimation,
    [AnimationMap.GoalkeeperInGate]: GoalkeeperInGateAnimation,
    [AnimationMap.MatchCountdown]: MatchCountdownAnimation,
    [AnimationMap.MatchEnd]: MatchEndAnimation,
    [AnimationMap.Penalty]: PenaltyAnimation,
    [AnimationMap.PenaltyEnd]: PenaltyEndAnimation,
    [AnimationMap.PenaltyGoal]: PenaltyGoalAnimation,
    [AnimationMap.PenaltyOffTarget]: PenaltyOffTargetAnimation,
    [AnimationMap.PenaltyShot]: PenaltyShotAnimation,
    [AnimationMap.PenaltyStart]: PenaltyStartAnimation,
    [AnimationMap.PeriodEnd]: PeriodEndAnimation,
    [AnimationMap.PeriodStart]: PeriodStartAnimation,
    [AnimationMap.PlayersOnField]: PlayersOnFieldAnimation,
    [AnimationMap.PlayersWarmUp]: PlayersWarmUpAnimation,
    [AnimationMap.Removal]: RemovalAnimation,
    [AnimationMap.RemovalEnd]: RemovalEndAnimation,
    [AnimationMap.ShotOnGoal]: ShotOnGoalAnimation,
    [AnimationMap.TeamTimeout]: TeamTimeoutAnimation,
    [AnimationMap.VideoReferee]: VideoRefereeAnimation,
};
