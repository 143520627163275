export function getQsValue(paramName) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    // eslint-disable-next-line no-unused-vars
    let result = false;

    vars.forEach((el) => {
        const [name, value] = el.split('=');
        if (name === paramName) result = value;
    });

    return result;
}
