/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const PenaltyEvent = new Event({
    type: ModelMap.Penalty,
    // renames: ['team', 'period', 'time'],
    animationName: AnimationMap.Penalty,
    calculate(store, evt) {
        const { team, time, period } = evt;
        store.translationData.statistics.penalties[team].push({
            time,
            period,
        });
        store.translationData.statistics.gameTimePenalty = true;
        store.translationData.statistics.freeThrowTeam = team;
    },
    pushToLog(log, evt, translationData) {
        const { matchDuration } = translationData.statistics;
        log.push({
            time: evt.time,
            component: 'DefaultLogEvent',
            type: 'penalty',
            period: evt.period,
            team: evt.team,
            id: evt.id,
            matchDuration,
        });
    },
});
