/* eslint-disable no-sequences */
import { AnimationMap, ModelMap } from 'features/basketball/constants';
import { Event } from 'features/common/models/event.model';

export function getForwardTime(t) {
    const { time: e, period: a } = t;
    if (e === null) {
        return null;
    }
    let i;
    const n = +t.quarters.split('x')[0];
    const s = 60 * t.periodDuration;
    // eslint-disable-next-line no-unused-expressions
    a > n ? (i = n * s + (a - n) * t.overtimeDuration * 60 - e) : (i = a * s - e);
    return Math.max(0, i);
}

export function ballPossessionUnkownFn(time, store) {
    let a;
    const { statistics, info } = store.translationData;
    const { ballPossession } = statistics;
    const { quarters, periodDuration, overtimeDuration } = info.extra;
    const forwardTime =
        getForwardTime({
            time,
            period: statistics.currentPeriod,
            quarters,
            periodDuration,
            overtimeDuration,
        }) || 0;
    function getLastItem(t) {
        const e = ballPossession[t];
        return e[e.length - 1];
    }

    function getLastItemForwardTime(t) {
        const e = getLastItem(t);
        return e ? e.forwardTime : 0;
    }
    const diff = getLastItemForwardTime(1) - getLastItemForwardTime(2);
    // eslint-disable-next-line no-unused-expressions
    diff > 0 ? (a = getLastItem(1)) : diff < 0 && (a = getLastItem(2)),
        a && (a.possessionTime = forwardTime - a.forwardTime);
}

export const BallPossessionEvent = new Event({
    type: ModelMap.BallPossession,
    // renames: ['team', 'time', 'period'],
    animationName: AnimationMap.BallPossession,
    calculate(store, evtData) {
        const { time, period, team } = evtData;
        const { info, statistics } = store.translationData;
        const { quarters, periodDuration, overtimeDuration } = info.extra;
        const forwardTime =
            getForwardTime({
                time,
                period,
                quarters,
                periodDuration,
                overtimeDuration,
            }) || 0;
        ballPossessionUnkownFn(time, store);
        statistics.ballPossession[team].push({
            forwardTime,
            possessionTime: 0,
            period,
            time,
        });
    },
    isDuplicate: (evtData, lastEvtData) =>
        evtData.team === lastEvtData.team && lastEvtData.type === ModelMap.BallPossession,
    isPreventProcessing: (evtData) => !evtData.team,
});
