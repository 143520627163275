/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { getLocalTime } from 'features/common/utils';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';

export const MatchCountdownEvent = new Event({
    type: ModelMap.MatchCountdown,
    animationName: AnimationMap.MatchCountdown,
    calculate(store) {
        if (!store.countdown.enabled) {
            const { translationData } = store;
            const timeStamp = translationData.info
                ? getLocalTime(translationData.info.start).getTime()
                : translationData.lineMatchInfo.startTimestamp;

            store.countdown.countdown = Math.max(timeStamp - store.countdown.currentTime, 0);
            store.countdown.enabled = true;
        }
    },
    isPreventProcessing: (_, store) => !store.countdown.needToShow,
});
