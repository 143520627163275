import React from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationMap } from 'features/rugby/constants';
import { IconText } from './common/IconText';

export function ScrumAnimation() {
    const [translate] = useTranslation();
    return <IconText icon="scrum" text={translate('action.scrum.animation')} />;
}
ScrumAnimation.ANIMATION_NAME = AnimationMap.Scrum;
