import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export function NetworkError({ onRefresh: refreshHandler, translate, sport }) {
    return (
        <div className={cn(sport, 'error')}>
            <p className="error__text">{translate('error.networkError')}</p>
            <button type="button" className={cn(sport, 'error__btn')} onClick={refreshHandler}>
                {translate('base.restart')}
            </button>
        </div>
    );
}

NetworkError.propTypes = {
    sport: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
};
