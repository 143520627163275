import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';

export const MatchEndEvent = new Event({
    type: ModelMap.MatchEnd,
    animationName: AnimationMap.MatchEnd,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isMatchEnded = true;
        statistics.isTimerPaused = true;
        statistics.timer = 0;
    },
});
