/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';

export const RedCardEvent = new Event({
    type: ModelMap.RedCard,
    animationName: AnimationMap.RedCard,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        statistics.redCards[eventData.team].push({
            time: eventData.time,
            period: store.currentPeriod,
            id: eventData.id,
        });
    },
});
