export const ModelMap = {
    MatchCountdown: -2e4,
    SetTeamsType: 2061,
    SetScoringAdvantage: 2062,
    SetLastSetTieBreakType: 2063,
    SetLastSetTieBreak: 2064,
    SetTotalSetsCount: 2065,
    ChangeSvgColor: 2066,
    SetServingTeam: 1123,
    Serve: 1124,
    SecondServe: 1126,
    SetScoringTeam: 1125,
    Score: -1125,
    TennisGameEnd: -1,
    TennisSetEnd: -2,
    MedicalBreak: 1015,
    WeatherBreak: 1011,
    BathroomBreak: 1013,
    CallForTrainer: 1078,
    TimerPaused: 1009,
    TimerStarted: 1010,
    PlayersWarmUp: 1031,
    Violation: [1142, 1143],
    DoubleFoul: -2068,
    Ace: -11251,
    MatchEnd: 1103,
    ChallengeNoTeam: -1147,
    Challenge: 1147,
    SetServerInPair: 2069,
};
