/* eslint-disable max-len */
import React from 'react';

export function Cross(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
            <path
                fillRule="evenodd"
                d="M16.414 15l12.728 12.728a.999.999 0 11-1.414 1.414L15 16.414 2.272 29.142a.999.999 0 11-1.414-1.414L13.586 15 .858 2.272A.999.999 0 112.272.858L15 13.586 27.728.858a.999.999 0 111.414 1.414L16.414 15z"
            />
        </svg>
    );
}
