import { lazy } from 'react';

const FootballPage = lazy(() => import(/* webpackChunkName: "Football" */ 'pages/football'));
const TableTennisPage = lazy(() => import(/* webpackChunkName: "TableTennis" */ 'pages/tableTennis'));
const VolleyballPage = lazy(() => import(/* webpackChunkName: "Volleyball" */ 'pages/volleyball'));
const BeachVolleyballPage = lazy(() => import(/* webpackChunkName: "BeachVolleyball" */ 'pages/beachVolleyball'));
const BasketballPage = lazy(() => import(/* webpackChunkName: "Basketball" */ 'pages/basketball'));
const Basketball3x3Page = lazy(() => import(/* webpackChunkName: "Basketball3x3" */ 'pages/basketball3x3'));
const IceHockeyPage = lazy(() => import(/* webpackChunkName: "IceHockey" */ 'pages/iceHockey'));
const BigTennisPage = lazy(() => import(/* webpackChunkName: "BigTennis" */ 'pages/bigTennis'));
const HandballPage = lazy(() => import(/* webpackChunkName: "Handball" */ 'pages/handball'));
const BadmintonPage = lazy(() => import(/* webpackChunkName: "BigTennis" */ 'pages/badminton'));
const FloorballPage = lazy(() => import(/* webpackChunkName: "BigTennis" */ 'pages/floorball'));
const FutsalPage = lazy(() => import(/* webpackChunkName: "FutsalPage" */ 'pages/futsal'));
const Rugby = lazy(() => import(/* webpackChunkName: "RugbyPage" */ 'pages/rugby'));

export const routes = [
    {
        name: 'Football',
        path: ['/soccer/:translationId', '/football/:translationId'],
        component: FootballPage,
    },
    {
        name: 'Futsal',
        path: '/futsal/:translationId',
        component: FutsalPage,
    },
    {
        name: 'TableTennis',
        path: '/table-tennis/:translationId',
        component: TableTennisPage,
    },
    {
        name: 'Volleyball',
        path: '/volleyball/:translationId',
        component: VolleyballPage,
    },
    {
        name: 'BeachVolleyball',
        path: '/beach-volley/:translationId',
        component: BeachVolleyballPage,
    },
    {
        name: 'Basketball',
        path: '/basketball/:translationId',
        component: BasketballPage,
    },
    {
        name: 'Basketball3x3',
        path: ['/basketball3x3/:translationId', '/basketball_3x3/:translationId'],
        component: Basketball3x3Page,
    },
    {
        name: 'IceHockey',
        path: '/ice-hockey/:translationId',
        component: IceHockeyPage,
    },
    {
        name: 'BigTennis',
        path: ['/big-tennis/:translationId', '/tennis/:translationId'],
        component: BigTennisPage,
    },
    {
        name: 'Handball',
        path: '/handball/:translationId',
        component: HandballPage,
    },
    {
        name: 'Badminton',
        path: '/badminton/:translationId',
        component: BadmintonPage,
    },
    {
        name: 'Floorball',
        path: '/floorball/:translationId',
        component: FloorballPage,
    },
    {
        name: 'Rugby',
        path: '/rugby/:translationId',
        component: Rugby,
    },
];
