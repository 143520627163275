import { ModelMap } from 'features/volleyball/constants';
import { Event } from './event.model';

export const BlockEvent = new Event({
    type: ModelMap.Block,
    // renames: ['team'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        statistics.blocks[evtData.team].push({
            period: statistics.currentPeriod,
        });
    },
});
