import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { calculatePercentsForTeams } from 'features/common/utils';

function ballPossessionStats(ballPossession) {
    return ballPossession.reduce((acc, curr) => {
        const { possessionTime } = curr;
        return acc + possessionTime;
    }, 0);
}

export const useBallPossessionStatistic = () => {
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;

    const { ballPossession } = statistics;

    const [translate] = useTranslation();

    const homeStat = ballPossessionStats(ballPossession[homeTeam]);
    const awayStat = ballPossessionStats(ballPossession[awayTeam]);

    let homePercent = 50;
    let awayPercent = 50;

    const totalStat = homeStat + awayStat;

    if (totalStat !== 0) {
        ({ awayPercent, homePercent } = calculatePercentsForTeams({ homeStat, awayStat }));
    }

    return {
        homeStat: homePercent,
        awayStat: awayPercent,
        label: translate('statistics.possession'),
    };
};
