import React from 'react';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTargetName } from 'hooks';
import { animationDataMap } from 'features/iceHockey/animations/data';

export function FieldIcon(props) {
    const { animationName, team } = props;

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid',
            progressiveLoad: false,
        },
        animationData: animationDataMap[animationName],
    });

    const { classMap } = useTargetName((targets) => ({
        [targets.MOSTBET]: 'h-16 w-16',
        [targets.BETANDREAS]: 'h-13 w-12',
        [targets.VIVI]: 'h-11 w-10',
    }));

    const leftOffset = team === 1 ? '58%' : '42%';
    const transform = team === 1 ? 'translate(-50%, -50%) scaleX(1)' : ' translate(-50%, -50%) scaleX(-1)';
    return (
        <div
            className={cn(classMap, 'ice-hockey-field_icon text-white abs-center sm:h-18 sm:w-18')}
            style={{ left: leftOffset, transform }}
        >
            <Lottie lottieRef={lottieRef} width="100%" height="100%" />
        </div>
    );
}

FieldIcon.propTypes = {
    animationName: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
};
