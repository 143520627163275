import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/volleyball/constants';
import { Event } from './event.model';

export const MatchEndEvent = new Event({
    type: ModelMap.MatchEnd,
    animationName: AnimationMap.MatchEnd,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isMatchEnd = true;
    },
    pushToLog(log, _, store) {
        const { statistics } = store.translationData;
        log.push({
            component: 'GeneralLogEvent',
            text: i18n.t('log.matchEnd'),
            translucent: true,
            period: statistics.currentPeriod,
            type: 'matchEnd',
            id: log.length,
        });
    },
});
