/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';
import { enableCountdown, updateCountdown } from './common';

export const PeriodCountdownEvent = new Event({
    type: ModelMap.PeriodCountdown,
    animationName: AnimationMap.PeriodCountdown,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        const { countdown } = store;
        const currentCountdownTime = countdown.currentTime;
        const i = 9e5 - currentCountdownTime + statistics.periodsEndDates[eventData.period].getTime();
        updateCountdown(store, i);
        enableCountdown(store);
    },
    isPreventProcessing: (_, store) => !store.countdown.needToShow,
});
