import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/bigTennis/constants';
import { IconText } from './common/IconText';

export function MedicalBreakAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);
    return (
        <div ref={nodeRef}>
            <IconText icon="medicalBreak" text={translate('animation.medicalBreak')} />
        </div>
    );
}

MedicalBreakAnimation.ANIMATION_NAME = AnimationMap.MedicalBreak;
