import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';

export const VideoRefereeEvent = new Event({
    type: ModelMap.VideoReferee,
    animationName: AnimationMap.VideoReferee,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.videoRefereesCount += 1;
    },
});
