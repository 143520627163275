import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

class ShotOnGoal extends Event {
    constructor(props) {
        const { type, redirect, animationName } = props;
        super({
            type,
            redirect,
            animationName,
            // renames: ['team', 'period', 'time'],
            calculate(store, { team, period, time }) {
                store.translationData.statistics.shotOnGoals[team].push({
                    time,
                    period,
                });
            },
        });
    }
}

export const ShotOnGoalEvent = new ShotOnGoal({
    type: ModelMap.ShotOnGoal,
    animationName: AnimationMap.ShotOnGoal,
    redirect: (statistics, evt) => !!statistics.ballPoint && { ...evt, type: ModelMap.ShotOnGoalWithPosition },
});

export const ShotOnGoalEventWithPosition = new ShotOnGoal({
    type: ModelMap.ShotOnGoalWithPosition,
    animationName: AnimationMap.ShotOnGoal,
});
