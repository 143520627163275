import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/volleyball/constants';
import { Event } from './event.model';

export const PlayersWarmupEvent = new Event({
    type: ModelMap.PlayersWarmup,
    animationName: AnimationMap.PlayersWarmup,
    pushToLog(log, evtData, store) {
        const { statistics } = store.translationData;
        log.push({
            component: 'GeneralLogEvent',
            translucent: true,
            text: i18n.t('log.playersWarmup'),
            period: statistics.currentPeriod,
            type: 'playersWarmup',
            id: evtData.id,
        });
    },
});
