import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/basketball/constants';
import { Event } from 'features/common/models/event.model';

export const FreeThrowMinusEvent = new Event({
    type: ModelMap.FreeThrowMinus,
    // renames: ['team', 'count', 'time', 'period'],
    animationName: AnimationMap.FreeThrow,
});

export const FreeThrowPlusEvent = new Event({
    type: ModelMap.FreeThrowPlus,
    // renames: ['team', 'count', 'time', 'period'],
    animationName: AnimationMap.FreeThrow,
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        const { team, time, period, count } = evtData;

        statistics.freeThrows[team].push({
            time,
            period,
            count,
        });

        statistics.currentFreeThrows = Array.from(
            {
                length: count,
            },
            () => ({
                done: false,
                status: undefined,
            }),
        );
    },
    pushToLog(log, evtData, store) {
        const { time, period, team, id, count } = evtData;
        const text = count > 1 ? i18n.t('action.freeThrows.log') : i18n.t('action.freeThrow.log');
        const { homeTeam, awayTeam } = store;
        const { statistics } = store.translationData;
        const { score } = statistics;
        log.push({
            time,
            type: 'freeThrow',
            component: 'DefaultLogEvent',
            period,
            text,
            // text: count > 3 ? `${text} (${count})` : text,
            // svg: count > 3 ? 'log/free_throw' : `log/free_throw_${count}`,
            svg: 'BbFreeThrowIcon',
            team,
            id,
            timeText: `${score[homeTeam]}:${score[awayTeam]}`,
            translucent: true,
        });
    },
    isPreventProcessing: (evtData) => !evtData.team || evtData.count === 0,
});
