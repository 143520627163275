/* eslint-disable no-param-reassign */
import { ModelMap } from 'features/futsal/constants';
import { Event } from './event.model';

export const TimePausedPreventFalse = new Event({
    type: ModelMap.TimePausedPreventFalse,
    calculate(store) {
        store.translationData.statistics.isTimerPaused = false;
    },
    isPreventProcessing: () => false,
});

export const TimePausedPreventTrue = new Event({
    type: ModelMap.TimePausedPreventTrue,
    calculate(store) {
        store.translationData.statistics.isTimerPaused = true;
    },
    isPreventProcessing: () => false,
});
