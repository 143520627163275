export const AnimationMap = {
    MatchCountdown: 'matchCountdown',
    PeriodCountdown: 'periodCountdown',
    Attack: 'attack',
    PeriodStart: 'periodStart',
    PeriodEnd: 'periodEnd',
    MatchEnd: 'matchEnd',
    Goal: 'goal',
    PenaltyGoal: 'penaltyGoal',
    PenaltyOffTarget: 'penaltyOffTarget',
    VideoReferee: 'videoReferee',
    Timeout: 'timeout',
    AdvertisingTimeout: 'advertisingTimeout',
    TeamTimeout: 'teamTimeout',
    Removal: 'removal',
    RemovalEnd: 'removalEnd',
    EmptyGate: 'emptyGate',
    GoalkeeperInGate: 'goalkeeperInGate',
    Penalty: 'penalty',
    PenaltyShot: 'penaltyShot',
    PenaltyStart: 'penaltyStart',
    PenaltyEnd: 'penaltyEnd',
    PlayersOnField: 'playersOnField',
    PlayersWarmUp: 'playersWarmUp',
    Fight: 'fight',
    ShotOnGoal: 'shotOnGoal',
    FaceOff: 'faceOff',
    FaceOffPoint: 'faceOffPoint',
};
