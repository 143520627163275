import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/rugby/constants';

export const PeriodEnd = new Event({
    type: ModelMap.PeriodEnd,
    animationName: AnimationMap.PeriodEnd,
    calculate(store, eventData) {
        const { period } = eventData;
        const { statistics } = store.translationData;
        // statistics.isMatchEnded = true;
        statistics.isTimerPaused = true;
        statistics.periods[period].ended = true;
    },
    pushToLog(log, eventData, store) {
        const { statistics } = store.translationData;
        const { currentPeriod } = statistics;

        log.push({
            component: 'GeneralLogEvent',
            text: i18n.t('log.periodEnd'),
            period: currentPeriod,
            type: 'periodEnd',
            id: eventData.id,
        });
    },
});
