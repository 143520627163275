import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useFootballCirclesTL, useTargetName } from 'hooks';
import { addToRefs } from 'features/football/utils';

export function ShotOnGoalMissCircles(props) {
    const { circles, team } = props;
    const isHome = team === 1;
    const circlesRefs = useFootballCirclesTL();
    const { conditions } = useTargetName();

    const contCls = cx('shotOnGoalMiss-circle_cont', {
        _left: isHome,
        _right: !isHome,
    });

    const outerCls = cx('shotOnGoalMiss-circle_outer', {
        _left: isHome,
        _right: !isHome,
    });
    const circlesCntCls = cx('shotOnGoalMiss-circle_inner_cont', {
        _left: isHome,
        _right: !isHome,
    });

    const dotCls = cx('shotOnGoalMiss-circle_inner', {
        'football-home-team-shadow': isHome,
        'football-away-team-shadow': !isHome,
        vivi: conditions.isVivi,
    });
    return (
        <div className={contCls}>
            <div className={outerCls}>
                <span className={dotCls} />
                <div className={circlesCntCls}>
                    {Array.from({ length: circles }, (_, index) => (
                        <span
                            ref={(el) => addToRefs(el, circlesRefs)}
                            key={index}
                            className="shotOnGoalMiss-circle_circles"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

ShotOnGoalMissCircles.propTypes = {
    circles: PropTypes.number.isRequired,
    team: PropTypes.number.isRequired,
};
