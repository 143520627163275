import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import gsap from 'gsap';

import { useCallbackTL, useNodeTimeline, useTargetName } from 'hooks';
import { AnimationMap } from 'features/basketball/constants';
// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export function ScoreChangeAnimation(props) {
    const { team, ...rest } = props;

    const { classMap } = useTargetName((targets) => ({
        [targets.MOSTBET]: 'bb_score-change-rect__mostbet',
        [targets.BETANDREAS]: 'bb_score-change-rect__betandreas',
    }));

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;
    const { score } = statistics;
    const scoreDifference = rest.code >= 0 ? rest.score : -rest.score;
    let homeTeamScore = score[homeTeam];
    let awayTeamScore = score[awayTeam];

    if (team === homeTeam) {
        homeTeamScore -= scoreDifference;
    } else {
        awayTeamScore -= scoreDifference;
    }

    const initialScore = {
        [homeTeam]: homeTeamScore,
        [awayTeam]: awayTeamScore,
    };

    const scoreRef = useRef(null);
    const homeRef = useRef(null);
    const awayRef = useRef(null);

    const teamRef = team === homeTeam ? homeRef : awayRef;

    const textTimeLine = useRef(
        gsap.timeline({
            repeat: 0,
        }),
    );

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef, { repeatDelay: 4 });

    useEffect(() => {
        textTimeLine.current
            .fromTo(
                scoreRef.current,
                {
                    opacity: 0,
                    display: 'none',
                },
                {
                    opacity: 1,
                    duration: 0.5,
                    display: 'flex',
                },
            )
            .fromTo(teamRef.current, { scale: 1 }, { scale: 0.5, opacity: 0 })
            .set(teamRef.current, { innerText: initialScore[team] + scoreDifference })
            .to(teamRef.current, { scale: 1, opacity: 1 });

        return () => {
            // eslint-disable-next-line
            textTimeLine.current.kill();
        };
        // eslint-disable-next-line
    }, [nodeRef]);

    return (
        <div className={cx('bb_score-change-rect', classMap)} ref={nodeRef}>
            <div className="bb_score-change-circle" />
            <div className="bb_score-change-text" ref={scoreRef}>
                <p ref={homeRef}>{initialScore[homeTeam]}</p>
                <p className="bb_score-change-divider">:</p>
                <p ref={awayRef}>{initialScore[awayTeam]}</p>
            </div>
        </div>
    );
}

ScoreChangeAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

ScoreChangeAnimation.ANIMATION_NAME = AnimationMap.ScoreChange;
