export const AnimationMap = {
    MatchCountdown: 'matchCountdown',
    PeriodCountdown: 'periodCountdown',
    Score: 'score',
    Foul: 'foul',
    Ace: 'ace',
    Serve: 'serve',
    PeriodStart: 'periodStart',
    PeriodEnd: 'periodEnd',
    MatchEnd: 'matchEnd',
    PlayersWarmup: 'playersWarmup',
    Timeout: 'timeout',
};
