/* eslint-disable no-param-reassign */
import { ModelMap } from 'features/badminton/constants';
import { Event } from 'features/common/models/event.model';

export const MatchEndEvent = new Event({
    type: ModelMap.MatchEnd,
    calculate(store) {
        store.isMatchEnd = true;
        store.isTimerPaused = true;
    },
    isPreventProcessing: (_, store) => !store.countdown.needToShow,
});
