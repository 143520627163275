import React from 'react';

import { FieldYellowCard } from 'ui/atoms/Icons/FieldYellowCard';
import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const YellowCardEvent = new Event({
    type: ModelMap.YellowCard,
    // renames: ['team', 'period', 'time'],
    animationName: AnimationMap.YellowCard,
    calculate(store, { time, period, team }) {
        store.translationData.statistics.yellowCards[team].push({
            time,
            period,
        });
    },
    pushToLog(log, evt, translationData) {
        const { matchDuration } = translationData.statistics;
        log.push({
            time: evt.time,
            type: 'yellowCard',
            component: 'DefaultLogEvent',
            period: evt.period,
            team: evt.team,
            id: evt.id,
            svg: <FieldYellowCard width="100%" height="100%" />,
            matchDuration,
        });
    },
});
