export class Event {
    constructor(props) {
        this.type = props.type;
        // this.renames = props.renames;
        /*
        calculate gets statistics and processed eventData but
        since for some calculations translationData.info is also needed
        we will pass the whole store object instead of the statistics
        */
        this.calculate = props.calculate;
        /* redirect gets renamed event data and statistics */
        this.redirect = props.redirect;
        /* pushToLog gets log, eventData and store */
        this.pushToLog = props.pushToLog;
        /* setLastAction gets eventData and statistics as arguments */
        this.setLastAction = props.setLastAction;
        /* isDuplicate gets current processedEventData and processedLastEventData */
        this.isDuplicate = props.isDuplicate;
        /* isPreventProcessing gets processedEventData and store */
        this.isPreventProcessing = props.isPreventProcessing || (() => false);
        this.pushAdditionalEvents = props.pushAdditionalEvents || (() => []);
        this.animationName = props.animationName;
    }

    get isWithoutAnimation() {
        return !this.animationName;
    }

    // eslint-disable-next-line class-methods-use-this
    processEventData(event) {
        // TODO - remove after test
        // const { renames } = this;
        // const renamedEvent = { ...event };
        // if (renames) {
        //     for (let i = 0; i < renames.length; i += 1) {
        //         const renamed = renames[i];
        //         renamedEvent[renamed] = event[`i${i + 1}`];
        //     }
        // }

        return event;
    }
}
