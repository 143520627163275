export const statistics = () => ({
    isMatchEnd: false,
    servingTeam: undefined,
    score: {
        1: 0,
        2: 0,
    },
    currentPeriod: 1,
    periods: [
        {
            number: 1,
            score: {
                1: 0,
                2: 0,
            },
            isEnded: false,
        },
    ],
    periodsEndDates: {},
    scoreChanges: [],
    timeouts: {
        1: [],
        2: [],
    },
    blocks: {
        1: [],
        2: [],
    },
    fouls: {
        1: [],
        2: [],
    },
    aces: {
        1: [],
        2: [],
    },
});
