import { assign } from 'xstate';

import { Animator } from 'features/common/services/animator.service';
import { EventProcessor } from 'features/rugby/services/processor.service';

export const idle = {
    invoke: {
        id: 'idle-invoke',
        src: 'translationData',
        onDone: {
            target: 'initialEvents',
            actions: [
                assign({
                    store: ({ context, event }) => {
                        const { teamsreverse } = event.output;
                        const homeTeam = teamsreverse ? 2 : 1;
                        const awayTeam = teamsreverse ? 1 : 2;
                        return {
                            ...context.store,
                            homeTeam,
                            awayTeam,
                            translationData: {
                                ...context.store.translationData,
                                // info: event.data,
                            },
                        };
                    },
                }),
                assign({
                    eventProcessor: ({ context }) => {
                        const animator = new Animator(context.store);
                        return new EventProcessor(context.store, animator);
                    },
                }),
            ],
        },
        onError: {
            target: 'failure',
            actions: assign({ error: ({ event }) => event.error }),
        },
    },
};
