import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AnimationMap } from 'features/handball/constants';
import { FullRectangle } from './common/FullRectangle';
import { IconText } from './common/IconText';

export function SevenMeterThrowAnimation(props) {
    const [translate] = useTranslation();
    const { team } = props;
    const teamName = translate(`team${team}`);

    return (
        <>
            <FullRectangle />
            <IconText icon="sevenMeterThrow" subText={teamName} text={translate('animation.sevenMeter')} />
        </>
    );
}

SevenMeterThrowAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

SevenMeterThrowAnimation.ANIMATION_NAME = AnimationMap.SevenMeterThrow;
