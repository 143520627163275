/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable max-classes-per-file */

import { FreezedEmitter } from 'features/common/services/emitter.service';

class Queue {
    constructor(processParam = (param) => param) {
        this.processParam = processParam;
        this.arr = [];
        this.virgin = true;
        this.overflowSize = 4;
        this.emitter = FreezedEmitter;
    }

    get length() {
        return this.arr.length;
    }

    push(...args) {
        const param = [...args].map(this.processParam);
        this.arr.push(...param);
        this.tryCleanupArr();
        const prevVirgin = this.virgin;
        this.virgin = false;
        prevVirgin && this.emitter.emit('touch');
    }

    shift() {
        if (this.arr.length === 0) {
            throw new Error('Queue is empty');
        }
        return this.arr.shift();
    }

    tryCleanupArr() {
        const { arr, overflowSize } = this;
        const len = arr.length;
        if (len > overflowSize) {
            this.arr = [arr[len - 1]];
        }
    }

    reset() {
        this.arr = [];
        this.resetVirgin();
    }

    resetVirgin() {
        this.virgin = true;
    }
}

export class Animator {
    constructor(store) {
        this.store = store;
        this._version = 0;
        this.emitter = FreezedEmitter;
        this.queue = new Queue();
        this.emitter.on('resetVirgin', () => {
            this.queue.resetVirgin();
        });
        this.emitter.on('touch', () => {
            this.checkQueue();
        });
        this.emitter.on('animationCompleted', () => {
            this.checkQueue();
            // || this.queue.resetVirgin();
        });
    }

    get matchData() {
        return this.store.translationData.info;
    }

    checkQueue() {
        const { queue } = this;
        return !!queue.length && (this.playNewAnimation(), false);
    }

    reset() {
        this._version += 1;
        this.queue.reset();
        // currentAnimation on statistics previously reset
        // before calling this function.
        this.currentAnimation = undefined;
    }

    async playNewAnimation() {
        const newAnimation = this.queue.shift();
        this.currentAnimation = { ...newAnimation };
        this.store.currentAnimation = {
            ...newAnimation,
        };
        this.emitter.emit('newAnimation', { ...newAnimation });
        // Строчку ниже нельзя раскомментировать т.к. события будут отображаться
        // Некорректно (часть событий будет пропускаться)
        // this.checkQueue() || this.queue.resetVirgin();
    }
}
