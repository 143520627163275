import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/rugby/constants';

export const TimerEnabled = new Event({
    type: ModelMap.TimerEnabled,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isTimerPaused = false;
    },
});

export const TimerDisabled = new Event({
    type: ModelMap.TimerDisabled,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isTimerPaused = true;
    },
});
