export const statistics = () => ({
    isMatchEnd: !1,
    servingTeam: undefined,
    score: {
        1: 0,
        2: 0,
    },
    colors: {
        1: {
            mainColor: '#0092F0',
            secondColor: '#0092F0',
        },
        2: {
            mainColor: '#52AB00',
            secondColor: '#52AB00',
        },
    },
    currentPeriod: 1,
    periods: [
        {
            number: 1,
            score: {
                1: 0,
                2: 0,
            },
            isEnded: !1,
        },
    ],
    periodsEndDates: {},
    scoreChanges: [],
    timeouts: {
        1: [],
        2: [],
    },
    blocks: {
        1: [],
        2: [],
    },
    fouls: {
        1: [],
        2: [],
    },
    aces: {
        1: [],
        2: [],
    },
});
