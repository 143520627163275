import { ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

export const SetTeamsTypeEvent = new Event({
    type: ModelMap.SetTeamsType,
    renames: ['teamsType'],
    calculate(store, evtData) {
        const { teamsType } = evtData;
        // eslint-disable-next-line no-param-reassign
        store.teamsType = teamsType;
    },
});
