import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';

export const ShotOnGoalEvent = new Event({
    type: ModelMap.ShotOnGoal,
    animationName: AnimationMap.ShotOnGoal,
    renames: ['team', 'period', 'time'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        const { team, period, time } = evtData;
        statistics.shotsOnGoal[team].push({
            time,
            period,
        });
    },
});
