/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/floorball/constants';

const BREAK_DURATION = 10 * 1000 * 60;

export const PeriodCountdownEvent = new Event({
    type: ModelMap.PeriodCountdown,
    animationName: AnimationMap.PeriodCountdown,
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        if (!store.countdown.enabled) {
            const periodEndDate = statistics.periodsEndDates[evtData.period];
            const currentTime = new Date().getTime();

            const timeStamp = BREAK_DURATION - currentTime + periodEndDate.getTime();
            store.countdown.countdown = Math.max(timeStamp, 0);
            store.countdown.enabled = true;
        }
    },
    isPreventProcessing: (_, store) => !store.countdown.needToShow,
});
