import React, { useContext } from 'react';
import { LoaderContext } from 'context/LoaderContext';

export const useComponentRendered = () => {
    const { setLoading } = useContext(LoaderContext);

    React.useEffect(() => {
        setLoading(false);
    }, [setLoading]);
};
