import { fromPromise, setup } from 'xstate';

import { getTranslationData, getTranslationEvents } from 'features/common/services/event.service';
import { state } from './context';
import { disabled, idle, initialEvents, newEvents } from './states';

export const rootMachine = ({ translationId }) =>
    setup({
        actors: {
            translationData: fromPromise(() => getTranslationData(translationId)),
            translationEvents: fromPromise(({ input }) => getTranslationEvents(input.translationId, input.lastEventId)),
            newEvents: fromPromise(({ input }) => getTranslationEvents(input.translationId, input.lastEventId)),
            disabled: fromPromise(({ input }) => getTranslationEvents(input.translationId, input.lastEventId)),
        },
        guards: {
            hasGotNoInitialEvents: ({ event }) => event.output.length === 0,
            isWidgetDisabled: ({ event }) => event.error.message.indexOf('disabledMatchError') > -1,
        },
    }).createMachine({
        id: 'root',
        initial: 'idle',
        context: {
            ...state,
            translationId,
        },
        states: {
            idle,
            disabled,
            initialEvents,
            newEvents,
            failure: {
                type: 'final',
            },
            delay: {
                after: { 1000: 'newEvents' },
            },
            initialEventsDelay: {
                after: { 1000: 'initialEvents' },
            },
            disabledDelay: {
                after: { 3000: 'disabled' },
            },
        },
    });
