import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';
import { getRealFormattedTime, getRealTime } from 'features/iceHockey/features/common/getTime';

export const EmptyGateEvent = new Event({
    type: ModelMap.EmptyGate,
    animationName: AnimationMap.EmptyGate,
    renames: ['team', 'period', 'time'],
    pushToLog(log, evtData, store) {
        const { info } = store.translationData;
        const { periodDuration, overtimeDuration, periodsCount } = info.extra;
        const formattedTime = getRealFormattedTime(
            evtData.time,
            evtData.period,
            periodDuration,
            overtimeDuration,
            periodsCount,
        );
        const realTime = getRealTime(evtData.time, evtData.period, periodDuration, overtimeDuration, periodsCount);

        log.push({
            time: realTime,
            type: 'emptyGate',
            component: 'DefaultLogEvent',
            period: evtData.period,
            svg: 'EmptyGate',
            timelineSvg: 'emptyGate',
            text: i18n.t('action.emptyGate.log'),
            timeText: formattedTime,
            team: evtData.team,
            id: evtData.id,
        });
    },
});
