import { AnimationMap } from 'features/badminton/constants';
import { ChallengeAnimation } from './challenge.animation';
import { MatchCountdownAnimation } from './matchCountdown.animation';
import { MatchEndAnimation } from './matchEnd.animation';
import { MatchResumedAnimation } from './matchResumed.animation';
import { PlayersOnFieldAnimation } from './playersOnField.animation';
import { PlayersWarmUpAnimation } from './playersWarmUp.animation';
import { RedCardAnimation } from './redCard.animation';
import { ScoreAnimation } from './score.animation';
import { ServeAnimation } from './serve.animation';
import { SetEndAnimation } from './setEnd.animation';
import { SetStartAnimation } from './setStart.animation';
import { TechnicalTimeoutAnimation } from './technicalTimeout.animation';
import { TechnicalTimeoutEndAnimation } from './technicalTimeoutEnd.animation';
import { TimeoutAnimation } from './timeout.animation';
import { VideoRefereeAnimation } from './videoReferee.animation';
import { YellowCardAnimation } from './yellowCard.animation';

export const animationFunctions = [
    ServeAnimation,
    MatchCountdownAnimation,
    MatchEndAnimation,
    SetStartAnimation,
    SetEndAnimation,
    ScoreAnimation,
    TimeoutAnimation,
    ChallengeAnimation,
    PlayersWarmUpAnimation,
    MatchResumedAnimation,
    PlayersOnFieldAnimation,
    TechnicalTimeoutAnimation,
    TechnicalTimeoutEndAnimation,
    VideoRefereeAnimation,
];

export const animationFnMap = {
    [AnimationMap.Serve]: ServeAnimation,
    [AnimationMap.MatchCountdown]: MatchCountdownAnimation,
    [AnimationMap.MatchEnd]: MatchEndAnimation,
    [AnimationMap.RedCard]: RedCardAnimation,
    [AnimationMap.YellowCard]: YellowCardAnimation,
    [AnimationMap.SetEnd]: SetEndAnimation,
    [AnimationMap.SetStart]: SetStartAnimation,
    [AnimationMap.Timeout]: TimeoutAnimation,
    [AnimationMap.Score]: ScoreAnimation,
    [AnimationMap.Challenge]: ChallengeAnimation,
    [AnimationMap.PlayersWamUp]: PlayersWarmUpAnimation,
    [AnimationMap.MatchResumed]: MatchResumedAnimation,
    [AnimationMap.PLayersOnField]: PlayersOnFieldAnimation,
    [AnimationMap.TechnicalTimeout]: TechnicalTimeoutAnimation,
    [AnimationMap.TechnicalTimeoutEnd]: TechnicalTimeoutEndAnimation,
    [AnimationMap.VideoReferee]: VideoRefereeAnimation,
};
