import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/basketball3x3/constants';
import { useBallPossessionStatistic } from 'features/basketball3x3/hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { HalfRectangle } from './common/HalfRectangle';

export function BallPossessionAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();
    const { homeStat, awayStat, label: subText } = useBallPossessionStatistic();
    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate('action.ballPossession.animation')}
                subText={subText}
                team={team}
                homeStat={homeStat}
                awayStat={awayStat}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                isPercent
            />
            <FieldIcon animationName={AnimationMap.BallPossession} team={team} />
        </div>
    );
}

BallPossessionAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

BallPossessionAnimation.ANIMATION_NAME = AnimationMap.BallPossession;
