import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline, useTargetName } from 'hooks';
import { AnimationMap } from 'features/football/constants';
import { useFreeKickStatistic } from 'features/football/hooks';
import { FieldAttackArrow } from './common/FieldAttackArrow';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { ShotOnGoalMissCircles } from './common/ShotOnGoalMissCircles';

export function ShotOnGoalMissAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    const { conditions } = useTargetName();

    const { awayStat, homeStat, label: subtext } = useFreeKickStatistic('statistics.shotsOnGoal');

    return (
        <div ref={nodeRef}>
            <FieldAttackArrow isLong team={team} ref={rectRef} />
            <FieldText
                title={translate('action.shotOnGoalMiss.animation')}
                subText={subtext}
                homeStat={homeStat}
                awayStat={awayStat}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName="freeKick" team={team} />
            {conditions.isMostbet && <ShotOnGoalMissCircles team={team} circles={6} />}
        </div>
    );
}

ShotOnGoalMissAnimation.ANIMATION_NAME = AnimationMap.ShotOnGoalMiss;

ShotOnGoalMissAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};
