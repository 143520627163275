import { useWindowWidth } from '@react-hook/window-size/throttled';
import { useEffect, useRef } from 'react';

import { WindowSize } from 'features/football/constants';

export { useEventsLoadingState } from './useEventsLoadingState';
export { useContainerHeight } from './useContainerHeight';
export { useRectTimeline } from './useRectTimeline';
export { useNodeTimeline } from './useNodeTimeline';
export { useFootballCirclesTL } from './useFootballCirclesTL';
export { useNodeTLCallback } from './useNodeTLCallback';
export { useCallbackTL } from './useCallbackTL';
export { useTeamNumbers } from './useTeamNumbers';
export { useAnimationProps } from './useAnimationProps';
export { useTargetName } from './useTargetName';
export { useAddTargetNameClassToBody } from './useAddTargetNameClassToBody';
export { useErrorConfig } from './useErrorConfig';
export { useMainSportConfig } from './useMainSportConfig';
export { useFieldAnimatorConfig } from './useFieldAnimatorConfig';
export { useComponentRendered } from './useComponentRendered';

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useIsMobile(customSize) {
    const currentWidth = useWindowWidth({ wait: 1000 });
    return currentWidth <= (customSize || WindowSize.sm);
}
