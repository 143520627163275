import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';
import { getTieBreakType } from '../utils/common';

const Q = (log, eventData, store) => {
    const { isSetEnd, team } = eventData;
    let { game } = eventData;
    const { translationData, homeTeam, awayTeam } = store;
    const { score, games } = translationData.statistics.sets.find((t) => t.number === eventData.set);
    const isGatewayCode62 = translationData.info.gateway_code === 62;
    const isFirstGame = isGatewayCode62 && game === 1 && games.length !== 1;
    const isTieBreak = (evtData) => !!getTieBreakType(evtData, store);
    // if (isSetEnd || o && eventData.game === 1 && games.length !== 1 || eventData.game--,
    //     !isTieBreak(eventData)) {
    if (!isSetEnd || !isFirstGame) game -= 1;
    if (!isTieBreak(eventData)) {
        const l = (t) => (score[t] === 50 ? 'Ad' : score[t]);
        const gameNumber = isSetEnd ? games.length : game;
        log.push({
            component: 'PeriodLogEvent',
            type: 'gameEnd',
            firstTeamScore: l(homeTeam),
            secondTeamScore: l(awayTeam),
            periodText: i18n.t('log.gameEnd', {
                game: `${gameNumber}`,
            }),
            translucent: !0,
            period: eventData.set,
            game: gameNumber,
            id: log.length,
            wonTeam: team,
        });
    }
};

export const TennisGameEndEvent = new Event({
    type: ModelMap.TennisGameEnd,
    animationName: AnimationMap.TennisGameEnd,
    pushToLog(log, eventData, store) {
        // eslint-disable-next-line no-unused-expressions
        eventData.isSetEnd || Q(log, eventData, store);
    },
});
