import React from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationMap } from 'features/handball/constants';
import { IconText } from './common/IconText';

export function SevenMeterThrowCancelledAnimation() {
    const [translate] = useTranslation();
    return <IconText icon="sevenMeterThrowCancelled" text={translate('animation.sevenMeterCancelled')} />;
}
SevenMeterThrowCancelledAnimation.ANIMATION_NAME = AnimationMap.SevenMeterThrowCancelled;
