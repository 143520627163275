import { Event } from 'features/common/models/event.model';
import { getLocalTime } from 'features/common/utils';
import { AnimationMap, ModelMap } from 'features/floorball/constants';

export const PeriodEndEvent = new Event({
    type: ModelMap.PeriodEnd,
    animationName: AnimationMap.PeriodEnd,
    renames: ['period'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        statistics.isTimerPaused = true;
        statistics.timer = 0;
        statistics.hasPeriodEnded[evtData.period] = true;
        statistics.periodsEndDates[evtData.period] = getLocalTime(evtData.regtime);
    },
    pushAdditionalEvents(evtData, store) {
        const { period } = evtData;
        const { info } = store.translationData;
        const { periodsCount } = info.extra;
        const { needToShow } = store.countdown;

        const checkToShowPeriodCountdown = !needToShow || period < 1 || period >= periodsCount;

        if (checkToShowPeriodCountdown) {
            return [];
        }

        return [{ ...evtData, type: ModelMap.PeriodCountdown }];
    },
});
