import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useFootballCirclesTL, useTargetName } from 'hooks';
import { addToRefs } from 'features/football/utils';

function WithRefKickFromGoalCircles(props, ref) {
    const { team, circles } = props;
    const isHome = team === 1;
    const circlesRefs = useFootballCirclesTL();
    const { conditions } = useTargetName();

    const goalKickCls = cx('goalKick', {
        _left: isHome,
        _right: !isHome,
    });

    const placerClx = cx('goalKick_placer flex items-center', {
        _left: isHome,
        _right: !isHome,
    });

    const dotCls = cx('goalKick_inner-circle', {
        'football-home-team-shadow': isHome,
        'football-away-team-shadow': !isHome,
        vivi: conditions.isVivi,
    });
    return (
        <div className={goalKickCls}>
            <div ref={ref} className={placerClx}>
                <div className="goalKick_outer-circle">
                    <span className={dotCls} />
                </div>
                <div className="goalKick_circle flex">
                    {Array.from({ length: circles }, (_, index) => (
                        <span
                            ref={(el) => addToRefs(el, circlesRefs)}
                            key={index}
                            className="goalKick_circle-circles"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export const KickFromGoalCircles = React.forwardRef(WithRefKickFromGoalCircles);

WithRefKickFromGoalCircles.propTypes = {
    team: PropTypes.number.isRequired,
    circles: PropTypes.number.isRequired,
};
