import React from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationMap } from 'features/handball/constants';
import { IconText } from './common/IconText';

export function EmptyGateAnimation() {
    const [translate] = useTranslation();
    return <IconText icon="emptyGates" text={translate('animation.emptyGates')} />;
}
EmptyGateAnimation.ANIMATION_NAME = AnimationMap.EmptyGate;
