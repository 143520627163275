export const AnimationMap = {
    MatchCountdown: 'matchCountdown',
    Serve: 'serve',
    Score: 'score',
    SetEnd: 'setEnd',
    MatchEnd: 'matchEnd',
    Challenge: 'challenge',
    YellowCard: 'yellowCard',
    RedCard: 'redCard',
    PlayersWamUp: 'playersWarmUp',
    VideoReferee: 'videoReferee',
    MedicalTimeout: 'medicalTimeout',
    SetStart: 'setStart',
    PLayersOnField: 'playersOnField',
    GameIsDelayed: 'gameIsDelayed',
    TechnicalTimeout: 'technicalTimeout',
    TechnicalTimeoutEnd: 'technicalTimeoutEnd',
    Timeouts: 'Timeouts',
    MatchInterrupted: 'matchInterrupted',
    MatchResumed: 'matchResumed',
    TeamTimeout: 'teamTimeout',
    Timeout: 'timeout',
    MedicalBreak: 'medicalBreak',
    ElectricityOff: 'electricityOff',
    Judges: 'judges',
};
