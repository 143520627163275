import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const PenaltyShootoutStartEvent = new Event({
    type: ModelMap.PenaltyShootoutStart,
    // renames: ['period', 'time'],
    calculate(store) {
        // eslint-disable-next-line no-param-reassign
        store.translationData.statistics.isPenaltyStarted = true;
    },
    animationName: AnimationMap.PenaltyShootoutStart,
});

export const PenaltyShootoutEndEvent = new Event({
    type: ModelMap.PenaltyShootoutEnd,
    // renames: ['team', 'time', 'period'],
    animationName: AnimationMap.PenaltyShootoutEnd,
});
