import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { useNodeTimeline } from './useNodeTimeline';
import { useRectTimeline } from './useRectTimeline';

export const useAnimationProps = () => {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    const rectRef = useRectTimeline();
    const { current } = useContext(RootMachineContext);

    const { store } = current.context;
    const { statistics } = store.translationData;

    return {
        translate,
        nodeRef,
        rectRef,
        statistics,
        store,
    };
};
