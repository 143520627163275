import React from 'react';
import { useTranslation } from 'react-i18next';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/football/constants';
import { FullRectangle } from './common/FullRectangle';
import whistle from './data/whistle.json';

export function PeriodStartAnimation(props) {
    const { period } = props;
    const [translate] = useTranslation();
    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: whistle,
    });

    const textRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <FullRectangle />
            <div className="matchEnd">
                <div style={{ height: '50px', width: '60px' }}>
                    <Lottie lottieRef={lottieRef} width="100%" height="100%" />
                </div>
                <p ref={textRef}>{translate('action.periodStart.animation', { period })}</p>
            </div>
        </div>
    );
}

PeriodStartAnimation.propTypes = {
    period: PropTypes.number.isRequired,
};

PeriodStartAnimation.ANIMATION_NAME = AnimationMap.PeriodStart;
