import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

export const PlayersWarmUpEvent = new Event({
    animationName: AnimationMap.PlayersWarmUp,
    type: ModelMap.PlayersWarmUp,
    pushToLog(log, eventData, store) {
        const { sets: s } = store.translationData.statistics;
        log.push({
            component: 'GeneralLogEvent',
            type: 'playersWarmUp',
            text: i18n.t('log.playersWarmUp'),
            translucent: !0,
            period: s[s.length - 1].number,
            id: eventData.id,
        });
    },
});
