import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/tableTennis/constants';
import { Event } from './event.model';

export const SetEndEvent = new Event({
    type: ModelMap.SetEnd,
    // renames: ['period'],
    animationName: AnimationMap.SetEnd,
    calculate(store, evt) {
        const { sets, score, servesData } = store.translationData.statistics;
        const lastSet = sets[sets.length - 1];
        const lastScore = lastSet.score;
        const { period } = evt;
        const { info } = store.translationData;
        if (Math.max(lastScore[1], lastScore[2]) === 7) {
            // eslint-disable-next-line no-param-reassign
            store.translationData.statistics.upTo7PointsInSet = true;
        }
        if (info.gateway_code !== 82) {
            score[lastScore[1] > lastScore[2] ? 1 : 2] += 1;
        }

        const isNextSetNeeded = info.extra.sets - period;

        if (!(Math.abs(score[1] - score[2]) > isNextSetNeeded)) {
            const servingTeam = lastSet.firstServingTeam === 1 ? 2 : 1;
            // eslint-disable-next-line no-unused-expressions
            servesData.currentTeam = servingTeam;
            servesData.serveNumber = 1;
            sets.push({
                number: period + 1,
                score: {
                    1: 0,
                    2: 0,
                },
                firstServingTeam: servingTeam,
            });
        }
    },
    /* pushToLog gets log, eventData and store */
    pushToLog(log, evt, store) {
        const { period } = evt;
        const { homeTeam, awayTeam } = store;
        const { sets, score } = store.translationData.statistics;
        // eslint-disable-next-line no-unused-vars
        const { info } = store.translationData;
        const lastScore = sets.find((set) => set.number === period).score;
        const winningTeam = lastScore[homeTeam] > lastScore[awayTeam] ? homeTeam : awayTeam;
        const lastScoreEvent =
            log[
                ((lg, cb) => {
                    for (let i = log.length - 1; i > -1; i -= 1) {
                        if (cb(lg[i])) {
                            return i;
                        }
                    }
                    return -1;
                })(log, (et) => et.type === 'score')
            ];

        if (lastScoreEvent) {
            const name = i18n.t(`team${winningTeam}`);
            lastScoreEvent.text = i18n.t('log.set', { set: name });
        }
        log.push({
            component: 'PeriodLogEvent',
            type: AnimationMap.SetEnd,
            firstTeamScore: score[homeTeam],
            secondTeamScore: score[awayTeam],
            period,
            id: log.length,
        });
    },
    isPreventProcessing: (evt) => !evt.period,
});
