import * as React from 'react';

import { TARGETS } from 'features/common/constants';

const MostBetField = (props) => (
    <svg viewBox="0 0 706 370" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path stroke="#66AC3F" strokeWidth={4} d="M352 0v370M515 0v370M650 0v370m-34-211v52m34-2h-82m82-50h-82" />
        <path
            stroke="#66AC3F"
            strokeWidth={2}
            strokeDasharray="8 8"
            d="M437 0v370M350 88h268M350 40h268M350 329h268m-268-48h268"
        />
        <path stroke="#66AC3F" strokeWidth={4} d="M189 0v370M54 0v370m34-211v52m-34-2h82m-82-50h82" />
        <path
            stroke="#66AC3F"
            strokeWidth={2}
            strokeDasharray="8 8"
            d="M267 0v370m87-282H86m268-48H86m268 289H86m268-48H86"
        />
        <path stroke="#fff" strokeWidth={4} d="M2 2h696v366H2z" />
    </svg>
);

const BetAndreasField = (props) => (
    <svg viewBox="0 0 455 253" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path stroke="#3D3D4D" d="M228.5 0v253m105-253v253m88-253v253" />
        <path stroke="#3D3D4D" strokeWidth={4} d="M400.1 109.564v33.871m21.4-.606h-53.3m53.3-32.567h-53.3" />
        <path
            stroke="#3D3D4D"
            strokeDasharray="8 8"
            d="M283.9 6v241M227.5 63.471h174.2M227.5 32.205h174.2M227.5 220.446h174.2m-174.2-31.265h174.2"
        />
        <path stroke="#3D3D4D" d="M123.5 0v253M35.5 0v253" />
        <path stroke="#3D3D4D" strokeWidth={4} d="M56.5 109.564v33.87m-21.4-.605h53.3m-53.3-32.567h53.3" />
        <path
            stroke="#3D3D4D"
            strokeDasharray="8 8"
            d="M173.7 6v241m56.4-183.53H55.9m174.2-31.265H55.9m174.2 188.241H55.9m174.2-31.265H55.9"
        />
        <path stroke="#fff" strokeWidth={4} d="M2 2h451v249H2z" />
    </svg>
);
const ViviField = (props) => (
    <svg viewBox="0 0 325 180" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M.5 4A3.5 3.5 0 0 1 4 .5h317a3.5 3.5 0 0 1 3.5 3.5v172a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 176V4Z"
            stroke="#fff"
        />
        <path stroke="#2E2C66" d="M246.5 1v178M162.5 1v178" />
        <path stroke="#2E2C66" strokeDasharray="12.03 12.03" d="M120.5 1v178M204.5 1v178" />
        <path stroke="#2E2C66" d="M78.5 1v178M306.82 78v24m17.18-.5h-42m42-23.11h-42" />
        <path stroke="#2E2C66" strokeDasharray="12.03 12.03" d="M1 163.5h323M1 41.5h323M1 137.5h323M1 15.5h323" />
        <path d="M18.18 78v24M1 101.5h42M1 78.4h42" stroke="#2E2C66" />
    </svg>
);
export const Field = ({ target = TARGETS.MOSTBET, ...props }) => {
    const fieldMap = {
        [TARGETS.MOSTBET]: MostBetField,
        [TARGETS.BETANDREAS]: BetAndreasField,
        [TARGETS.VIVI]: ViviField,
    };

    return fieldMap[target](props);
};
