import { getEnvironmentTargets } from 'lib/getEnvironmentTargets';
import { TARGETS } from 'features/common/constants';

export const useTargetName = (callback) => {
    const { targetName, isMostbet, isBetandreas, isVivi } = getEnvironmentTargets();

    const classMap = callback
        ? ((target) => {
              const map = callback(TARGETS);
              return map[target];
          })(targetName)
        : undefined;

    return {
        targetName,
        targetMap: TARGETS,
        conditions: {
            isMostbet,
            isBetandreas,
            isVivi,
        },
        classMap,
    };
};
