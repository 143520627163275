/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/handball/constants';

export const TimerUnpausedEvent = new Event({
    type: ModelMap.TimerUnpaused,
    calculate(store) {
        store.translationData.statistics.isTimerPaused = false;
    },
});
