import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

class ShotOnGoalMiss extends Event {
    constructor(props) {
        const { type, redirect, animationName } = props;
        super({
            type,
            redirect,
            animationName,
            renames: ['team', 'period', 'time'],
            calculate(store, evt) {
                store.translationData.statistics.missedGoalKicks[evt.team].push({
                    time: evt.time,
                    period: evt.period,
                });
            },
        });
    }
}

export const ShotOnGoalMissEvent = new ShotOnGoalMiss({
    type: ModelMap.ShotOnGoalMiss,
    animationName: AnimationMap.ShotOnGoalMiss,
    redirect: (statistics, evt) =>
        !!statistics.ballPoint && {
            ...evt,
            type: ModelMap.ShotOnGoalMissWithPosition,
        },
});

export const ShotOnGoalMissWithPositionEvent = new ShotOnGoalMiss({
    type: ModelMap.ShotOnGoalMissWithPosition,
    animationName: AnimationMap.ShotOnGoalMiss,
});
