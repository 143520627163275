import {
    AttackAnimation,
    BallPositionAnimation,
    BallPossessionAnimation,
    CompensationTimeAnimation,
    CornerKickAnimation,
    CornerKickWithPositionAnimation,
    DangerousAttackAnimation,
    FightAnimation,
    FreeKickAnimation,
    FreeKickMissAnimation,
    GoalAnimation,
    HalfTimeEndAnimation,
    InjuryAnimation,
    KickFromGoalAnimation,
    MatchEndAnimation,
    OffsideAnimation,
    OutAnimation,
    OutWithPositionAnimation,
    PenaltyAnimation,
    PenaltyGoalAnimation,
    PenaltyMissAnimation,
    PenaltySeriesAnimation,
    PenaltyShootoutEndAnimation,
    PenaltyShootoutStartAnimation,
    PeriodStartAnimation,
    PlayersOnFieldAnimation,
    RedCardAnimation,
    ShotOnGoalAnimation,
    ShotOnGoalMissAnimation,
    SubstitutionAnimation,
    VideoRefereeAnimation,
    YellowCardAnimation,
} from 'features/football/animations';
import { AnimationMap } from 'features/football/constants';

export const animationFunctions = [
    PeriodStartAnimation,
    AttackAnimation,
    BallPositionAnimation,
    CornerKickAnimation,
    DangerousAttackAnimation,
    FreeKickAnimation,
    FreeKickMissAnimation,
    GoalAnimation,
    MatchEndAnimation,
    OutAnimation,
    PenaltyAnimation,
    PenaltyGoalAnimation,
    PenaltyMissAnimation,
    PenaltyShootoutStartAnimation,
    PenaltyShootoutEndAnimation,
    PenaltySeriesAnimation,
    RedCardAnimation,
    YellowCardAnimation,
    ShotOnGoalAnimation,
    ShotOnGoalMissAnimation,
    SubstitutionAnimation,
    BallPossessionAnimation,
    CornerKickWithPositionAnimation,
    CompensationTimeAnimation,
    OutWithPositionAnimation,
    KickFromGoalAnimation,
    HalfTimeEndAnimation,
    VideoRefereeAnimation,
    FightAnimation,
    PlayersOnFieldAnimation,
    InjuryAnimation,
    OffsideAnimation,
];

export const animationFnMap = {
    [AnimationMap.BallPosition]: BallPositionAnimation,
    [AnimationMap.IsBallPosition]: BallPositionAnimation,
    [AnimationMap.BallPossession]: BallPossessionAnimation,
    [AnimationMap.Goal]: GoalAnimation,
    [AnimationMap.CornerKick]: CornerKickAnimation,
    [AnimationMap.CornerKickWithPosition]: CornerKickWithPositionAnimation,
    [AnimationMap.YellowCard]: YellowCardAnimation,
    [AnimationMap.Substitution]: SubstitutionAnimation,
    [AnimationMap.RedCard]: RedCardAnimation,
    [AnimationMap.PeriodStart]: PeriodStartAnimation,
    [AnimationMap.Penalty]: PenaltyAnimation,
    [AnimationMap.PenaltyGoal]: PenaltyGoalAnimation,
    [AnimationMap.PenaltyMiss]: PenaltyMissAnimation,
    [AnimationMap.PenaltyShootoutStart]: PenaltyShootoutStartAnimation,
    [AnimationMap.PenaltyShootoutEnd]: PenaltyShootoutEndAnimation,
    [AnimationMap.PenaltySeries]: PenaltySeriesAnimation,
    [AnimationMap.HalfTimeEnd]: HalfTimeEndAnimation,
    [AnimationMap.Out]: OutAnimation,
    [AnimationMap.OutWithPosition]: OutWithPositionAnimation,
    [AnimationMap.ShotOnGoal]: ShotOnGoalAnimation,
    [AnimationMap.ShotOnGoalMiss]: ShotOnGoalMissAnimation,
    [AnimationMap.FreeKick]: FreeKickAnimation,
    [AnimationMap.FreeKickSportLevel]: FreeKickAnimation,
    [AnimationMap.FreeKickMiss]: FreeKickMissAnimation,
    [AnimationMap.Attack]: AttackAnimation,
    [AnimationMap.DangerousAttack]: DangerousAttackAnimation,
    [AnimationMap.CompensationTime]: CompensationTimeAnimation,
    [AnimationMap.TimerStarted]: CompensationTimeAnimation,
    [AnimationMap.KickFromGoal]: KickFromGoalAnimation,
    [AnimationMap.MatchEnd]: MatchEndAnimation,
    [AnimationMap.Offside]: OffsideAnimation,
    [AnimationMap.VideoReferee]: VideoRefereeAnimation,
    [AnimationMap.Fight]: FightAnimation,
    [AnimationMap.PlayersOnField]: PlayersOnFieldAnimation,
    [AnimationMap.Injury]: InjuryAnimation,
};
