import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import debounce from 'lodash/debounce';

import { FreezedEmitter } from 'features/common/services/emitter.service';

function onAnimationComplete() {
    FreezedEmitter.emit('animationCompleted');
}

const debouncedAnimationComplete = debounce(onAnimationComplete, 100);

export function useNodeTLCallback() {
    const nodeRef = useRef(null);

    const nodeTimeLine = useRef(
        gsap.timeline({
            // repeat: -1,
            // repeatDelay: 0.5,
            yoyo: true,
            repeat: 0,
            repeatDelay: 0,
            onComplete: debouncedAnimationComplete,
        }),
    );

    useEffect(() => {
        nodeTimeLine.current
            // .fromTo(nodeRef.current, { opacity: 1 }, { opacity: 1, duration: 0.8 })
            .fromTo(nodeRef.current, { opacity: 0 }, { opacity: 1, duration: 1.5 })
            .play();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => nodeTimeLine.current.to(nodeRef.current, { opacity: 0 }).kill();
    }, []);

    return nodeRef;
}
