import React from 'react';
import PropTypes from 'prop-types';

import { AnimationMap } from 'features/basketball/constants';
import { FreeThrow } from './common/FreeThrow';

export function FreeThrowNotScoredAnimation(props) {
    const { team } = props;

    return (
        <FreeThrow
            team={team}
            title="action.freeThrowNotScored.animation"
            subText="statistics.freeThrows"
            animationName={AnimationMap.FreeThrowNotScored}
        />
    );
}

FreeThrowNotScoredAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

FreeThrowNotScoredAnimation.ANIMATION_NAME = AnimationMap.FreeThrowNotScored;
