import Emitter from 'eventemitter3';

const EventEmitter = new Emitter();

const emitter = {
    on(event, data) {
        return EventEmitter.on(event, data);
    },
    emit(event, data = null) {
        return EventEmitter.emit(event, data);
    },
    off(event) {
        return EventEmitter.off(event);
    },
};

export const FreezedEmitter = Object.freeze(emitter);
