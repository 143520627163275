export const AnimationMap = {
    BallEnter: 'ballEnter',
    BallPossession: 'ballPossession',
    EmptyGate: 'emptyGate',
    FreeThrow: 'freeThrow',
    Goal: 'goal',
    GoalkeeperHasBall: 'goalkeeperHasBall',
    MatchCountdown: 'matchCountdown',
    MatchEnd: 'matchEnd',
    OvertimeStart: 'overtimeStart',
    PenaltyGoal: 'penaltyGoal',
    PenaltyMiss: 'penaltyMiss',
    PenaltyShootout: 'penaltyShootout',
    PenaltyThrow: 'penaltyThrow',
    PeriodCountdown: 'periodCountdown',
    PeriodEnd: 'periodEnd',
    PeriodStart: 'periodStart',
    PlayersOnField: 'playersOnField',
    RedCard: 'redCard',
    Removal: 'removal',
    SevenMeterThrow: 'sevenMeterThrow',
    SevenMeterThrowCancelled: 'sevenMeterThrowCancelled',
    TeamTimeout: 'teamTimeout',
    ThrowOnGates: 'throwOnGates',
    ThrowOnGatesMiss: 'throwOnGatesMiss',
    Timeout: 'timeout',
    YellowCard: 'yellowCard',
};
