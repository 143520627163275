import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { useFreeThrowStatistic } from 'features/basketball/hooks';
import { FieldIcon } from './FieldIcon';
import { FieldText } from './FieldText';
import { HalfRectangle } from './HalfRectangle';

export function FreeThrow(props) {
    const { team, title, subText, animationName } = props;

    const [translate] = useTranslation();

    const { awayStat, homeStat } = useFreeThrowStatistic();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate(title)}
                subText={translate(subText)}
                team={team}
                homeStat={homeStat}
                awayStat={awayStat}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName={animationName} team={team} />
        </div>
    );
}

FreeThrow.propTypes = {
    team: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    animationName: PropTypes.string.isRequired,
};
