import { AnimationMap, ModelMap } from 'features/basketball/constants';
import { Event } from 'features/common/models/event.model';
import { getCurrentFreeThrows, getLastItemIndex } from './scoreChange.model';

export const MissEvent = new Event({
    type: ModelMap.Miss,
    // renames: ['team', 'time', 'period', 'score'],
    animationName: AnimationMap.Miss,
    /* redirect gets renamed event data and statistics */
    redirect(evtData, statistics, store) {
        const { log } = store.translationData;
        const currentFreeThrow = getCurrentFreeThrows(statistics);
        const lastFreeThrow = getLastItemIndex(log, (t) => t.type === 'freeThrow');
        // const isLastThrowByCurrentTeam = log[lastFreeThrow].team !== evtData.team;
        return (
            !(!currentFreeThrow || log[lastFreeThrow].team !== evtData.team) && {
                ...evtData,
                type: ModelMap.FreeThrowNotScored,
            }
        );
    },
});
