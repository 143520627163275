import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export function WithRefFieldAttackArrow(props, ref) {
    const { team, isLong } = props;
    const isHome = team === 1;

    const classes = cx('half_rectangle', {
        _left: isHome,
        _right: !isHome,
        _long: isLong,
    });

    return (
        <div className={classes} ref={ref} style={{ opacity: 0.5 }}>
            <div className="field_arrow_icon">
                <svg
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="0 0 100 365"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 364.5V0L100 183L0 364.5Z" />
                </svg>
            </div>
        </div>
    );
}

export const FieldAttackArrow = React.forwardRef(WithRefFieldAttackArrow);

WithRefFieldAttackArrow.propTypes = {
    team: PropTypes.number.isRequired,
    isLong: PropTypes.bool,
};

WithRefFieldAttackArrow.defaultProps = {
    isLong: false,
};
