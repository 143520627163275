/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';

export const BallPossessionEvent = new Event({
    type: ModelMap.BallPossession,
    animationName: AnimationMap.BallPossession,
    calculate(store, eventData) {
        const { statistics } = store.translationData;

        statistics.possessionTeam = eventData.team;
        statistics.attacks[eventData.team].push({
            period: statistics.currentPeriod,
            time: eventData.time,
        });
    },
});
