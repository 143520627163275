import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCallbackTL, useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/iceHockey/constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { HalfRectangle } from './common/HalfRectangle';

export function PenaltyOffTargetAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate('action.penaltyOffTarget.animation')}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                team={team}
                isWithoutStats
            />
            <FieldIcon animationName="missedGoalKick" team={team} />
        </div>
    );
}

PenaltyOffTargetAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

PenaltyOffTargetAnimation.ANIMATION_NAME = AnimationMap.PenaltyOffTarget;
