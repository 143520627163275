import { AnimationMap } from 'features/basketball3x3/constants';
import { useBallPossessionStatistic } from './useBallPossessionStatistic';
import { useFoulStatistics } from './useFoulStatistics';
import { useFreeThrowStatistic } from './useFreeThrowStatistic';
import { useTeamTimeoutStatistic } from './useTeamTimeoutStatistic';

export const useStatisticsHookMap = (name) => {
    const map = {
        [AnimationMap.BallPossession]: useBallPossessionStatistic,
        [AnimationMap.Foul]: useFoulStatistics,
        [AnimationMap.FreeThrow]: useFreeThrowStatistic,
        [AnimationMap.TeamTimeout]: useTeamTimeoutStatistic,
    };

    return map[name] ? map[name]() : null;
};
