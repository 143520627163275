import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/rugby/constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { HalfRectangle } from './common/HalfRectangle';

export function LineOutAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();
    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate('action.lineOut.animation')}
                team={team}
                isWithoutStats
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                isPercent
            />
            <FieldIcon animationName="lineOut" team={team} />
        </div>
    );
}

LineOutAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

LineOutAnimation.ANIMATION_NAME = AnimationMap.LineOut;
