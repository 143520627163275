import { BallPositionEvent } from './ballPosition.model';
import { BallPossessionEvent } from './ballPossession.model';
import { Card } from './card.model';
import { Conversion } from './conversion.model';
import { DropGoal } from './dropGoal.model';
import { FreeKick } from './freeKick';
import { LineBreak } from './lineBreak.model';
import { LineOut } from './lineOut.model';
import { MatchEnd } from './matchEnd.model';
import { Out } from './out.model';
import { PenaltyKickScored } from './penaltyKickScored.model';
import { PeriodEnd } from './periodEnd';
import { PeriodStarted } from './periodStarted.model';
import { PlayersOnField } from './playersOnField.model';
import { PlayersWarmupEvent } from './playersWarmup.model';
import { ScoreModel } from './score.model';
import { Scrum } from './scrum.model';
import { ScrumWinsModel } from './scrumWins.model';
import { Substitution } from './substitution.model';
import { TimerDisabled, TimerEnabled } from './timer.model';
import { Try } from './try.model';
import { TwentyTwoMeterReplay } from './twentyTwoMeterReplay.model';

export const eventFactory = () => [
    BallPossessionEvent,
    PlayersWarmupEvent,
    Scrum,
    ScrumWinsModel,
    Try,
    FreeKick,
    LineBreak,
    ScoreModel,
    TwentyTwoMeterReplay,
    Substitution,
    Out,
    TimerEnabled,
    TimerDisabled,
    PeriodEnd,
    PlayersOnField,
    PenaltyKickScored,
    MatchEnd,
    PeriodStarted,
    Card,
    LineOut,
    DropGoal,
    Conversion,
    BallPositionEvent,
];
