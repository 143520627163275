import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/rugby/constants';

//  TODO Magic is here
export const FreeKick = new Event({
    type: [ModelMap.PenaltyKick, ModelMap.FreeKick, ModelMap.TwentyTwoMetreDropOut, ModelMap.ConversionPosition],
    animationName: AnimationMap.FreeKick,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        const { penalties } = statistics;
        const { type, team, period } = eventData;
        if (type === ModelMap.PenaltyKick) {
            penalties[team].push({
                period,
                isScored: false,
            });
        }
    },
});
