import { ModelMap } from 'features/football/constants';
import { Event } from './event.model';

class WithoutAnimation extends Event {
    /* eslint-disable class-methods-use-this */
    get isWithoutAnimation() {
        return false;
    }
}

export const CornerWithoutAnimationEvent = new WithoutAnimation({
    type: ModelMap.CornerWithoutAnimation,
    // renames: ['corner'],
    redirect({ id, regtime, corner }, statistics) {
        const { team, period, time } = statistics.cornerKickWithPosition;
        return {
            type: ModelMap.CornerKickWithCornerNumber,
            // TODO - remove after test
            // i1: team,
            // i2: period,
            // i3: time,
            // i4: evt.corner === 1 || evt.corner === 4 ? 2 : 1,
            team,
            period,
            time,
            id,
            regtime,
            cornerNumber: corner === 1 || corner === 4 ? 2 : 1,
        };
    },
    isPreventProcessing: (t, e) => !e.translationData.statistics.cornerKickWithPosition,
});
