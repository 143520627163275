import { AnimationMap } from 'features/iceHockey/constants';
import { useAttackStatistic } from './useAttackStatistic';
import { usePenaltyStatistics } from './usePenaltyStatistics';
import { useRemovalStatistic } from './useRemovalStatistic';
import { useShotOnGoalStatistic } from './useShotOnGoalStatistic';

export const useStatisticsHookMap = (name) => {
    const map = {
        [AnimationMap.Attack]: useAttackStatistic,
        [AnimationMap.Penalty]: usePenaltyStatistics,
        [AnimationMap.Removal]: useRemovalStatistic,
        [AnimationMap.ShotOnGoal]: useShotOnGoalStatistic,
    };

    return map[name] ? map[name]() : null;
};
