import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useNodeTLCallback, useTargetName } from 'hooks';

export function TitleCaption(props) {
    const { title, caption } = props;

    const nodeRef = useNodeTLCallback();
    const { classMap: targetPrefix } = useTargetName((targets) => ({
        [targets.MOSTBET]: '_mostbet',
        [targets.BETANDREAS]: '_betandreas',
        [targets.VIVI]: '_vivi',
    }));

    return (
        <div ref={nodeRef}>
            <div className="bb-full_rectangle" />
            <div className="bb-playing_status">
                <div className="bb-playing_status--title">
                    <p>{title}</p>
                </div>
                <div className={cx('bb-playing_status--subtext', targetPrefix)}>
                    <p>{caption}</p>
                </div>
            </div>
        </div>
    );
}

TitleCaption.propTypes = {
    title: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
};
