export const ModelMap = {
    MatchCountdown: -2e4,
    PeriodCountdown: -20001,
    BallPossession: 1532,
    Foul: 1518,
    FreeThrowPlus: 1524,
    FreeThrowMinus: -1524,
    TeamTimeout: 1128,
    Timeout: -1128,
    PlayersOnField: 1206,
    ScoreChange: 1536,
    FreeThrowGoal: -15361524,
    Fight: 1170,
    PeriodStart: 1118,
    TimerPaused: 1017,
    PeriodEnd: 1102,
    MatchEnd: 1103,
    FreeThrowNotScored: 1530,
    Removal: 1547,
    Miss: 1534,
    FreeThrowCanceled: 1553,
};
