import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/floorball/constants';

export function getPeriodText(period, firstLog, secondLog, firstString = '', secondString = '', store = {}) {
    const { statistics } = store.translationData;
    const { periodsCount } = statistics;

    if (period > periodsCount) {
        return {
            text: i18n.t(secondLog, { period }),
            // text: `${period} период`,
            svg: `log/${secondString}`,
        };
    }
    return {
        // text: 'Овертайм',
        text: i18n.t(firstLog, { period }),
        svg: `log/${firstString}`,
    };
}

export const PeriodStartEvent = new Event({
    type: ModelMap.PeriodStart,
    renames: ['period'],
    animationName: AnimationMap.PeriodStart,
    calculate(store, evtData) {
        const { statistics, info } = store.translationData;
        const { periodDuration, overtimeDuration, periodsCount } = info.extra;
        const timer = evtData.period < periodsCount + 1 ? periodDuration : overtimeDuration;

        statistics.isTimerPaused = false;
        statistics.timer = 60 * timer;
        statistics.hasGameStarted = true;
    },
    pushToLog(log, evtData, store) {
        const { statistics } = store.translationData;
        const { homeTeam, awayTeam } = store;
        const { text } = getPeriodText(evtData.period, 'period.byIndex', 'period.overtime.short', null, null, store);
        log.push({
            periodText: text,
            firstTeamScore: statistics.goals[homeTeam].length,
            secondTeamScore: statistics.goals[awayTeam].length,
            type: 'period',
            component: 'PeriodLogEvent',
            period: evtData.period,
            id: evtData.id,
        });
    },
});
