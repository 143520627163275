/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/futsal/constants';
import { Event } from './event.model';

export const PlayersOnFieldEvent = new Event({
    type: ModelMap.PlayersOnField,
    animationName: AnimationMap.PlayersOnField,
    calculate(store) {
        store.translationData.statistics.isTimerPaused = true;
    },
});
