import { ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

export const SetScoringAdvantageEvent = new Event({
    type: ModelMap.SetScoringAdvantage,
    renames: ['shortGame'],
    calculate(store, evtData) {
        const { isShortGame } = evtData;
        // eslint-disable-next-line no-param-reassign
        store.advantageScoring = !isShortGame;
    },
});
