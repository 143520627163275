import { assign } from 'xstate';

import { machineUtils } from 'features/common/utils';

const assignStore = ({ context, event }) => {
    context.eventProcessor.processInitialEvents(event.output);
    const { log, statistics } = context.eventProcessor.store.translationData;

    return {
        ...context.store,
        translationData: {
            log,
            ...context.store.translationData,
            statistics: { ...statistics },
        },
    };
};

export const initialEvents = {
    invoke: {
        id: 'initialEvents-invoke',
        src: 'translationEvents',
        input: ({ context }) => ({
            translationId: context.translationId,
            lastEventId: context.lastEventId,
        }),
        onDone: [
            { target: 'initialEventsDelay', guard: 'hasGotNoInitialEvents' },
            {
                target: 'delay',
                actions: assign({
                    store: assignStore,
                    lastEventId: machineUtils.lastEventIdAction,
                }),
            },
        ],
        onError: {
            target: 'failure',
            actions: assign({ error: ({ event }) => event.error }),
        },
    },
};
