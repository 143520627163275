import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/iceHockey/constants';
import { IconText } from './common/IconText';

export function VideoRefereeAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div ref={nodeRef}>
            <IconText icon="videoReferee" text={translate('action.videoReferee.animation')} />
        </div>
    );
}

VideoRefereeAnimation.ANIMATION_NAME = AnimationMap.VideoReferee;
