import redCard from './redCard.json';
import score from './score.json';
import serve from './serve.json';
import timeout from './timeout.json';
import yellowCard from './yellowCard.json';

export const animationDataMap = {
    serve,
    score,
    timeout,
    yellowCard,
    redCard,
};
