import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useYellowCardStatistic = (subtext = 'statistics.yellowCards') => {
    const [translate] = useTranslation();

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam: HOME, awayTeam: AWAY } = store;
    const { statistics } = store.translationData;
    const { yellowCards } = statistics;
    const homeStat = yellowCards[HOME].length;
    const awayStat = yellowCards[AWAY].length;

    return {
        homeStat,
        awayStat,
        label: translate(subtext),
    };
};
