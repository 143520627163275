import { AnimationMap } from 'features/bigTennis/constants';
import { AceAnimation } from './ace.animation';
import { BathroomBreakAnimation } from './bathroomBreak.animation';
import { CallForTrainerAnimation } from './callForTrainer.animation';
import { ChallengeAnimation } from './challenge.animation';
import { DoubleFoulAnimation } from './doubleFoul.animation';
import { MatchCountdownAnimation } from './matchCountdown.animation';
import { MatchEndAnimation } from './matchEnd.animation';
import { MedicalBreakAnimation } from './medicalBreak.animation';
import { PlayersWarmUpAnimation } from './playersWarmUp.animation';
import { ScoreAnimation } from './score.animation';
import { SecondServeAnimation } from './secondServe.animation';
import { ServeAnimation } from './serve.animation';
import { TennisGameEndAnimation } from './tennisGameEnd.animation';
import { TennisSetEndAnimation } from './tennisSetEnd.animation';
import { TimerPausedAnimation } from './timerPaused.animation';
import { TimerStartedAnimation } from './timerStarted.animation';
import { ViolationAnimation } from './violation.animation';
import { WeatherBreakAnimation } from './weatherBreak.animation';

export const animationFunctions = [
    ServeAnimation,
    ScoreAnimation,
    SecondServeAnimation,
    MatchCountdownAnimation,
    TennisGameEndAnimation,
    TennisSetEndAnimation,
    MedicalBreakAnimation,
    WeatherBreakAnimation,
    BathroomBreakAnimation,
    CallForTrainerAnimation,
    PlayersWarmUpAnimation,
    ViolationAnimation,
    DoubleFoulAnimation,
    AceAnimation,
    MatchEndAnimation,
    ChallengeAnimation,
    TimerStartedAnimation,
    TimerPausedAnimation,
];

export const animationFnMap = {
    [AnimationMap.Serve]: ServeAnimation,
    [AnimationMap.Score]: ScoreAnimation,
    [AnimationMap.SecondServe]: SecondServeAnimation,
    [AnimationMap.MatchCountdown]: MatchCountdownAnimation,
    [AnimationMap.TennisGameEnd]: TennisGameEndAnimation,
    [AnimationMap.TennisSetEnd]: TennisSetEndAnimation,
    [AnimationMap.MedicalBreak]: MedicalBreakAnimation,
    [AnimationMap.WeatherBreak]: WeatherBreakAnimation,
    [AnimationMap.BathroomBreak]: BathroomBreakAnimation,
    [AnimationMap.CallForTrainer]: CallForTrainerAnimation,
    [AnimationMap.PlayersWarmUp]: PlayersWarmUpAnimation,
    [AnimationMap.Violation]: ViolationAnimation,
    [AnimationMap.DoubleFoul]: DoubleFoulAnimation,
    [AnimationMap.Ace]: AceAnimation,
    [AnimationMap.MatchEnd]: MatchEndAnimation,
    [AnimationMap.Challenge]: ChallengeAnimation,
    [AnimationMap.TimerStarted]: TimerStartedAnimation,
    [AnimationMap.TimerPaused]: TimerPausedAnimation,
};
