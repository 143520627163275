import React from 'react';

import { FieldRedCard } from 'ui/atoms/Icons/FieldRedCard';
import { AnimationMap, ModelMap } from 'features/futsal/constants';
import { Event } from './event.model';

export const RedCardEvent = new Event({
    type: ModelMap.RedCard,
    // renames: ['team', 'period', 'time'],
    animationName: AnimationMap.RedCard,
    calculate(store, { team, period, time }) {
        store.translationData.statistics.redCards[team].push({
            time,
            period,
        });
    },
    pushToLog(log, evt, translationData) {
        const { matchDuration } = translationData.statistics;
        log.push({
            time: evt.time,
            type: 'redCard',
            component: 'DefaultLogEvent',
            period: evt.period,
            team: evt.team,
            id: evt.id,
            svg: <FieldRedCard width="100%" height="100%" />,
            matchDuration,
        });
    },
});
