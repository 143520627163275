import { hasLength } from 'lib';
import { getlastEventId } from 'features/common/services/event.service';

const storeAction = ({ context, event }) => {
    context.eventProcessor.checkForNewEvents(event.output);
    const { log, statistics } = context.eventProcessor.store.translationData;

    return {
        ...context.eventProcessor.store,
        translationData: {
            log,
            ...context.store.translationData,
            statistics: { ...statistics },
        },
    };
};

const lastEventIdAction = ({ context, event }) => {
    if (hasLength(event.output)) {
        return getlastEventId(event.output);
    }
    return context.lastEventId;
};

export const machineUtils = {
    storeAction,
    lastEventIdAction,
};
