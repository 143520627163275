import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/futsal/constants';
import { AttackAnimation } from './attack.animation';
import { BallPossessionAnimation } from './ballPossession.animation';
import { DangerousAttackAnimation } from './dangerousAttack.animation';

export function BallPositionAnimation(props) {
    // todo [drone] maybe need to remove
    const { team } = props;
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;

    const componentsMap = {
        [BallPositionAnimation.animationNames.ballPossession]: BallPossessionAnimation,
        [BallPositionAnimation.animationNames.attack]: AttackAnimation,
        [BallPositionAnimation.animationNames.dangerousAttack]: DangerousAttackAnimation,
    };

    const getRelativePoint = () => {
        const coords = {
            x: null,
            y: null,
        };
        const { ballPoint } = store.translationData.statistics;
        if (ballPoint) {
            coords.x = team === store.awayTeam ? 100 - ballPoint.x : ballPoint.x;
            coords.y = ballPoint.y;
        }
        return coords;
    };

    const getPossessionType = () => {
        const { x } = getRelativePoint();
        if (!x) {
            return 'ballPossession';
        }

        // eslint-disable-next-line no-nested-ternary
        return x <= 50
            ? BallPositionAnimation.animationNames.ballPossession
            : x <= 75
              ? BallPositionAnimation.animationNames.attack
              : BallPositionAnimation.animationNames.dangerousAttack;
    };

    return <>{componentsMap[getPossessionType()]}</>;
}

BallPositionAnimation.animationNames = {
    ballPossession: 'ballPossession',
    attack: 'attack',
    dangerousAttack: 'dangerousAttack',
};

BallPositionAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

BallPositionAnimation.ANIMATION_NAME = AnimationMap.BallPosition;
