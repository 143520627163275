import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/floorball/constants';

export const UnpauseTimerEvent = new Event({
    type: ModelMap.UnpauseTimer,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isTimerPaused = false;
    },
});

export const PauseTimerEvent = new Event({
    type: ModelMap.PauseTimer,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isTimerPaused = true;
    },
    isPreventProcessing: (_, store) => store.translationData.statistics.isMatchEnded,
});
