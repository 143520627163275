import { AttackEvent, DangerousAttack } from './attack.model';
import { BallPositionEvent } from './ballPosition.model';
import { BallPossessionEvent } from './ballPossession.model';
import { CornerKickEvent, CornerKickWithPosition } from './cornerKick.model';
import { FoulEvent } from './foul.model';
import { FreeKickEvent, FreeKickEventWithPosition, FreeKickMiss } from './freeKick.model';
import { GoalEvent } from './goal.model';
import { HalfTimeEndEvent } from './halfTimeEnd.model';
import { PenaltyEvent } from './penalty.model';
import { PenaltyGoalEvent } from './penaltyGoal.model';
import { PenaltyMissEvent } from './penaltyMiss.model';
import { PenaltyShootoutEndEvent, PenaltyShootoutStartEvent } from './penaltyShootout.model';
import { PeriodStartEvent } from './periodStart.model';
import { PlayersOnFieldEvent } from './playersOnField.model';
import { RedCardEvent } from './redCard.model';
import { TimePausedPreventFalse, TimePausedPreventTrue } from './timerPaused.model';
import { YellowCardEvent } from './yellowCard.model';

export const eventFactory = () => [
    AttackEvent,
    DangerousAttack,
    BallPositionEvent, // todo [drone] maybe remove
    BallPossessionEvent,
    FreeKickEventWithPosition,
    FreeKickEvent,
    FreeKickMiss,
    PeriodStartEvent,
    PlayersOnFieldEvent,
    HalfTimeEndEvent,
    GoalEvent,
    CornerKickWithPosition,
    CornerKickEvent,
    YellowCardEvent,
    RedCardEvent,
    FoulEvent,
    PenaltyShootoutEndEvent,
    PenaltyShootoutStartEvent,
    PenaltyMissEvent,
    PenaltyGoalEvent,
    PenaltyEvent,
    TimePausedPreventTrue,
    TimePausedPreventFalse,
];
