import { AnimationMap, ModelMap } from 'features/basketball3x3/constants';
import { Event } from 'features/common/models/event.model';
import { ballPossessionUnkownFn } from './ballPossession.model';

export const MatchEndEvent = new Event({
    type: ModelMap.MatchEnd,
    animationName: AnimationMap.MatchEnd,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isMatchEnded = true;
        statistics.isTimerPaused = true;
        ballPossessionUnkownFn(0, store);
        statistics.timer = 0;
    },
});
