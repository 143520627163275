import { assign } from 'xstate';

import { machineUtils } from 'features/common/utils';

export const disabled = {
    invoke: {
        id: 'disabled-invoke',
        src: 'disabled',
        input: ({ context }) => ({
            translationId: context.translationId,
            lastEventId: context.lastEventId,
        }),
        onDone: {
            target: 'delay',
            actions: assign({
                store: machineUtils.storeAction,
                lastEventId: machineUtils.lastEventIdAction,
            }),
        },
        onError: {
            target: 'disabledDelay',
        },
    },
};
