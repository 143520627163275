import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export function useNodeTimeline(duration = 0.8) {
    const nodeRef = useRef();

    const nodeTimeLine = useRef(
        gsap.timeline({
            repeat: 0,
        }),
    );

    useEffect(() => {
        nodeTimeLine.current.fromTo(nodeRef.current, { opacity: 0 }, { opacity: 1, duration });

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            nodeTimeLine.current.to(nodeRef.current, { opacity: 0 }).kill();
        };
    }, [duration, nodeRef]);

    return nodeRef;
}
