import React from 'react';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTargetName } from 'hooks';
import { animationDataMap } from 'features/football/animations/data';

export function CenterIcon(props) {
    const { animationName } = props;

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: animationDataMap[animationName],
    });

    const { classMap } = useTargetName((targets) => ({
        [targets.MOSTBET]: 'h-7 w-7',
        [targets.BETANDREAS]: 'h-16 w-16',
        [targets.VIVI]: 'h-16 w-16',
    }));

    return (
        <div className={cn(classMap, 'text-white sm:h-18 sm:w-18')}>
            <Lottie lottieRef={lottieRef} width="100%" height="100%" />
        </div>
    );
}

CenterIcon.propTypes = {
    animationName: PropTypes.string.isRequired,
};
