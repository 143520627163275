export const WindowProportions = {
    defaultWidthProportion: 525,
    defaultHeightProportion: 277,
    defaultMobileWidthProportion: 300,
    defaultMobileHeightProportion: 157.03,
    heightRatio() {
        return WindowProportions.defaultHeightProportion / WindowProportions.defaultWidthProportion;
    },
    heightMobileRatio() {
        return WindowProportions.defaulMobiletWidthProportion / WindowProportions.defaultMobileHeightProportion;
    },
};
