import i18n from 'i18n';

import { ModelMap } from 'features/badminton/constants';
import { Event } from 'features/common/models/event.model';
import { ze } from './common';

export const SetEndOfSetEvent = new Event({
    type: ModelMap.SetEndOfSet,
    animationName: 'setEnd',
    // renames: ['period'],
    calculate(store, eventData) {
        const { period } = eventData;
        const { servesData: i, sets: n, score } = store.translationData.statistics;
        const { homeTeam, awayTeam } = store;
        i.servePosition = 'right';
        const currentSet = n[n.length - 1];
        // eslint-disable-next-line no-plusplus,no-unused-expressions,no-sequences,max-len
        currentSet.isEnd = !0;
        if (currentSet.score[1] > currentSet.score[2]) score[1] += 1;
        else score[2] += 1;
        // ze - if match is going to end
        if (period < 3 && !ze(n, homeTeam, awayTeam)) {
            n.push({
                number: period + 1,
                score: { 1: 0, 2: 0 },
                isStart: false,
                isEnd: false,
            });
        }
    },
    pushToLog(log, eventData, store) {
        const { sets: i } = store.translationData.statistics;
        const { period } = eventData;
        const s = i.filter((e) => e.isEnd);
        let r = 0;
        let o = 0;
        s.forEach((e) => {
            if (e.score[1] > e.score[2]) r += 1;
            else o += 1;
        });
        const l = { 1: r, 2: o };
        log.push({
            component: 'PeriodLogEvent',
            type: 'setEnd',
            firstTeamScore: l[store.homeTeam],
            secondTeamScore: l[store.awayTeam],
            periodText: i18n.t('time.set', { period }),
            timeText: i18n.t('time.set', { period }),
            period,
            id: log.length,
        });
    },
});
