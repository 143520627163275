import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/rugby/constants';

export const ScoreModel = new Event({
    type: ModelMap.Score,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        statistics.score[eventData.team] = eventData.score;
    },
});
