export const statistics = () => ({
    timer: 0,
    isTimerPaused: true,
    isMatchEnded: false,
    currentFreeThrows: [],
    currentPeriod: -1,
    periodsEndDates: {},
    goals: {
        1: [],
        2: [],
    },
    score: {
        1: 0,
        2: 0,
    },
    fouls: {
        1: [],
        2: [],
    },
    scoreChanges: {
        1: [],
        2: [],
    },
    freeThrows: {
        1: [],
        2: [],
    },
    freeThrowsRealizations: {
        1: [],
        2: [],
    },
    ballPossession: {
        1: [],
        2: [],
    },
    timeouts: {
        1: 0,
        2: 0,
    },
});
