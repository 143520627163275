import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCallbackTL, useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/iceHockey/constants';
import { usePenaltyStatistics } from 'features/iceHockey/hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { HalfRectangle } from './common/HalfRectangle';

export function PenaltyAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const nodeRef = useNodeTimeline();
    const rectRef = useRectTimeline();
    useCallbackTL(nodeRef);

    const { awayStat, homeStat, label: subText } = usePenaltyStatistics();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate('action.penaltyShot.animation')}
                subText={subText}
                team={team}
                homeStat={homeStat}
                awayStat={awayStat}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName="penalty" team={team} />
        </div>
    );
}

PenaltyAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

PenaltyAnimation.ANIMATION_NAME = AnimationMap.Penalty;
