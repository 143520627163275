import { ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

export const SetServingTeamEvent = new Event({
    type: ModelMap.SetServingTeam,
    renames: ['set', 'game', 'team', 'time'],
    calculate(store, evtData) {
        const { team } = evtData;
        const { statistics } = store.translationData;
        statistics.servesData.currentTeam = team;
    },
});
