import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';

export const PenaltyGoalEvent = new Event({
    type: ModelMap.PenaltyGoal,
    animationName: AnimationMap.PenaltyGoal,
    renames: ['team', 'period', 'time'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        statistics.penaltyShootout[evtData.team].push({
            time: evtData.time,
            type: 'goal',
            period: evtData.period,
        });
    },
});

export const PenaltyEvent = new Event({
    type: ModelMap.Penalty,
    renames: ['team', 'period', 'time'],
    animationName: AnimationMap.Penalty,
});

export const PenaltyShotEvent = new Event({
    type: ModelMap.PenaltyShot,
    renames: ['team'],
    animationName: AnimationMap.PenaltyShot,
});

export const PenaltyStartEvent = new Event({
    type: ModelMap.PenaltyStart,
    renames: ['period', 'time'],
    animationName: AnimationMap.PenaltyStart,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isPenaltyStarted = true;
    },
});

export const PenaltyEndEvent = new Event({
    type: ModelMap.PenaltyEnd,
    animationName: AnimationMap.PenaltyEnd,
});

export const PenaltyDelayEvent = new Event({
    type: ModelMap.PenaltyDelay,
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        statistics.delayedPenalty[evtData.team] = !0;
    },
});
