/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const CompensationTimeEvent = new Event({
    type: ModelMap.CompensationTime,
    // renames: ['compensationTime', 'period'],
    animationName: AnimationMap.CompensationTime,
    calculate(store, evt) {
        store.translationData.statistics.compensationTime[evt.period] = evt.compensationTime;
    },
    isPreventProcessing: (evt) => evt.compensationTime === 0,
});
export const TimerStartedEvent = new Event({
    type: ModelMap.TimerStarted,
    animationName: AnimationMap.CompensationTime,
    calculate(store, evt) {
        store.translationData.statistics.compensationTime[evt.period] = evt.compensationTime;
    },
    isPreventProcessing: (evt) => evt.compensationTime === 0,
});
