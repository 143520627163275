/* eslint-disable */
import { ModelMap, AnimationMap } from 'features/football/constants';
import { Event } from './event.model';

const ATTACK_TYPE_POSSESION_COORD = 50;
const ATTACK_TYPE_ATACK_COORD = 75;

class BallPoint {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    isEqual(coords) {
        return this.x === coords.x && this.y === coords.y;
    }
}

export function calculatePosession(t, e) {
    /* TODO: NEED TO DEOBFUSCATION */
    var a,
        { team: i } = e,
        { ballPossessionData: n } = t,
        s = n[1][n[1].length - 1],
        r = n[2][n[2].length - 1];
    (a = s || r ? (s && !r ? s : !s && r ? r : r.eventTime > s.eventTime ? r : s) : void 0) &&
        (a.possessionTime = e.time - a.eventTime),
        n[i].push({
            possessionTime: 0,
            eventTime: e.time,
            period: e.period,
        });
    // const { ballPossessionData: bPD } = statistics;
    // const { team } = event;
    // let teamPossesingBall;
    // const home = bPD[1][bPD[1].length - 1];
    // const away = bPD[2][bPD[2].length - 1];

    // if (home || away) {
    //   teamPossesingBall = home || away;
    // }

    // if (home && !away) {
    //   teamPossesingBall = home;
    // } else if (!home && away) {
    //   teamPossesingBall = away;
    // }

    // if (home && away) {
    //   teamPossesingBall = away.eventTime > home.eventTime ? away : home;
    // }

    // if (teamPossesingBall) {
    //   teamPossesingBall.possessionTime = event.time - teamPossesingBall.eventTime;
    // } else {
    //   bPD[team].push({
    //     possessionTime: 0,
    //     eventTime: event.time,
    //     period: event.period,
    //   });
    // }
}

export function attackType(x) {
    if (x <= ATTACK_TYPE_POSSESION_COORD) {
        return 'possession';
    }
    if (x <= ATTACK_TYPE_ATACK_COORD) {
        return 'attack';
    }
    return 'dangerousAttack';
}

export const BallPositionEvent = new Event({
    type: ModelMap.BallPosition,
    // renames: ["x", "y", "team", "period", "time"],
    animationName: AnimationMap.BallPosition,
    /* isPreventProcessing gets processedEventData and store */
    isPreventProcessing(event, store) {
        const { lastBypassBallPositionEventData } = store.translationData.statistics;
        return Boolean(
            lastBypassBallPositionEventData &&
                lastBypassBallPositionEventData.time === event.time &&
                lastBypassBallPositionEventData.team === event.team,
        );
    },
    calculate(store, event) {
        const { lastPossessionTeam, ballPoint, lastActualPossessionTeam } = store.translationData.statistics;

        store.translationData.statistics.lastBallPositionCalculateResult = 'unchanged';

        /* TODO: NEED TO DEOBFUSCATION */
        store.translationData.info.teamsreverse && (event.x = 100 - event.x);

        if (event.team === 0) {
            event.team = lastPossessionTeam === 1 ? 2 : 1;
        } else {
            store.translationData.statistics.lastPossessionTeam = event.team;
        }

        calculatePosession(store.translationData.statistics, event);
        store.translationData.statistics.lastActualPossessionTeam = event.team;

        store.translationData.statistics.ballPoint = new BallPoint(event.x, event.y);

        let attackSelection = false;
        const xCoordinate = event.team === store.homeTeam ? event.x : 100 - event.x;
        const atkType = attackType(xCoordinate);

        if (lastActualPossessionTeam !== event.team) {
            attackSelection = true;
        } else {
            const xCoords = event.team === store.homeTeam ? ballPoint.x : 100 - ballPoint.x;
            attackSelection = attackType(xCoords) !== atkType;
        }

        /* TODO: NEED TO DEOBFUSCATION */
        if (attackSelection) {
            return atkType === 'attack'
                ? (store.translationData.statistics.attacks[event.team].push({
                      time: event.time,
                      period: event.period,
                  }),
                  void (store.translationData.statistics.lastBallPositionCalculateResult = 'newAttack'))
                : atkType === 'dangerousAttack'
                  ? (store.translationData.statistics.dangerousAttacks[event.team].push({
                        time: event.time,
                        period: event.period,
                    }),
                    void (store.translationData.statistics.lastBallPositionCalculateResult = 'newDangerousAttack'))
                  : void (store.translationData.statistics.lastBallPositionCalculateResult = 'newPossession');
        }
    },
});

export const IsBallPositionEvent = new Event({
    type: ModelMap.IsBallPosition,
    animationName: AnimationMap.IsBallPosition,
    isPreventProcessing(event, store) {
        const { lastBypassBallPositionEventData } = store.translationData.statistics;
        return Boolean(
            lastBypassBallPositionEventData &&
                lastBypassBallPositionEventData.time === event.time &&
                lastBypassBallPositionEventData.team === event.team,
        );
    },
    calculate(store, event) {
        const { lastPossessionTeam, ballPoint, lastActualPossessionTeam } = store.translationData.statistics;

        store.translationData.statistics.lastBallPositionCalculateResult = 'unchanged';

        /* TODO: NEED TO DEOBFUSCATION */
        store.translationData.info.teamsreverse && (event.x = 100 - event.x);

        if (event.team === 0) {
            event.team = lastPossessionTeam === 1 ? 2 : 1;
        } else {
            store.translationData.statistics.lastPossessionTeam = event.team;
        }

        calculatePosession(store.translationData.statistics, event);
        store.translationData.statistics.lastActualPossessionTeam = event.team;

        store.translationData.statistics.ballPoint = new BallPoint(event.x, event.y);

        let attackSelection = false;
        const xCoordinate = event.team === store.homeTeam ? event.x : 100 - event.x;
        const atkType = attackType(xCoordinate);

        if (lastActualPossessionTeam !== event.team) {
            attackSelection = true;
        } else {
            const xCoords = event.team === store.homeTeam ? ballPoint.x : 100 - ballPoint.x;
            attackSelection = attackType(xCoords) !== atkType;
        }

        /* TODO: NEED TO DEOBFUSCATION */
        if (attackSelection) {
            return atkType === 'attack'
                ? (store.translationData.statistics.attacks[event.team].push({
                      time: event.time,
                      period: event.period,
                  }),
                  void (store.translationData.statistics.lastBallPositionCalculateResult = 'newAttack'))
                : atkType === 'dangerousAttack'
                  ? (store.translationData.statistics.dangerousAttacks[event.team].push({
                        time: event.time,
                        period: event.period,
                    }),
                    void (store.translationData.statistics.lastBallPositionCalculateResult = 'newDangerousAttack'))
                  : void (store.translationData.statistics.lastBallPositionCalculateResult = 'newPossession');
        }
    },
});
