import React, { useReducer } from 'react';

import { getQsValue } from 'lib';
import { ErrorBoundary } from 'ui/molecules';

export const CurrentScreen = {
    field: 'field',
    statistics: 'statistics',
    course: 'course',
};

const screenFromQs = getQsValue('tab');
const initialState = {
    currentScreen: CurrentScreen[screenFromQs] || CurrentScreen.field,
};

export const CurrentScreenActions = {
    SET_CURRENT_SCREEN: 'SET_CURRENT_SCREEN',
};

function currentScreenReducer(state, action) {
    switch (action.type) {
        case CurrentScreenActions.SET_CURRENT_SCREEN:
            return {
                ...state,
                currentScreen: action.payload.screen,
            };
        default:
            return state;
    }
}

export const CurrentScreenContext = React.createContext(initialState);

export function CurrentScreenProvider(props) {
    const { children } = props;
    const [state, dispatch] = useReducer(currentScreenReducer, initialState);

    const { currentScreen } = state;

    function setCurrentScreen(screen) {
        dispatch({
            type: CurrentScreenActions.SET_CURRENT_SCREEN,
            payload: {
                screen,
            },
        });
    }

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const values = { setCurrentScreen, currentScreen };
    return (
        <CurrentScreenContext.Provider value={values}>
            <ErrorBoundary isInline>{children}</ErrorBoundary>
        </CurrentScreenContext.Provider>
    );
}
