import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/tableTennis/constants';
import { Event } from './event.model';

export const SetStartEvent = new Event({
    type: ModelMap.SetStart,
    // renames: ['period'],
    animationName: AnimationMap.SetStart,
    /* pushToLog gets log, eventData and store */
    pushToLog(log, evt) {
        const { period } = evt;
        log.push({
            component: 'GeneralLogEvent',
            type: AnimationMap.SetStart,
            text: i18n.t('log.setStart', { set: period }),
            period,
            translucent: true,
            id: log.length,
        });
    },

    pushAdditionalEvents(evt, store) {
        const { statistics } = store.translationData;
        const { servesData } = statistics;
        if (evt.period) {
            return [];
        }
        return [{ ...evt, servingTeam: servesData.currentTeam, type: ModelMap.Serve }];
    },
});
