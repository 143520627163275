/* eslint-disable new-parens */
/* eslint-disable no-param-reassign */
import { getLocalTime } from 'features/common/utils';
import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';
import { getTimerTime } from './matchEnd.model';

export const HalfTimeEndEvent = new Event({
    type: ModelMap.HalfTimeEnd,
    // renames: ['period'],
    animationName: AnimationMap.HalfTimeEnd,
    calculate(store, evt) {
        const { currentPeriod, matchDuration } = store.translationData.statistics;
        const { period } = evt;
        if (period) {
            const timer = getTimerTime(currentPeriod, matchDuration);
            store.translationData.statistics.timer = 60 * timer;
            store.translationData.statistics.isTimerPaused = true;
            store.translationData.statistics.hasPeriodEnded[period] = true;
            // window.$store.getters['countdown/needToShow'] && (t.halfTimeEndDate = p(e.regtime));
            // For future dont know, need it right now or not
            store.translationData.statistics.halfTimeEndDate = getLocalTime(evt.regtime);
        }
    },
});
