import { ModelMap } from 'features/badminton/constants';
import { Event } from 'features/common/models/event.model';
import { qe, Ve } from './common';

export const ScoreWithAnimationEvent = new Event({
    type: ModelMap.ScoreWithAnimation,
    // renames: ["period", "score1", "score2"],
    // animationName: AnimationMap.Score,
    calculate(store, eventData) {
        Ve(store, eventData);
    },
    isPreventProcessing(event, store) {
        const { scoreChanges } = store.translationData.statistics;
        const { score1, score2, period } = event;
        return !!scoreChanges.find((e) => e.score1 === score1 && e.score2 === score2 && e.period === period);
    },
    pushAdditionalEvents(event, store) {
        const { regtime, id, score1, score2, period } = event;
        const { currentTeam, servePosition } = store.translationData.statistics.servesData;
        const c = [];
        const d = { 1: score1, 2: score2 };
        d[currentTeam] -= 1;
        c.push({
            type: ModelMap.Point,
            regtime,
            id,
            team: currentTeam,
            score: d,
        });
        // eslint-disable-next-line no-unused-expressions
        qe(score1, score2) ||
            c.push({
                type: ModelMap.Serve,
                regtime,
                id,
                team: currentTeam,
                serveCorner: servePosition,
                period,
            });
        return c;
    },
});
