import { useContext } from 'react';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useTeamNumbers = () => {
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    return [homeTeam, awayTeam];
};
