import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useTeamTimeoutStatistic = (subtextKey = 'statistics.timeouts') => {
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;
    const { timeouts } = statistics;

    const homeStat = timeouts[homeTeam] || 0;
    const awayStat = timeouts[awayTeam] || 0;

    return {
        label: translate(subtextKey),
        homeStat,
        awayStat,
    };
};
