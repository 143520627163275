import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTargetName } from 'hooks';

function WithRefFieldTextLeft(props, ref) {
    const { homeStat, awayStat, homeTeam, isPercent, title, subText, isShowStatistic } = props;

    const { classMap: targetPrefix } = useTargetName((targets) => ({
        [targets.MOSTBET]: '_mostbet',
        [targets.BETANDREAS]: '_betandreas',
        [targets.VIVI]: '_vivi',
    }));

    return (
        <div className="bb-field_text-wrapper">
            <div className={cx('bb-field_text _left')} ref={ref}>
                <div className="bb-field_text-info">
                    <p className="bb-field_text-action break-words">{title}</p>
                    <p className={cx('bb-field_text-team', '_left', targetPrefix)}>{homeTeam}</p>
                </div>
                {isShowStatistic && (
                    <div className="bb-field_text-stats _left">
                        <div className="flex justify-end">
                            <p>
                                <span>{`${subText} `}</span>
                                <span>{homeStat}</span>
                                {isPercent && <span>%</span>}
                            </p>
                            <span className="divider" />
                            <p className="bb-field_text-away">
                                <span>{awayStat} </span>
                                {isPercent && <span>%</span>}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
const FieldTextLeft = React.forwardRef(WithRefFieldTextLeft);

WithRefFieldTextLeft.propTypes = {
    homeStat: PropTypes.number.isRequired,
    awayStat: PropTypes.number.isRequired,
    homeTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    isPercent: PropTypes.bool,
    isShowStatistic: PropTypes.bool.isRequired,
};
WithRefFieldTextLeft.defaultProps = {
    isPercent: false,
};

function WithRefFieldTextRight(props, ref) {
    const { homeStat, awayStat, awayTeam, isPercent, title, subText, isShowStatistic } = props;

    const { classMap: targetPrefix } = useTargetName((targets) => ({
        [targets.MOSTBET]: '_mostbet',
        [targets.BETANDREAS]: '_betandreas',
        [targets.VIVI]: '_vivi',
    }));

    return (
        <div className="bb-field_text-wrapper _right">
            <div className={cx('bb-field_text _right', targetPrefix)} ref={ref}>
                <div className="bb-field_text-info">
                    <p className="bb-field_text-action break-words">{title}</p>
                    <p className={cx('bb-field_text-team _right', targetPrefix)}>{awayTeam}</p>
                </div>
                {isShowStatistic && (
                    <div className="bb-field_text-stats _right">
                        <div className="flex">
                            <p className="bb-field_text-away">
                                <span>{homeStat}</span>
                                {isPercent && <span>%</span>}
                            </p>
                            <span className="divider" />
                            <p>
                                <span>{`${subText} `}</span>
                                <span>{awayStat}</span>
                                {isPercent && <span>%</span>}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
const FieldTextRight = React.forwardRef(WithRefFieldTextRight);

WithRefFieldTextRight.propTypes = {
    homeStat: PropTypes.number.isRequired,
    awayStat: PropTypes.number.isRequired,
    awayTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    isPercent: PropTypes.bool,
    isShowStatistic: PropTypes.bool.isRequired,
};
WithRefFieldTextRight.defaultProps = {
    isPercent: false,
};

function WithRefFieldText(props, ref) {
    const { team, ...rest } = props;
    const { conditions } = useTargetName();

    return (
        <>
            {team === 1 ? (
                <FieldTextLeft {...rest} isShowStatistic={conditions.isMostbet} ref={ref} />
            ) : (
                <FieldTextRight {...rest} isShowStatistic={conditions.isMostbet} ref={ref} />
            )}
        </>
    );
}
export const FieldText = React.forwardRef(WithRefFieldText);

WithRefFieldText.propTypes = {
    team: PropTypes.number.isRequired,
};
