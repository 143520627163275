import { AnimationMap, ModelMap } from 'features/basketball3x3/constants';
import { Event } from 'features/common/models/event.model';

export function getLastItemIndex(arr, cb) {
    for (let i = arr.length - 1; i > -1; i -= 1) {
        if (cb(arr[i])) {
            return i;
        }
    }
    return -1;
}

export function getCurrentFreeThrows(statistics) {
    const { currentFreeThrows } = statistics;
    const areFreeThrowsPresent = !!currentFreeThrows.length;

    // eslint-disable-next-line no-return-assign
    return (
        areFreeThrowsPresent &&
        (!!currentFreeThrows.find((thrw) => !thrw.done) ||
            // eslint-disable-next-line no-param-reassign
            ((statistics.currentFreeThrows = []), false))
    );
}

export function getFreeThrowStatus(statistics, filterString) {
    // eslint-disable-next-line no-param-reassign
    return (
        !!getCurrentFreeThrows(statistics) &&
        (filterString &&
            ((t, e) => {
                const { currentFreeThrows: a } = t;
                if (e) {
                    const i = a.find((k) => !k.done);
                    // eslint-disable-next-line no-unused-expressions
                    i && ((i.status = e), (i.done = true));
                }
            })(statistics, filterString),
        true)
    );
}

function ScoreChangeFactory(props) {
    const { type, animationName, redirect, info } = props;
    const emptyInfoObj = {};
    if (info) {
        emptyInfoObj.info = info;
    }
    return new Event({
        type,
        animationName,
        redirect,
        // renames: ['team', 'score', 'time', 'period', 'code'],
        calculate(store, evtData) {
            const { statistics, log } = store.translationData;
            const { score, code, team, time, period, id } = evtData;
            const { scoreChanges, freeThrowsRealizations, ballPossession } = statistics;
            const currTeamScoreChanges = scoreChanges[team];
            if (!info) {
                // eslint-disable-next-line no-param-reassign
                evtData.info = code >= 0 ? `+${score}` : `-${score}`;
            }
            if (code >= 0) {
                statistics.score[team] += score;
                if (score === 1 && type === ModelMap.FreeThrowGoal) {
                    freeThrowsRealizations[team].push({
                        period,
                    });
                }
                currTeamScoreChanges.push({
                    time,
                    period,
                    score,
                    code,
                    id,
                });
                // eslint-disable-next-line no-inner-declarations
                function getBallPossession(tm) {
                    const teamsBP = ballPossession[tm];
                    return teamsBP[teamsBP.length - 1];
                }
                const BP = getBallPossession(evtData.team);
                if (!BP) {
                    return;
                }
                const BP2 = getBallPossession(evtData.team === 1 ? 2 : 1);
                // eslint-disable-next-line no-unused-expressions
                (!BP2 || BP.forwardTime > BP2.forwardTime) && (BP.scored = true);
            } else {
                statistics.score[team] -= score;
                const idx = getLastItemIndex(currTeamScoreChanges, (t) => t.score === score);
                if (idx >= 0) {
                    const logIdx = log.findIndex((lg) => lg.id === currTeamScoreChanges[idx].id);
                    log.splice(logIdx, 1);
                    currTeamScoreChanges.splice(idx, 1);
                }
            }
        },
        /* pushToLog gets log, eventData and store */
        pushToLog(log, evtData, store) {
            const { homeTeam, awayTeam } = store;
            const { statistics } = store.translationData;
            const { score } = statistics;
            // eslint-disable-next-line no-unused-expressions
            evtData.code < 0 ||
                log.push({
                    time: evtData.time,
                    type: 'scoreChange',
                    component: 'DefaultLogEvent',
                    period: evtData.period,
                    // svg: evtData.code >= 0 ? 'BbScore' : 'BbScore',
                    // text: `+${evtData.score} (${score[homeTeam]}:${score[awayTeam]})`,
                    svg: 'BbScoreIcon',
                    text: `+${evtData.score}`,
                    team: evtData.team,
                    id: evtData.id,
                    timeText: `${score[homeTeam]}:${score[awayTeam]}`,
                });
        },
    });
}

export const ScoreChangeEvent = ScoreChangeFactory({
    type: ModelMap.ScoreChange,
    animationName: AnimationMap.ScoreChange,
    // renames: ['team', 'score', 'time', 'period', 'code'],
    redirect(evtData, statistics, store) {
        const { log } = store.translationData;
        const lastFreeThrowIndex = getLastItemIndex(log, (evt) => evt.type === 'freeThrow');
        if (lastFreeThrowIndex < 0 || evtData.team !== log[lastFreeThrowIndex].team) {
            return false;
        }
        const freeThrowStatus = getFreeThrowStatus(statistics, 'scored');
        return (
            !(evtData.code < 0 || !freeThrowStatus) && {
                ...evtData,
                type: ModelMap.FreeThrowGoal,
            }
        );
    },
});

export const FreeThrowGoalEvent = ScoreChangeFactory({
    type: ModelMap.FreeThrowGoal,
    animationName: AnimationMap.FreeThrowGoal,
    info: 'action.freeThrowScored.animation',
});
