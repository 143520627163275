/**
 * @description -  Расчет процента статистики
 * @param {Number}homeStat - Статы домашних
 * @param {Number}awayStat - Статы гостей
 * @returns {{homePercent: (number|number), awayPercent: (number|number)}}
 */
export const calculatePercentsForTeams = ({ homeStat, awayStat }) => {
    const totalStat = homeStat + awayStat;
    const isNumber = totalStat && Number.isFinite(totalStat);
    const homePercent = isNumber ? Math.ceil((homeStat / totalStat) * 100) : 0;
    const awayPercent = isNumber ? 100 - homePercent : 0;

    return {
        homePercent,
        awayPercent,
    };
};
