import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export function MatchEndError({ translate, sport }) {
    return (
        <div className={cn(sport, 'error')}>
            <p className="error__text">{translate('error.matchEnded')}</p>
        </div>
    );
}
MatchEndError.propTypes = {
    translate: PropTypes.func.isRequired,
    sport: PropTypes.string.isRequired,
};
