import { BallEnterEvent } from './ballEnter.model';
import { BallPossessionEvent } from './ballPossession.model';
import { EmptyGateEvent } from './emptyGate.model';
import { FreeThrowEvent } from './freeThrow.model';
import { GoalEvent } from './goal.model';
import { GoalkeeperHasBallEvent } from './goalkeeperHasBall.model';
import { MatchCountdownEvent } from './matchCountdown.model';
import { MatchEndEvent } from './matchEnd.model';
import { OvertimeStartEvent } from './overtimeStart.model';
import { PenaltyGoalEvent } from './penaltyGoal.model';
import { PenaltyMissEvent } from './penaltyMiss.model';
import { PenaltyShotoutEvent } from './penaltyShotout.model';
import { PenaltyThrowEvent } from './penaltyThrow.model';
import { PeriodCountdownEvent } from './periodCountdown.model';
import { PeriodEndEvent } from './periodEnd.model';
import { PeriodStartEvent } from './periodStart.model';
import { PeriodStartWithoutAnimationEvent } from './periodStartWithoutAnimation.model';
import { PlayersOnFieldEvent } from './playersOnField.model';
import { RedCardEvent } from './redCard.model';
import { RemovalEvent } from './removal.model';
import { SetScoreEvent } from './setScore.model';
import { SevenMeterThrowEvent } from './sevenMeterThrow.model';
import { SevenMeterThrowCancelledEvent } from './sevenMeterThrowCancelled.model';
import { SevenMeterThrowMissedEvent } from './sevenMeterThrowMissed.model';
import { TeamTimeoutEvent } from './teamTimeout.model';
import { ThrowOnGatesEvent } from './throwOnGates.model';
import { ThrowOnGatesMissEvent } from './throwOnGatesMiss.model';
import { TimeoutEvent } from './timeout.model';
import { TimeoutOrTeamTimeoutEvent } from './timeoutOrTeamTimeout.model';
import { TimerPausedEvent } from './timerPaused.model';
import { TimerUnpausedEvent } from './timerUnpaused.model';
import { YellowCardEvent } from './yellowCard.model';

export function EventFactory() {
    return [
        BallEnterEvent,
        BallPossessionEvent,
        EmptyGateEvent,
        FreeThrowEvent,
        GoalEvent,
        GoalkeeperHasBallEvent,
        MatchCountdownEvent,
        MatchEndEvent,
        OvertimeStartEvent,
        PenaltyGoalEvent,
        PenaltyMissEvent,
        PenaltyShotoutEvent,
        PenaltyThrowEvent,
        PeriodCountdownEvent,
        PeriodEndEvent,
        PeriodStartEvent,
        PeriodStartWithoutAnimationEvent,
        PlayersOnFieldEvent,
        RedCardEvent,
        RemovalEvent,
        SetScoreEvent,
        SevenMeterThrowEvent,
        SevenMeterThrowCancelledEvent,
        SevenMeterThrowMissedEvent,
        TeamTimeoutEvent,
        ThrowOnGatesEvent,
        ThrowOnGatesMissEvent,
        TimeoutEvent,
        TimeoutOrTeamTimeoutEvent,
        TimerPausedEvent,
        TimerUnpausedEvent,
        YellowCardEvent,
    ];
}
