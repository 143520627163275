import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const InjuryEvent = new Event({
    type: ModelMap.Injury,
    // renames: ['time', 'period', 'team'],
    animationName: AnimationMap.Injury,
    calculate(store, { time, period, team }) {
        store.translationData.statistics.injuries[team].push({
            time,
            period,
        });
    },
    isPreventProcessing: (event) => !event.team,
});
