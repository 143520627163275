export const statistics = () => ({
    scoreChanges: [],
    colors: {
        1: {
            mainColor: '#43A271',
            secondColor: '#43A271',
        },
        2: {
            mainColor: '#CF0ADD',
            secondColor: '#CF0ADD',
        },
    },
    score: {
        1: 0,
        2: 0,
    },
    servesData: {
        currentTeam: 1,
        currentNumber: 1,
        currentCorner: 'right',
        serversInPairs: {
            1: 0,
            2: 0,
        },
    },
    sets: [
        {
            number: 1,
            score: {
                1: 0,
                2: 0,
            },
            games: [
                {
                    number: 1,
                    score: {
                        1: 0,
                        2: 0,
                    },
                },
            ],
            totalServeHits: {
                1: 0,
                2: 0,
            },
            secondServeHits: {
                1: 0,
                2: 0,
            },
        },
    ],
    aces: {
        1: [],
        2: [],
    },
    doubleFouls: {
        1: [],
        2: [],
    },
    gameballs: {
        1: [],
        2: [],
    },
    gameballsRealizations: {
        1: [],
        2: [],
    },
    serves: {
        1: [],
        2: [],
    },
    servesOrder: {
        1: [],
        2: [],
    },
    scores: {
        1: [],
        2: [],
    },
});
