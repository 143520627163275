import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const FieldStatus = React.forwardRef((props, ref) => {
    const { team, children } = props;
    const isHome = team === 1;
    const classes = cx('field_status', {
        _left: isHome,
        _right: !isHome,
    });

    return (
        <div className={classes} ref={ref}>
            <div className="text-white text-center abs-center">{children}</div>
        </div>
    );
});

FieldStatus.displayName = 'FieldStatus';
FieldStatus.propTypes = {
    team: PropTypes.number.isRequired,
};
