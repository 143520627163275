import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/volleyball/constants';
import { Event } from './event.model';

export const TimeoutEvent = new Event({
    type: ModelMap.Timeout,
    animationName: AnimationMap.Timeout,
    // renames: ['team'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        // eslint-disable-next-line no-unused-expressions
        evtData.team &&
            statistics.timeouts[evtData.team].push({
                period: statistics.currentPeriod,
            });
    },
    pushToLog(log, evtData, store) {
        const { statistics } = store.translationData;
        log.push({
            component: 'GeneralLogEvent',
            text: i18n.t('log.timeout'),
            translucent: true,
            period: statistics.currentPeriod,
            type: 'timeout',
            id: evtData.id,
        });
    },
});
