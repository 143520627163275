import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export function AppError({ isInline }) {
    const [translate] = useTranslation('common');
    const handleRefresh = () => {
        window.location.reload(false);
    };
    const errorText = isInline ? translate('error.appInlineError') : translate('error.appError');

    return (
        <div
            className="error-block
            font-medium flex-auto flex flex-col text-center
            justify-center items-center divide-mb-5"
        >
            <p className="font-medium text-2xl text-white">{errorText}</p>
            <button
                type="button"
                className="error-button text-white px-3 py-3 rounded-6 text-base"
                onClick={handleRefresh}
            >
                {translate('base.restart')}
            </button>
        </div>
    );
}

AppError.propTypes = {
    isInline: PropTypes.bool,
};
AppError.defaultProps = {
    isInline: false,
};
