import React from 'react';
import PropTypes from 'prop-types';

import { useComponentRendered, useErrorConfig } from 'hooks';
import { DisabledError, MatchEndError, NetworkError, ServerError } from '../../atoms';

export function ErrorPageHandler({ sport }) {
    const { error, translate, isDisabled, isFailed, handleRefresh } = useErrorConfig();

    useComponentRendered();

    function renderError(err) {
        if (err?.message.indexOf('disabledMatchError') > -1) {
            return <DisabledError translate={translate} sport={sport} />;
        }
        if (err?.message.indexOf('matchEnd') > -1) {
            return <MatchEndError translate={translate} sport={sport} />;
        }
        if (err?.message.indexOf('Network Error') > -1) {
            return <NetworkError translate={translate} onRefresh={handleRefresh} sport={sport} />;
        }
        if (err?.message.indexOf('serverError') > -1) {
            return <ServerError translate={translate} onRefresh={handleRefresh} sport={sport} />;
        }
        return <ServerError translate={translate} onRefresh={handleRefresh} sport={sport} />;
    }

    return isFailed || isDisabled ? (
        <div className="main-container main-container-football">
            <div className="main-container__content">{renderError(error)};</div>
        </div>
    ) : null;
}

ErrorPageHandler.propTypes = {
    sport: PropTypes.string.isRequired,
};
