import { statistics as statisticsInitStat } from './statistics';

export const state = {
    store: {
        events: [],
        lastEventId: 0,
        homeTeam: 1,
        awayTeam: 2,
        currentAnimation: {
            eventData: {},
            componentName: '',
        },
        translationData: {
            info: {},
            lastAction: {},
            log: [],
            statistics: statisticsInitStat(),
        },
    },
};
