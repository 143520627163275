export const ModelMap = {
    BallPossession: 1113,
    PlayersWarmUp: 1031,
    BallPass: 2608,
    Scrum: 2600,
    ScrumWins: 2601,
    Try: 2603,
    PenaltyTry: 2604,
    PenaltyKick: 1116,
    FreeKick: 2650,
    TwentyTwoMetreDropOut: 2616,
    ConversionPosition: 2630,
    LineBreak: 2631,
    Conversion: 2605,
    Score: 1213,
    Substitution: 1111,
    TwentyTwoMetreReplay: 2627,
    Out: 2615,
    TimerEnabled: 1503,
    TimerDisabled: 1504,
    PeriodEnd: 1102,
    PlayersOnField: 1206,
    PenaltyKickScored: 2606,
    MatchEnd: 1103,
    PeriodStarted: 1118,
    RedCard: 1109,
    YellowCard: 1108,
    LineOut: 2602,
    DropGoal: 2607,
    BallPosition: 2681,
    SubtypeFive: 5,
    SubtypeTwo: 2,
};
