import { useTranslation } from 'react-i18next';

import { useTargetName } from 'hooks';
import { getPeriodText } from 'features/basketball/models/periodStart.model';

export const usePeriods = ({ currentPeriod, quarters, periodsCount }) => {
    const [translate] = useTranslation();
    const { conditions } = useTargetName();

    if (periodsCount === 1 && currentPeriod <= 1) {
        return [];
    }

    const periods = [
        {
            label: translate('period.wholeMatch'),
            value: 0,
        },
    ];

    Array.from({ length: Math.max(currentPeriod, periodsCount) }).forEach((_, index) => {
        periods.push(
            ((idx) => ({
                value: idx,
                label: getPeriodText({ period: idx, quarters, isShort: conditions.isBetandreas }),
                disabled: currentPeriod < idx,
            }))(index + 1),
        );
    });
    return periods;
};
