import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';

export const PenaltyOffTargetEvent = new Event({
    type: ModelMap.PenaltyOffTarget,
    animationName: AnimationMap.PenaltyOffTarget,
    renames: ['team', 'period', 'time'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        if (statistics.isPenaltyStarted) {
            statistics.penaltyShootout[evtData.team].push({
                time: evtData.time,
                type: 'miss',
                period: evtData.period,
            });
        }
    },
});
