import { useContext, useEffect, useState } from 'react';

import { getValueByParametrCssVariable, getWindowProportions } from 'lib';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { FreezedEmitter } from 'features/common/services/emitter.service';
import { useTargetName } from './useTargetName';

export const useFieldAnimatorConfig = ({ isMobile, height }) => {
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { conditions } = useTargetName();

    const [animationData, setAnimationData] = useState({
        eventData: store.currentAnimation.eventData,
        currentAnimation: store.currentAnimation.componentName,
    });
    const [calculatedWidth, setCalculatedWidth] = useState(null);

    useEffect(() => {
        FreezedEmitter.on('newAnimation', (data) => {
            const { eventData: evtData, componentName } = data;
            setAnimationData({
                eventData: evtData,
                currentAnimation: componentName,
            });
        });
        return () => {
            FreezedEmitter.emit('resetVirgin');
            FreezedEmitter.off('newAnimation');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const verticalOffset = getValueByParametrCssVariable('--field-vertical-padding');
        const newCalculatedWidth = (height - 2 * verticalOffset) / getWindowProportions.heightRatio();

        setCalculatedWidth(newCalculatedWidth);
    }, [conditions.isVivi, height, setCalculatedWidth]);

    function getMaxWidth() {
        const mobilePadding = getValueByParametrCssVariable('--field-horizontal-padding--mobile');

        if (conditions.isVivi) {
            return window.innerWidth - 2 * getValueByParametrCssVariable('--field-horizontal-padding');
        }

        const horizontalOffset = isMobile ? mobilePadding : getValueByParametrCssVariable('--field-horizontal-padding');

        return window.innerWidth - 2 * horizontalOffset;
    }

    function fieldWidth() {
        const maxWidth = getMaxWidth();

        return calculatedWidth ? Math.min(maxWidth, calculatedWidth) : null;
    }

    return {
        store,
        animationData,
        setAnimationData,
        calculatedWidth,
        setCalculatedWidth,
        fieldWidth,
    };
};
