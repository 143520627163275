import { ModelMap } from 'features/beachVolleyball/constants';
import { Event } from './event.model';

export const ServeAndServingTeamEvent = new Event({
    type: [ModelMap.ServePlus, ModelMap.ServingTeam],
    // renames: ['team'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        // eslint-disable-next-line no-unused-expressions
        statistics.periods[0].firstServingTeam = evtData.team;
        statistics.servingTeam = evtData.team;
    },
    isPreventProcessing(_, store) {
        const { statistics } = store.translationData;
        return statistics.periods[0].firstServingTeam !== undefined;
    },
});
