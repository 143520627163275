import React from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationMap } from 'features/rugby/constants';
import { IconText } from './common/IconText';

export function PlayersWarmupAnimation() {
    const [translate] = useTranslation();
    return <IconText icon="playersWarUp" text={translate('action.playersWarmUp.animation')} />;
}

PlayersWarmupAnimation.ANIMATION_NAME = AnimationMap.PlayersWarmUp;
