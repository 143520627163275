import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { getBallPossessionStats } from 'features/basketball3x3/utils';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { calculatePercentsForTeams } from 'features/common/utils';

export const useBallPossessionStatistic = (subtextKey = 'statistics.possession') => {
    const [translate] = useTranslation();

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;
    const { ballPossession } = statistics;

    const homeStat = getBallPossessionStats(ballPossession[homeTeam]);
    const awayStat = getBallPossessionStats(ballPossession[awayTeam]);

    let homePercent = 50;
    let awayPercent = 50;

    const totalStat = homeStat + awayStat;

    if (totalStat !== 0) {
        ({ awayPercent, homePercent } = calculatePercentsForTeams({ homeStat, awayStat }));
    }

    return {
        homeStat: homePercent,
        awayStat: awayPercent,
        label: translate(subtextKey),
    };
};
