import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTargetName } from 'hooks';
import { Scroll } from 'ui';

export function TimeTabs(props) {
    const { handleTabClick, activeTab, periods, activeBgCls, activeTextCls, baseBgCls, baseTextCls } = props;

    const { conditions } = useTargetName();

    function classes({ isActive, isDisabled }) {
        return cx('time-tabs__item', {
            'time-tabs__item--active': isActive,
            'time-tabs__item--disabled': isDisabled,
            [activeBgCls]: isActive,
            [activeTextCls]: isActive,
            [baseTextCls]: !isActive,
        });
    }

    return (
        <div id="event-tabs" className={cx(`${baseBgCls} time-tabs`)}>
            <Scroll
                classNames="flex"
                isShowShadow={conditions.isVivi || conditions.isMostbet}
                isDraggable={conditions.isVivi || conditions.isMostbet}
                isHorizontal
            >
                {periods.map((period, index) => {
                    const isActive = activeTab === period.value;
                    return (
                        <div
                            key={period.value}
                            className={classes({ isActive, isDisabled: period.disabled })}
                            onClick={() =>
                                handleTabClick(period.value, {
                                    text: period.label,
                                    cls: classes({ isActive, isDisabled: period.disabled }),
                                    count: periods.length,
                                    position: index + 1,
                                })
                            }
                        >
                            <p className="time-tabs__label">{period.label}</p>
                        </div>
                    );
                })}
            </Scroll>
        </div>
    );
}

TimeTabs.defaultProps = {
    baseTextCls: 'text-white',
};

TimeTabs.propTypes = {
    baseBgCls: PropTypes.string.isRequired,
    baseTextCls: PropTypes.string,
    activeTextCls: PropTypes.string.isRequired,
    activeBgCls: PropTypes.string.isRequired,
    handleTabClick: PropTypes.func.isRequired,
    activeTab: PropTypes.number.isRequired,
    periods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

TimeTabs.tabs = {
    FULL_MATCH: 'FULL_MATCH',
    FIRST_HALF: 'FIRST_HALF',
    SECOND_HALF: 'SECOND_HALF',
    FIRST_OT: 'FIRST_OT',
    SECOND_OT: 'SECOND_OT',
};
