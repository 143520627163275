/* eslint-disable  */
import i18n from 'i18n';

export const filterBySet = (statistic, set, propName = 'set') => {
    if (set === 0) return statistic;
    return statistic.filter((stat) => stat[propName] === set);
};

export const getActualSets = (statistics) => {
    const sets = [...statistics.sets];
    let lastSetNumber = sets.length - 1;
    const lastSet = sets[lastSetNumber];
    const { games } = lastSet;
    // eslint-disable-next-line no-unused-vars
    const lastGame = games[games.length - 1];
    if (games.length === 1) {
        const { score } = lastGame;
        if (score[1] + score[2] === 0) sets.splice(lastSetNumber, 1);
    }
    return sets;
};

const getPeriodTranslationKey = (number, hasTbLastSet, setsCount) =>
    hasTbLastSet && number === setsCount ? 'time.tieBreak' : 'time.set';

export const getPeriods = (statistics, hasTbLastSet) => {
    const actualSets = getActualSets(statistics);

    const periods = [
        {
            label: i18n.t('time.wholeMatch'),
            value: 0,
        },
    ];
    const setsByNumbers = actualSets.map((set) => ({
        value: set.number,
        label: i18n.t('time.set', { set: set.number }),
    }));

    return [...periods, ...setsByNumbers];
};

const getScoreTranslation = (scoreType) => {
    let scoreTranslation = i18n.t('log.score');
    if (scoreType === 1) scoreTranslation += ` (${i18n.t('log.ace')})`;
    if (scoreType === 2) scoreTranslation += ` (${i18n.t('log.doubleFoul')})`;
    return scoreTranslation;
};

export const getServeTeam = (statistics) => {
    const { servesData } = statistics;
    return servesData.currentTeam;
};

export const getOpposingTeam = (team) => (team === 1 ? 2 : 1);

export const getGameScore = (eventData) => ({
    1: eventData.gameScore1,
    2: eventData.gameScore2,
});

export const getIsWimbledon = (store) => {
    const { is12PointTiebreak: isWimbledon } = store;
    return isWimbledon;
};

export const getCornerSide = (statistics) => {
    const { servesData } = statistics;
    servesData.currentCorner = servesData.currentCorner === 'right' ? 'left' : 'right';
};
export const getIsPair = (store) => store.teamsType === 2;

export const setPairServer = (store, currentTeam) => {
    const { serversInPairs } = store.translationData.statistics.servesData;
    const currentServerInTeam = serversInPairs[currentTeam];
    getIsPair(store) && currentServerInTeam !== 0 && (serversInPairs[currentTeam] = currentServerInTeam === 1 ? 2 : 1);
};

export const setCornerSide = (store) => {
    const { servesData } = store.translationData.statistics;
    setPairServer(store, servesData.currentTeam),
        (servesData.currentTeam = getOpposingTeam(servesData.currentTeam)),
        (servesData.currentCorner = 'right');
};

export const checkGatewayCode = (code, store) => store.translationData.info.gateway_code === code;

export const getTotalSets = (store) => store.totalSets;

export const hasTieBreakLastSet = (store) => {
    const { tieBreakLastSetType, tieBreakLastSet, is12PointTiebreak } = store;

    // eslint-disable-next-line max-len,no-mixed-operators
    return (
        !is12PointTiebreak &&
        (tieBreakLastSetType === 7 || tieBreakLastSetType === 10 || (tieBreakLastSetType !== 0 && tieBreakLastSet))
    );
};

export const getIsNextGenATP = (store) => store.translationData.info.tournament === 'Next Gen ATP Finals';

export const checkIsTieBreak = (eventData, store) => {
    const { is12PointTiebreak: isWimbledon, totalSets } = store;
    if (isWimbledon) return false;
    return hasTieBreakLastSet(store) && eventData.set === totalSets;
};

// export const getTieBreakType = (eventData, store) => checkIsTieBreak(eventData, store) ? "superTieBreak" : q(eventData, store) ? "common" : null

export const getTieBreakType = (eventData, store) => {
    if (checkIsTieBreak(eventData, store)) return 'superTieBreak';
    if (q(eventData, store)) return 'common';
    return null;
};

export const checkIsLastSet = (set, store) => set === store.totalSets;

export var q = (eventData, store) => {
    const { team, game } = eventData;
    const opposingTeam = getOpposingTeam(team);
    const setScores = {
        1: eventData.setScore1,
        2: eventData.setScore2,
    };
    const teamSetScore = setScores[team];
    const opposingTeamSetScore = setScores[opposingTeam];
    const { is12PointTiebreak: isWimbledon, totalSets } = store;
    const isNextGetATP = getIsNextGenATP(store);

    if (isWimbledon && eventData.set === totalSets) return false;
    let l = 6;
    isNextGetATP && (l = 3);
    const c = 2 * l + 1;
    return (
        game >=
            (teamSetScore === l + 1 || (teamSetScore >= l && teamSetScore - opposingTeamSetScore >= 2) ? c + 1 : c) ||
        (teamSetScore + opposingTeamSetScore === 2 * l + 1 && game === 1)
    );
};

const addNewSet = (set, sets) => {
    const a = {
        number: set,
        games: [
            {
                number: 1,
                score: {
                    1: 0,
                    2: 0,
                },
            },
        ],
        score: {
            1: 0,
            2: 0,
        },
        totalServeHits: {
            1: 0,
            2: 0,
        },
        secondServeHits: {
            1: 0,
            2: 0,
        },
    };
    sets.push(a);
    return a;
};
export const pt = (store, eventData) => {
    const { advantageScoring } = store;
    const { gameballs, servesData, sets } = store.translationData.statistics;
    const gameScore = getGameScore(eventData);
    if (gameScore[1] !== 0 || gameScore[2] !== 0) {
        if (gameScore[1] === gameScore[2] && gameScore[1] === 40) {
            const { games } = sets[sets.length - 1];
            games[games.length - 1].balance = true;
        }
        [1, 2].forEach((teamNumber) => {
            const reversedTeam = getOpposingTeam(teamNumber);
            const teamGameScore = gameScore[teamNumber];
            const isAdvantage = teamGameScore >= 40 && teamGameScore > gameScore[reversedTeam];
            const ifIs40 = teamGameScore === 40;
            if (advantageScoring ? isAdvantage : ifIs40) {
                store.translationData.statistics.gameballTeam = teamNumber;
                gameballs[teamNumber].push({
                    set: eventData.set,
                    isBreakpoint: teamNumber !== servesData.currentTeam,
                });
            }
            // original
            // (advantageScoring ? teamGameScore >= 40 && teamGameScore > gameScore[reversedTeam] : teamGameScore === 40) &&
            //   ((store.gameballTeam = teamNumber),
            //   gameballs[teamNumber].push({
            //     set: eventData.set,
            //     isBreakpoint: teamNumber !== servesData.currentTeam,
            //   }));
        });
    }
};

const mt = (currentSet, eventData, store) => {
    const { game, team } = eventData;
    const { games } = currentSet;
    const gameScore = getGameScore(eventData);
    const currentGame = games.find((gm) => gm.number === game);
    const tieBreakType = getTieBreakType(eventData, store);
    const l = (t) => t.score[team]++;

    if (!currentGame) {
        return eventData.isSetEnd
            ? void (tieBreakType && l(games[games.length - 1]))
            : ((eventData.isNewGame = true),
              void games.push({
                  number: game,
                  score: gameScore,
              }));
    }
    checkGatewayCode(62, store) && game === 1 && games.length > 1
        ? tieBreakType && l(games[games.length - 1])
        : tieBreakType === 'superTieBreak' && gameScore[team] === 0
          ? l(currentGame)
          : (currentGame.score = gameScore);
};

export const dt = (store, eventData) => {
    const { sets, servesData } = store.translationData.statistics;
    const { team } = eventData;
    const gameScores = {
        1: eventData.setScore1,
        2: eventData.setScore2,
    };
    const currentSet = sets.find((set) => set.number === eventData.set) || addNewSet(eventData.set, sets);
    const { score, number } = currentSet.games[currentSet.games.length - 1];
    const { log } = store.translationData;
    number === 1 && score[1] + score[2] === 0 && (eventData.isSetStart = true), (currentSet.score = gameScores);
    if (score[1] + score[2] === 0) {
        log.push({
            component: 'PeriodLogEvent',
            period: number,
            periodText: i18n.t('time.set', { set: number }),
            id: `${eventData.id}|${log.length}`,
            type: 'setStart',
            svg: 'log/setStart',
            time: 0,
            set: eventData.set,
        });
    }
    const { currentTeam } = servesData;
    currentSet.totalServeHits[currentTeam]++;
    if (servesData.currentNumber === 2) currentSet.secondServeHits[currentTeam]++;
    const opponentTeam = getOpposingTeam(team);
    const teamSetScore = currentSet.score[team];
    const isLastSet = checkIsLastSet(currentSet.number, store);
    const isWimbledon = getIsWimbledon(store);
    const isNextGenATP = getIsNextGenATP(store);

    if (
        (((!(isWimbledon && isLastSet) && teamSetScore === 7) ||
            (teamSetScore >= 6 && teamSetScore - currentSet.score[opponentTeam] >= 2)) &&
            (eventData.isSetEnd = true),
        isNextGenATP && teamSetScore === 4 && (eventData.isSetEnd = true),
        checkIsTieBreak(eventData, store) && currentSet.score[team] === 1 && (eventData.isSetEnd = true),
        eventData.isSetEnd && !isLastSet)
    ) {
        const v = getTotalSets(store);
        const p = Math.ceil(v / 2);
        eventData.score1 < p && eventData.score2 < p && addNewSet(currentSet.number + 1, sets);
    }
    mt(currentSet, eventData, store);
};

const getTotalGamescore = (store) => {
    const { scoreChanges } = store.translationData.statistics;
    if (scoreChanges.length < 2) return '0 : 0';
    const a = getGameScore(scoreChanges[scoreChanges.length - 2]);
    const s = (t) => (a[t] === 50 ? 'Ad' : a[t]);

    return `${s(store.homeTeam)} : ${s(store.awayTeam)}`;
};

export const bt = (eventData, store, isTieBreak) => {
    const { set, team, isNewGame, isSetEnd, id, game } = eventData;
    const { homeTeam, awayTeam } = store;
    const { servesData, sets, scoreChanges, servesOrder } = store.translationData.statistics;
    const { log } = store.translationData;
    const totalGameScore = getTotalGamescore(store);

    const pushToLog = (textKey, svg, type) =>
        log.push({
            component: 'DefaultLogEvent',
            period: set,
            timeText: totalGameScore,
            team: servesData.currentTeam,
            id: `${id}|${log.length}`,
            type,
            text: i18n.t(textKey),
            svg,
            time: 0,
            game,
            set,
        });
    pushToLog('log.serve', 'log/serve', 'serve'),
        servesData.currentNumber === 2 && pushToLog('log.secondServe', 'log/second-serve', 'secondServe');
    const { games } = sets.find((e) => e.number === eventData.set);
    const { score } = games[games.length - 1];
    if (isNewGame || isSetEnd) {
        const w = (() =>
            isTieBreak && !!getTieBreakType(scoreChanges[scoreChanges.length - 2], store) ? 'log.set' : 'log.game')();
        const b = `${i18n.t(w)} ${i18n.t(`team${team}`)}`;
        const getTimeText = (() => {
            if (!isSetEnd || !isTieBreak) return totalGameScore;
            if (checkIsLastSet(set, store)) return `${score[homeTeam]} - ${score[awayTeam]}`;
            const { games } = sets[sets.length - 2];
            const lastGameScore = games[games.length - 1].score;
            return `${lastGameScore[homeTeam]}:${lastGameScore[awayTeam]}`;
        })();
        const { score: gamesScore } = sets.find((t) => t.number === set);
        const getScoreWithAdvantage = (team) => (gamesScore[team] === 50 ? 'Ad' : gamesScore[team]);
        // modified, check source

        log.push({
            isTieBreak,
            component: 'DefaultLogEvent',
            period: set,
            timeText: getTimeText,
            firstTeamScore: getScoreWithAdvantage(homeTeam),
            secondTeamScore: getScoreWithAdvantage(awayTeam),
            team,
            id: `${eventData.id}|${log.length}`,
            type: 'teamGameEnd',
            text: b,
            svg: 'log/plus',
            time: 0,
            game: isSetEnd ? games.length : eventData.game - 1,
            set: eventData.set,
            wonTeam: team,
        });
    } else {
        const S = (t) => (score[t] === 50 ? 'Ad' : score[t]);
        log.push({
            time: 0,
            component: 'DefaultLogEvent',
            timeText: `${S(homeTeam)}:${S(awayTeam)}`,
            team,
            id: `${eventData.id}|${log.length}`,
            type: 'score',
            text: getScoreTranslation(eventData.scoreType),
            svg: 'log/score',
            period: set,
            game: eventData.game,
            set: eventData.set,
        });
    }
};

export const gt = (store, set, game) => {
    const { gameballTeam, servesData } = store.translationData.statistics;
    gameballTeam &&
        (store.translationData.statistics.gameballsRealizations[gameballTeam].push({
            set,
            game: game - 1,
            isBreakpoint: gameballTeam !== servesData.currentTeam,
        }),
        (store.translationData.statistics.gameballTeam = void 0));
};
