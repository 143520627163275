import React from 'react';

import { AnimationMap } from 'features/basketball3x3/constants';
import { IconText } from './common/IconText';

export function FightAnimation() {
    return <IconText icon={AnimationMap.Fight} text="action.fight.animation" />;
}

FightAnimation.ANIMATION_NAME = AnimationMap.Fight;
