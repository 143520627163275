import { assign } from 'xstate';

import { Animator } from 'features/common/services/animator.service';
import { EventProcessor } from 'features/floorball/services/processor.service';

const processTranslationInfo = (info) => {
    const periodsCount = +info.extra.periodsCount || 3;
    const overtimeDuration = +info.extra.overtimeDuration || 5;
    const periodDuration = +info.extra.periodDuration || 20;
    const matchDuration = periodDuration * periodsCount;

    const newInfoExtra = {
        periodsCount,
        overtimeDuration,
        periodDuration,
        matchDuration,
        ...info.extra,
    };
    return { ...info, extra: { ...newInfoExtra } };
};

export const idle = {
    invoke: {
        id: 'idle-invoke',
        src: 'translationData',
        onDone: {
            target: 'initialEvents',
            actions: [
                assign({
                    store: ({ context, event }) => {
                        const { teamsreverse } = event.output;
                        const homeTeam = teamsreverse ? 2 : 1;
                        const awayTeam = teamsreverse ? 1 : 2;
                        return {
                            ...context.store,
                            homeTeam,
                            awayTeam,
                            translationData: {
                                ...context.store.translationData,
                                info: processTranslationInfo(event.output),
                            },
                        };
                    },
                }),
                assign({
                    eventProcessor: ({ context }) => {
                        const animator = new Animator(context.store);
                        return new EventProcessor(context.store, animator);
                    },
                }),
            ],
        },
        onError: {
            target: 'failure',
            actions: assign({ error: ({ event }) => event.error }),
        },
    },
};
