import ballPossession from './ballPossession.json';
import fight from './fight.json';
import foul from './foul.json';
import freeThrow from './freeThrow.json';
import freeThrowCanceled from './freeThrowCanceled.json';
import freeThrowGoal from './freeThrowGoal.json';
import freeThrowNotScored from './freeThrowNotScored.json';
import playersOnField from './playersOnField.json';
import removal from './removal.json';
import timeout from './timeout.json';
import timerPaused from './timerPaused.json';

export const animationDataMap = {
    freeThrow,
    freeThrowCanceled,
    freeThrowGoal,
    freeThrowNotScored,
    ballPossession,
    playersOnField,
    removal,
    timerPaused,
    fight,
    foul,
    timeout,
};
