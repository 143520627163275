import { assign } from 'xstate';

import { Animator } from 'features/common/services/animator.service';
import { EventProcessor } from 'features/futsal/services/processor.service';

const assignStore = () =>
    assign({
        store: ({ context, event }) => {
            const { extra, teamsreverse } = event.output;
            let duration;
            const homeTeam = teamsreverse ? 2 : 1;
            const awayTeam = teamsreverse ? 1 : 2;
            if (extra) {
                duration = extra.duration || context.store.translationData.statistics.matchDuration;
            }

            return {
                ...context.store,
                homeTeam,
                awayTeam,
                translationData: {
                    ...context.store.translationData,
                    info: event.output,
                    statistics: {
                        ...context.store.translationData.statistics,
                        matchDuration: duration,
                    },
                },
            };
        },
    });

const assignEventProcessor = () =>
    assign({
        eventProcessor: ({ context }) => {
            const animator = new Animator(context.store);
            return new EventProcessor(context.store, animator);
        },
    });

export const idle = {
    invoke: {
        id: 'idle-invoke',
        src: 'translationData',
        onDone: {
            target: 'initialEvents',
            actions: [assignStore(), assignEventProcessor()],
        },
        onError: {
            target: 'failure',
            actions: assign({ error: ({ event }) => event.error }),
        },
    },
};
