import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import debounce from 'lodash/debounce';

import { FreezedEmitter } from 'features/common/services/emitter.service';

function onAnimationComplete() {
    FreezedEmitter.emit('animationCompleted');
}
const debouncedAnimationComplete = debounce(onAnimationComplete, 100);

export function useCallbackTL(nodeRef, options = {}) {
    const { repeatDelay = 0.5 } = options;

    const callBackTl = useRef(
        gsap.timeline({
            repeat: -1,
            repeatDelay,
            yoyo: false,
            onRepeat: debouncedAnimationComplete,
        }),
    );

    useEffect(() => {
        callBackTl.current.fromTo(nodeRef.current, { opacity: 1 }, { opacity: 1, duration: 0.5 });

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            callBackTl.current.kill();
        };
    }, [nodeRef]);
}
