import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/iceHockey/constants';

export const FaceOffPointEvent = new Event({
    type: ModelMap.FaceOffPoint,
    animationName: AnimationMap.FaceOffPoint,
    renames: ['point'],
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.faceOffInProgress = true;
    },
});
