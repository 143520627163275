import { AnimationMap, ModelMap } from 'features/futsal/constants';
import { Event } from './event.model';

export const PenaltyShootoutStartEvent = new Event({
    type: ModelMap.PenaltyShootoutStart,
    animationName: AnimationMap.PenaltyShootoutStart,
    calculate(store) {
        // eslint-disable-next-line no-param-reassign
        store.translationData.statistics.isPenaltyStarted = true;
    },
});

export const PenaltyShootoutEndEvent = new Event({
    type: ModelMap.PenaltyShootoutEnd,
    animationName: AnimationMap.PenaltyShootoutEnd,
});
