import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useFootballCirclesTL, useTargetName } from 'hooks';
import { addToRefs } from 'features/football/utils';

export function CornerCircles(props) {
    const { circles, team, isBottomCorner } = props;
    const circlesRefs = useFootballCirclesTL();
    const { conditions } = useTargetName();
    const isHome = team === 1;

    const outerCls = cx('corner-circle_outer', {
        '_top-right': isHome && !isBottomCorner,
        '_bottom-right': isHome && isBottomCorner,
        '_top-left': !isHome && !isBottomCorner,
        '_bottom-left': !isHome && isBottomCorner,
    });

    const circlesContCls = cx('corner-circle_inner_cont', {
        '_top-right': isHome && !isBottomCorner,
        '_bottom-right': isHome && isBottomCorner,
        '_top-left': !isHome && !isBottomCorner,
        '_bottom-left': !isHome && isBottomCorner,
    });

    const innerDotCls = cx('corner-circle_inner', {
        'football-home-team-shadow': isHome,
        'football-away-team-shadow': !isHome,
        vivi: conditions.isVivi,
    });

    return (
        <div className="corner-circle_cont">
            <div className={outerCls}>
                <span className={innerDotCls} />
            </div>
            <div className={circlesContCls}>
                {Array.from({ length: circles }, (_, index) => (
                    <span ref={(el) => addToRefs(el, circlesRefs)} key={index} className="corner-circle_circles" />
                ))}
            </div>
        </div>
    );
}

CornerCircles.propTypes = {
    circles: PropTypes.number.isRequired,
    team: PropTypes.number.isRequired,
    isBottomCorner: PropTypes.bool.isRequired,
};
