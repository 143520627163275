import React from 'react';
import i18n from 'i18n';

import { RedCard } from 'ui/atoms/Icons/RedCard';
import { YellowCard } from 'ui/atoms/Icons/YellowCard';
import { Event } from 'features/common/models/event.model';
import { getTimeText } from 'features/handball/models/common';
import { AnimationMap, ModelMap } from 'features/rugby/constants';

const getCardState = (type) => {
    if (type === ModelMap.RedCard) {
        return {
            store: 'redCards',
            text: i18n.t('log.redCard'),
            subtype: 'red',
        };
    }
    return {
        store: 'yellowCards',
        text: i18n.t('log.yellowCard'),
        subtype: 'yellow',
    };
};

export const Card = new Event({
    type: [ModelMap.RedCard, ModelMap.YellowCard],
    animationName: AnimationMap.Card,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        const { team, period, type } = eventData;
        const cardState = getCardState(type);

        statistics[cardState.store][team].push({ period });
    },

    pushToLog(log, eventData, store) {
        const { statistics } = store.translationData;
        const { currentPeriod } = statistics;

        const choiceSvgForCard = (type) => {
            if (type === ModelMap.YellowCard) {
                return <YellowCard />;
            }

            return <RedCard />;
        };

        log.push({
            component: 'DefaultLogEvent',
            type: 'card',
            svg: choiceSvgForCard(eventData.type),
            id: eventData.id,
            time: eventData.time,
            team: eventData.team,
            period: currentPeriod,
            ...getCardState(eventData.type),
            timeText: getTimeText(eventData.time, statistics),
        });
    },
});
