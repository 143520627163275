import React from 'react';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';

import { animationDataMap } from 'features/futsal/animations/data';

export function CenterIcon(props) {
    const { animationName } = props;

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: animationDataMap[animationName],
    });

    return (
        <div className="text-white h-7 w-7 sm:h-18 sm:w-18">
            <Lottie lottieRef={lottieRef} width="100%" height="100%" />
        </div>
    );
}

CenterIcon.propTypes = {
    animationName: PropTypes.string.isRequired,
};
