import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/rugby/constants';

export const MatchEnd = new Event({
    type: ModelMap.MatchEnd,
    animationName: AnimationMap.MatchEnd,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isMatchEnded = true;
        statistics.isTimerPaused = true;
    },
    pushToLog(log, eventData, store) {
        const { statistics } = store.translationData;
        const { currentPeriod } = statistics;

        log.push({
            component: 'GeneralLogEvent',
            text: i18n.t('log.matchEnd'),
            period: currentPeriod,
            type: 'matchEnd',
            id: eventData.id,
        });
    },
});
