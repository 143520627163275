import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/basketball/constants';
import { Event } from 'features/common/models/event.model';

export const FoulEvent = new Event({
    type: ModelMap.Foul,
    // renames: ['team', 'time', 'period'],
    animationName: AnimationMap.Foul,
    isPreventProcessing: (evtData) => !evtData.team,
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        const { team, period, time } = evtData;
        if (team) {
            statistics.fouls[team].push({
                period,
                time,
            });
        }
    },
    /* pushToLog gets log, eventData and store */
    pushToLog(log, evtData, store) {
        const { time, period, team, id } = evtData;

        const { homeTeam, awayTeam } = store;
        const { statistics } = store.translationData;
        const { score } = statistics;

        log.push({
            time,
            type: 'foul',
            component: 'DefaultLogEvent',
            period,
            team,
            svg: 'BbFoulIcon',
            id,
            text: i18n.t('action.foul.log'),
            timeText: `${score[homeTeam]}:${score[awayTeam]}`,
            translucent: true,
        });
    },
});
