import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/futsal/constants';
import { FieldDangerAttackArrow } from './common/FieldDangerAttackArrow';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function DangerousAttackAnimation(props) {
    // todo [drone] maybe need to remove
    const { team } = props;
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { statistics } = store.translationData;
    const { ballPossessionData } = statistics;

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    function totalPossessionTime(teamNumber, stats) {
        return stats[teamNumber].reduce((acc, curr) => acc + curr.possessionTime, 0);
    }

    const stats = {
        home: 50,
        away: 50,
    };

    const homePossession = totalPossessionTime(1, ballPossessionData);
    const awayPossession = totalPossessionTime(2, ballPossessionData);
    const totalPossession = homePossession + awayPossession;

    stats.home = totalPossession ? Math.round((homePossession / totalPossession) * 100) : 50;
    stats.away = totalPossession ? 100 - stats.home : 50;

    return (
        <div ref={nodeRef}>
            <FieldDangerAttackArrow team={team} ref={rectRef} />
            <FieldText
                title={translate('action.dangerousAttack.animation')}
                subText={translate('statistics.possession')}
                team={team}
                homeStat={stats.home}
                awayStat={stats.away}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                isPercent
            />
            <FieldIcon animationName="dangerousAttack" team={team} />
        </div>
    );
}

DangerousAttackAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

DangerousAttackAnimation.ANIMATION_NAME = AnimationMap.DangerousAttack;
