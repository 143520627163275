import i18n from 'i18n';

import { ModelMap } from 'features/volleyball/constants';
import { Event } from './event.model';

export function getScoreIndex(log, cb) {
    for (let i = log.length - 1; i > -1; i -= 1) {
        if (cb(log[i])) {
            return i;
        }
    }
    return -1;
}

export const AcePlusEvent = new Event({
    type: ModelMap.AcePlus,
    // renames: ['period', 'team'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        statistics.aces[evtData.team].push({
            period: evtData.period,
        });
    },
    pushToLog(log, evtData, store) {
        const { info } = store.translationData;
        if ([41, 46, 48].includes(info.gateway_code)) {
            const scoreIndex = getScoreIndex(log, (t) => t.type === 'score');

            const { timeText } = log[scoreIndex - 1];
            const logToPush = {
                component: 'DefaultLogEvent',
                team: evtData.team,
                type: 'ace',
                time: 0,
                period: evtData.period,
                timeText,
                text: i18n.t('log.ace'),
                id: log.length,
                translucent: true,
            };
            log.splice(scoreIndex, 0, logToPush);
        }
    },
});
