import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Field } from 'ui/atoms/Icons/Field';
// eslint-disable-next-line import/no-cycle
import { CurrentScreen } from 'features/common/providers/CurrentScreenProvider';

export function FieldTab(props) {
    const { setActiveScreen, wrapperClasses, textClasses } = props;
    const [translate] = useTranslation('common');

    function handleClick() {
        setActiveScreen(CurrentScreen.field);
    }

    return (
        <div onClick={handleClick} className={wrapperClasses}>
            <Field className="h-6 fill-current mr-5px" width={20} height={16} />
            <p className={textClasses}>{translate('tabs.graphic')}</p>
        </div>
    );
}

FieldTab.propTypes = {
    setActiveScreen: PropTypes.func.isRequired,
    wrapperClasses: PropTypes.string.isRequired,
    textClasses: PropTypes.string.isRequired,
};
