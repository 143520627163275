import { CurrentScreen } from 'features/common/providers/CurrentScreenProvider';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { useEventsLoadingState } from './useEventsLoadingState';

export const useMainSportConfig = (screenMap) => {
    const tabsProps = Object.keys(CurrentScreen).map((screen, index) => ({
        id: `${screen}${index}`,
        screen,
    }));

    const { isLoading, isFailed, isDisabled } = useEventsLoadingState(RootMachineContext);

    const screens = screenMap(CurrentScreen);

    return {
        tabsProps,
        isDisabled,
        isLoading,
        isFailed,
        screens,
    };
};
