/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import i18n from 'i18n';

import { ModelMap } from 'features/tableTennis/constants';
import { BouncesEvent } from './bounces.model';
import { BouncesScoreEvent } from './bouncesScore.model';
import { Event } from './event.model';
import { MatchCountdownEvent } from './matchCountdown.model';
import { MatchEndEvent } from './matchEnd.model';
import { PenaltyPointEvent } from './penaltyPoint.model';
import { RemovalEvent } from './removal.model';
import { ScoreEvent } from './score.model';
import { ServeEvent } from './serve.model';
import { SetEndEvent } from './setEnd.model';
import { SetStartEvent } from './setStart.model';
import { TeamTimeoutEvent } from './teamTimeout.model';
import { TimeoutEvent } from './timeout.model';
import { WarningEvent } from './warning.model';

function getScore(obj) {
    return {
        1: obj.setScore1,
        2: obj.setScore2,
    };
}

// may be extra sets???
// TODO deobfuscate
function getExtraSets(evt, store) {
    const { info } = store.translationData;
    const { team } = evt;
    // const score = {
    //     1: evt.setScore1,
    //     2: evt.setScore2,
    // };
    const score = getScore(evt);
    const concernedTeam = team === 1 ? 2 : 1;
    const concernedScore = score[team];
    // eslint-disable-next-line no-nested-ternary
    return store.translationData.statistics.upTo7PointsInSet
        ? concernedScore === 7
        : info.tournament.includes('FNTR')
          ? concernedScore === (evt.set === +info.extra.sets ? 7 : 11)
          : concernedScore >= 11 && concernedScore - score[concernedTeam] >= 2;
}

// TODO deobfuscate
// eslint-disable-next-line consistent-return
function setServeNumber(store, evt) {
    const { servesData } = store.translationData.statistics;
    if (servesData.serveNumber === 1) {
        const { setScore1, setScore2 } = evt;
        const absScore = Math.abs(setScore1 - setScore2);
        if (setScore1 < 10 || setScore2 < 10 || absScore >= 2) {
            // eslint-disable-next-line no-return-assign
            return (servesData.serveNumber += 1);
        }
    }
    servesData.currentTeam = servesData.currentTeam === 1 ? 2 : 1;
    servesData.serveNumber = 1;
}

// TODO deobfuscate
function pushToLog(store, evt) {
    const { log } = store.translationData;
    const { scoreChanges } = store.translationData.statistics;
    const { homeTeam, awayTeam } = store;
    const { setScore1, setScore2, set, team, servingTeam, serveNumber } = evt;
    log.push({
        component: 'DefaultLogEvent',
        period: set,
        timeText: (() => {
            if (setScore1 + setScore2 === 1) {
                return '0 - 0';
            }
            const lastScoreChanges = scoreChanges[scoreChanges.length - 2];
            const newScore = {
                1: lastScoreChanges.setScore1,
                2: lastScoreChanges.setScore2,
            };
            return `${newScore[homeTeam]} - ${newScore[awayTeam]}`;
        })(),
        team: servingTeam,
        id: log.length,
        type: 'serve',
        text: serveNumber === 2 ? i18n.t('log.secondServe') : i18n.t('log.serve'),
        time: 0,
    });
    const someScore = getScore(evt);
    log.push({
        component: 'DefaultLogEvent',
        period: set,
        team,
        timeText: `${someScore[homeTeam]} - ${someScore[awayTeam]}`,
        id: log.length,
        type: 'score',
        text: i18n.t('log.score'),
        time: 0,
    });
}

const UnknownEvents = [
    new Event({
        type: 1174,
        calculate(store) {
            const { serves, sets } = store.translationData.statistics;
            serves.push({
                ended: false,
                bounces: [],
                set: sets.length,
            });
        },
    }),
    new Event({
        type: 2681,
        // renames: ['x', 'y'],
        calculate(store, evt) {
            const { statistics } = store.translationData;
            const { serves } = statistics;
            serves[serves.length - 1].bounces.push(evt);
            const { teamsreverse } = store.translationData.info;
            if (teamsreverse) {
                evt.x = 100 - evt.x;
                evt.hasBounces = true;
            }
        },
        pushAdditionalEvents(evt) {
            return [
                {
                    ...evt,
                    type: ModelMap.Bounces,
                },
            ];
        },
        isPreventProcessing(_, store) {
            const { statistics } = store.translationData;
            const { serves } = statistics;
            const lastServe = serves[serves.length - 1];
            return !lastServe || !!serves.ended;
        },
    }),
    new Event({
        type: 1128,
        // renames: ['team'],
        pushAdditionalEvents(evt) {
            return [
                {
                    ...evt,
                    type: evt.team ? ModelMap.TeamTimeout : ModelMap.Timeout,
                },
            ];
        },
    }),
    new Event({
        type: 1103,
        pushAdditionalEvents(evt, store) {
            const additionalEvents = [];
            const { info, statistics } = store.translationData;
            // TODO deobfuscate
            return (
                info.gateway_code === 82 &&
                    // eslint-disable-next-line no-sequences
                    additionalEvents.push({
                        type: ModelMap.SetEnd,
                        i1: statistics.sets.length,
                    }),
                additionalEvents.push({ ...evt, type: ModelMap.MatchEnd }),
                additionalEvents
            );
        },
    }),
    new Event({
        type: 1180,
        // renames: [
        //     'set',
        //     'team',
        //     'score1',
        //     'score2',
        //     'setScore1',
        //     'setScore2',
        //     'time',
        //     'scoreType',
        // ],
        calculate(store, evt) {
            const { servesData, scoreChanges, score, sets, serves } = store.translationData.statistics;
            const eventScore = {
                1: evt.score1,
                2: evt.score2,
            };
            scoreChanges.push(evt);
            const extraSets = getExtraSets(evt, store);
            evt.isSetEnd = extraSets;
            score[1] = eventScore[1];
            score[2] = eventScore[2];
            sets.find((e) => e.number === evt.set).score = {
                1: evt.setScore1,
                2: evt.setScore2,
            };
            evt.info = evt.scoreType === 5 ? '+2' : '+1';
            evt.servingTeam = servesData.currentTeam;
            evt.serveNumber = servesData.serveNumber;
            setServeNumber(store, evt);
            pushToLog(store, evt);
            if (serves.length) {
                serves[serves.length - 1].ended = true;
            }
        },
        pushAdditionalEvents(evt, store) {
            const { scoreType, team } = evt;
            const { statistics } = store.translationData;
            const additionalEvents = [];

            // TODO deobfuscate

            if (
                // eslint-disable-next-line no-mixed-operators
                ((scoreType !== 4 && scoreType !== 5) ||
                    // eslint-disable-next-line no-mixed-operators
                    additionalEvents.push(
                        {
                            ...evt,
                            type: ModelMap.PenaltyPoint,
                            team: team === 1 ? 2 : 1,
                        },
                        // eslint-disable-next-line no-sequences
                    ),
                additionalEvents.push({
                    ...evt,
                    type: statistics.hasBounces ? ModelMap.PenaltyPoint : ModelMap.Score,
                }),
                !evt.isSetEnd)
            ) {
                const { servesData } = statistics;
                additionalEvents.push({
                    ...evt,
                    type: ModelMap.Serve,
                    servingTeam: servesData.currentTeam,
                    serveNumber: servesData.serveNumber,
                });
            }
            return additionalEvents;
        },
    }),
    new Event({
        type: 1212,
        // renames: ['set', 'team'],
        calculate(store, evt) {
            const { sets, servesData } = store.translationData.statistics;
            const { team } = evt;
            const lastSet = sets[sets.length - 1];
            servesData.currentTeam = team;
            lastSet.firstServingTeam = team;
        },
        pushAdditionalEvents(evt, store) {
            return [
                {
                    ...evt,
                    servingTeam: store.translationData.statistics.servesData.currentTeam,
                    type: ModelMap.Serve,
                },
            ];
        },
        isPreventProcessing(_, store) {
            const sets = store.translationData.statistics;
            return sets.length > 1;
        },
    }),
];

export function EventFactory() {
    return [
        MatchCountdownEvent,
        ScoreEvent,
        BouncesScoreEvent,
        ServeEvent,
        SetStartEvent,
        SetEndEvent,
        MatchEndEvent,
        TeamTimeoutEvent,
        TimeoutEvent,
        WarningEvent,
        RemovalEvent,
        BouncesEvent,
        PenaltyPointEvent,
        ...UnknownEvents,
    ];
}
