export const ModelMap = {
    SetScore: 1411,
    BallEnter: 1034,
    BallPossession: 1421,
    EmptyGate: 1445,
    FreeThrow: 1453,
    Goal: 1401,
    GoalkeeperHasBall: 1457,
    MatchCountdown: -20000,
    MatchEnd: 1103,
    OvertimeStart: -11180,
    PenaltyGoal: 1152,
    PenaltyMiss: 1154,
    PenaltyShootout: 1165,
    PenaltyThrow: -14250,
    PeriodCountdown: -20001,
    PeriodEnd: 1102,
    PeriodStart: -1118,
    PlayersOnField: 1206,
    RedCard: 1404,
    Removal: 1431,
    SevenMeterThrow: 1425,
    SevenMeterThrowCancelled: 1426,
    TeamTimeout: -1128,
    ThrowOnGates: 1455,
    ThrowOnGatesMiss: 1456,
    Timeout: -11280,
    YellowCard: 1403,
    PeriodStartWithoutAnimation: 1118,
    SevenMeterThrowMissed: 1427,
    TimeoutOrTeamTimeout: 1128,
    TimerPaused: 1407,
    TimerUnpaused: 1406,
};
