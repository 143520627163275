export const statistics = () => ({
    currentPeriod: 1,
    matchDuration: 80,
    timer: 0,
    isTimerPaused: false,
    possessionTeam: 1,
    isMatchEnded: false,
    colors: {
        1: { mainColor: '#B060F7', secondColor: '#B060F7' },
        2: { mainColor: '#F6A623', secondColor: '#F6A623' },
    },
    periods: {
        1: { started: false, ended: false },
        2: { started: false, ended: false },
        3: { started: false, ended: false },
        4: { started: false, ended: false },
        5: { started: false, ended: false },
        6: { started: false, ended: false },
    },
    scoreChanges: [],
    scrums: [],
    scrumWins: { 1: [], 2: [] },
    score: { 1: 0, 2: 0 },
    goals: { 1: [], 2: [] },
    yellowCards: { 1: [], 2: [] },
    redCards: { 1: [], 2: [] },
    attacks: { 1: [], 2: [] },
    dropGoals: { 1: [], 2: [] },
    penaltyShootout: {
        1: [],
        2: [],
    },
    penalties: {
        1: [],
        2: [],
    },
    compensationTime: {
        1: null,
        2: null,
        3: null,
        4: null,
    },
    isLastAnimationWithPosition: false,
});
