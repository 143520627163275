import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const OffsideEvent = new Event({
    type: ModelMap.Offside,
    // renames: ['team', 'period', 'time'],
    animationName: AnimationMap.Offside,
    calculate(store, { time, period, team }) {
        store.translationData.statistics.offsides[team].push({
            time,
            period,
        });
    },
});
