import i18n from 'i18n';

import { ModelMap } from 'features/volleyball/constants';
import { getScoreIndex } from './acePlus.model';
import { Event } from './event.model';

export const FoulPlusEvent = new Event({
    type: ModelMap.FoulPlus,
    // renames: ['period', 'team'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        statistics.fouls[evtData.team].push({
            period: evtData.period,
        });
    },
    pushToLog(log, evtData, store) {
        const { info } = store.translationData;
        if ([41, 46, 48].includes(info.gateway_code)) {
            const scoreIndex = getScoreIndex(log, (t) => t.type === 'score');

            const { timeText, team } = log[scoreIndex - 1];
            const logToPush = {
                component: 'DefaultLogEvent',
                team,
                type: 'foul',
                time: 0,
                period: evtData.period,
                timeText,
                text: i18n.t('log.foulOnServe'),
                id: log.length,
                translucent: true,
            };
            log.splice(scoreIndex, 0, logToPush);
        }
    },
});
