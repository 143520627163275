import ballPossession from './ballPossession.json';
import emptyGates from './emptyGates.json';
import freeThrow from './freeThrow.json';
import goal from './goal.json';
import goalkeeperHasBall from './goalkeaperHasBall.json';
import missedThrow from './missedThrow.json';
import redCard from './redCard.json';
import removal from './removal.json';
import sevenMeterThrow from './sevenMeters.json';
import sevenMeterThrowCancelled from './sevenMetersCancelled.json';
import throwOnGates from './throwOnGates.json';
import yellowCard from './yellowCard.json';

export const animationDataMap = {
    ballPossession,
    emptyGates,
    freeThrow,
    goal,
    goalkeeperHasBall,
    missedThrow,
    removal,
    sevenMeterThrow,
    sevenMeterThrowCancelled,
    throwOnGates,
    redCard,
    yellowCard,
};
