import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/football/constants';
import { usePenaltyStatistic } from 'features/football/hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { HalfRectangle } from './common/HalfRectangle';

export function PenaltyAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const nodeRef = useNodeTimeline();
    const rectRef = useRectTimeline();

    const { awayStat, homeStat, label: subtext } = usePenaltyStatistic();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate('action.penalty.animation')}
                subText={subtext}
                team={team}
                homeStat={homeStat}
                awayStat={awayStat}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName="penalty" team={team} />
        </div>
    );
}

PenaltyAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

PenaltyAnimation.ANIMATION_NAME = AnimationMap.Penalty;
