import ace from './ace.json';
import foul from './foul.json';
import score from './score.json';
import serve from './serve.json';
import start from './start.json';
import timeout from './timeout.json';
import warmUp from './warmUp.json';

export const animationDataMap = {
    ace,
    foul,
    score,
    serve,
    start,
    timeout,
    warmUp,
};
