import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useNodeTimeline, useTargetName } from 'hooks';
import { AnimationMap } from 'features/basketball/constants';

export function MatchEndAnimation() {
    const nodeRef = useNodeTimeline();
    const { classMap: targetPrefix } = useTargetName((targets) => ({
        [targets.MOSTBET]: '_mostbet',
        [targets.BETANDREAS]: '_betandreas',
        [targets.VIVI]: '_vivi',
    }));
    const [translate] = useTranslation();

    return (
        <div ref={nodeRef}>
            <div className={cx('bb-full_rectangle', targetPrefix)} />
            <div className="bb-playing_status">
                <div className="bb-playing_status--title">
                    <p>{translate('action.matchEnd.animation')}</p>
                </div>
            </div>
        </div>
    );
}

MatchEndAnimation.ANIMATION_NAME = AnimationMap.MatchEnd;
