import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/badminton/constants';
import { Event } from 'features/common/models/event.model';

export const SetPeriodStartEvent = new Event({
    type: ModelMap.SetStart,
    animationName: AnimationMap.SetStart,
    renames: ['period'],
    pushToLog(log, eventData) {
        const { period } = eventData;
        log.push({
            component: 'GeneralLogEvent',
            type: 'setStart',
            translucent: !0,
            text: i18n.t('log.setStart', { period }),
            period,
            id: log.length,
        });
    },
});
