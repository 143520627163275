export const sports = {
    football: 'football',
    tableTennis: 'tableTennis',
    volleyball: 'volleyball',
    beachVolleyball: 'beachVolleyball',
    basketball: 'basketball',
    basketball3x3: 'basketball3x3',
    iceHockey: 'ice-hockey',
    bigTennis: 'big-tennis',
    handball: 'handball',
    badminton: 'badminton',
    floorball: 'floorball',
    futsal: 'futsal',
    rugby: 'rugby',
};
