import { AceEvent } from './ace.model';
import { AcePlusEvent } from './acePlus.model';
import { BlockEvent } from './block.model';
import { FoulEvent } from './foul.model';
import { FoulPlusEvent } from './foulPlus.model';
import { MatchCountdownEvent } from './matchCountdown.model';
import { MatchEndEvent } from './matchEnd.model';
import { PeriodCountdownEvent } from './periodCountdown.model';
import { PeriodEndEvent } from './periodEnd.model';
import { PeriodStartEvent } from './periodStart.model';
import { PlayersWarmupEvent } from './playersWarmup.model';
import { ScoreEvent } from './score.model';
import { ServeEvent } from './serve.model';
import { ServeAndServingTeamEvent } from './serveAndServingTeam.model';
import { SetOpeningTeamEvent } from './setOpeningTeam.model';
import { TimeoutEvent } from './timeout.model';

export function EventFactory() {
    return [
        MatchCountdownEvent,
        PeriodCountdownEvent,
        SetOpeningTeamEvent,
        ServeAndServingTeamEvent,
        ScoreEvent,
        FoulEvent,
        ServeEvent,
        AceEvent,
        PeriodStartEvent,
        MatchEndEvent,
        PeriodEndEvent,
        AcePlusEvent,
        FoulPlusEvent,
        PlayersWarmupEvent,
        TimeoutEvent,
        BlockEvent,
    ];
}
