import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useIsMobile, useTeamNumbers } from 'hooks';
import { FieldIcon } from './FieldIcon';

export function WithRefFieldText(props, ref) {
    const { icon, team, title, homeTeam, awayTeam, iconReversed } = props;
    const isMobile = useIsMobile();
    const [homeTeamNumber] = useTeamNumbers();
    const isHome = team === homeTeamNumber;
    const wrapperCls = cx('beach-volleyball-field-text_wrapper', {
        'items-start': isMobile && !isHome,
        'items-end': isMobile && isHome,
        'flex-row': !isHome,
        reversed: isHome,
        _left: isHome,
        _right: !isHome,
    });

    const txtCls = cx('flex flex-col justify-center p-3 sm:h-1/2 ', {
        'items-end text-right': isHome,
        'items-start text-left': !isHome,
    });
    const teamCls = cx('beach-volleyball-team-text', {
        'text-right': isHome,
        'text-left': !isHome,
    });

    return (
        <div className={wrapperCls} ref={ref}>
            <FieldIcon team={team} animationName={icon} isReversed={iconReversed} />
            <div className={txtCls}>
                <p className="beach-volleyball-action-text">{title}</p>
                <p className={teamCls}>{isHome ? homeTeam : awayTeam}</p>
            </div>
        </div>
    );
}

export const FieldText = React.forwardRef(WithRefFieldText);

WithRefFieldText.propTypes = {
    icon: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
    homeTeam: PropTypes.string.isRequired,
    awayTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    iconReversed: PropTypes.bool,
};

WithRefFieldText.defaultProps = {
    iconReversed: true,
};
