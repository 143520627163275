import { ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

export const SetTotalSetsCountEvent = new Event({
    type: ModelMap.SetTotalSetsCount,
    renames: ['count'],
    calculate(store, evtData) {
        const { count } = evtData;
        // eslint-disable-next-line no-param-reassign
        store.totalSets = count;
    },
});
