import { ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

export const SetLastSetTieBreakTypeEvent = new Event({
    type: ModelMap.SetLastSetTieBreakType,
    renames: ['lastSetTieBreakType'],
    calculate(store, evtData) {
        const { lastSetTieBreakType } = evtData;
        // eslint-disable-next-line no-param-reassign
        store.tieBreakLastSetType = lastSetTieBreakType;
    },
});
