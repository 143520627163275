import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTeamNumbers } from 'hooks';

function WithRefHalfRectangle(props, ref) {
    const { team, isAnimated } = props;
    const [homeTeamNumber] = useTeamNumbers();
    const isHome = team === homeTeamNumber;

    const classes = cx('big-tennis-half_rectangle', {
        _left: isHome,
        _right: !isHome,
        _isAnimated: !isAnimated,
    });
    return <div className={classes} ref={ref} />;
}

export const HalfRectangle = React.forwardRef(WithRefHalfRectangle);

WithRefHalfRectangle.propTypes = {
    team: PropTypes.number.isRequired,
    isAnimated: PropTypes.bool,
};

WithRefHalfRectangle.defaultProps = {
    isAnimated: true,
};
