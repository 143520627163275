/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { getEnvironmentTargets } from 'lib/getEnvironmentTargets';
import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';
import { getRemovalsByTeam, getTimeText } from './common';

export const GoalEvent = new Event({
    type: ModelMap.Goal,
    animationName: AnimationMap.Goal,
    calculate(store, eventData) {
        const { team, time } = eventData;
        const { statistics } = store.translationData;
        const { possessionTeam, currentPeriod, majorityPlays, goals, score, attacksRealization } = statistics;
        goals[team].push({
            time: eventData.time,
            period: currentPeriod,
            id: eventData.id,
        });
        score[team] += 1;
        if (team === possessionTeam) {
            attacksRealization[team].push({
                period: currentPeriod,
                time,
            });
        }
        const removals = getRemovalsByTeam(statistics, time);
        if (removals[team === 1 ? 2 : 1] > removals[team]) {
            const c = majorityPlays[team];
            c[c.length - 1].fulfilled = true;
        }
    },
    pushToLog(log, eventData, store) {
        const { homeTeam, awayTeam } = store;
        const { statistics } = store.translationData;
        const { goals, currentPeriod } = statistics;

        const { isVivi } = getEnvironmentTargets();

        const goalText = i18n.t('log.goal');

        const baseText = `${goalText}, ${goals[homeTeam].length}:${goals[awayTeam].length}`;
        const viviText = goalText;

        const baseTimeText = getTimeText(eventData.time, statistics);
        const viviTimeText = `${goals[homeTeam].length} - ${goals[awayTeam].length}`;

        log.push({
            component: 'DefaultLogEvent',
            team: eventData.team,
            text: isVivi ? viviText : baseText,
            svg: 'log/goal',
            time: eventData.time,
            timeText: isVivi ? viviTimeText : baseTimeText,
            period: currentPeriod,
            type: 'score',
            id: eventData.id,
        });
    },
    redirect(event, statistics) {
        return (
            !!statistics.penaltyShootoutStarted && {
                ...event,
                type: ModelMap.PenaltyGoal,
            }
        );
    },
});
