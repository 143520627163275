/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';

export const SevenMeterThrowCancelledEvent = new Event({
    type: ModelMap.SevenMeterThrowCancelled,
    animationName: AnimationMap.SevenMeterThrowCancelled,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        statistics.sevenMeterThrows[eventData.team].pop();
    },
});
