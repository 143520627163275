import {
    BallPossessionAnimation,
    FightAnimation,
    FoulAnimation,
    FreeThrowAnimation,
    FreeThrowCanceledAnimation,
    FreeThrowGoalAnimation,
    FreeThrowNotScoredAnimation,
    MatchCountdownAnimation,
    MatchEndAnimation,
    MissAnimation,
    PeriodCountdownAnimation,
    PeriodEndAnimation,
    PeriodStartAnimation,
    PlayersOnFieldAnimation,
    RemovalAnimation,
    ScoreChangeAnimation,
    TeamTimeoutAnimation,
    TimeoutAnimation,
    TimerPausedAnimation,
} from 'features/basketball3x3/animations';
import { AnimationMap } from 'features/basketball3x3/constants';

export const animationFunctions = [
    BallPossessionAnimation,
    FightAnimation,
    FoulAnimation,
    FreeThrowAnimation,
    FreeThrowCanceledAnimation,
    FreeThrowGoalAnimation,
    FreeThrowNotScoredAnimation,
    MatchCountdownAnimation,
    MatchEndAnimation,
    MissAnimation,
    PeriodCountdownAnimation,
    PeriodEndAnimation,
    PeriodStartAnimation,
    PlayersOnFieldAnimation,
    RemovalAnimation,
    ScoreChangeAnimation,
    TeamTimeoutAnimation,
    TimeoutAnimation,
    TimerPausedAnimation,
];
export const animationFnMap = {
    [AnimationMap.BallPossession]: BallPossessionAnimation,
    [AnimationMap.Fight]: FightAnimation,
    [AnimationMap.Foul]: FoulAnimation,
    [AnimationMap.FreeThrow]: FreeThrowAnimation,
    [AnimationMap.FreeThrowCanceled]: FreeThrowCanceledAnimation,
    [AnimationMap.FreeThrowGoal]: FreeThrowGoalAnimation,
    [AnimationMap.FreeThrowNotScored]: FreeThrowNotScoredAnimation,
    [AnimationMap.MatchCountdown]: MatchCountdownAnimation,
    [AnimationMap.MatchEnd]: MatchEndAnimation,
    [AnimationMap.Miss]: MissAnimation,
    [AnimationMap.PeriodCountdown]: PeriodCountdownAnimation,
    [AnimationMap.PeriodEnd]: PeriodEndAnimation,
    [AnimationMap.PeriodStart]: PeriodStartAnimation,
    [AnimationMap.PlayersOnField]: PlayersOnFieldAnimation,
    [AnimationMap.Removal]: RemovalAnimation,
    [AnimationMap.ScoreChange]: ScoreChangeAnimation,
    [AnimationMap.TeamTimeout]: TeamTimeoutAnimation,
    [AnimationMap.Timeout]: TimeoutAnimation,
    [AnimationMap.TimerPaused]: TimerPausedAnimation,
};
