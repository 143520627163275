/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
// eslint-disable-next-line import/no-cycle
import { ModelMap } from 'features/handball/constants';
import { getPeriod } from './common';

export const PeriodStartWithoutAnimationEvent = new Event({
    type: ModelMap.PeriodStartWithoutAnimation,
    calculate(store, eventData) {
        const { periods } = store.translationData.statistics;
        const { period } = eventData;

        eventData.period = getPeriod(eventData, store);
        periods[period].started = true;
        store.isTimerPaused = false;
        store.currentPeriod = period;
    },
    pushToLog(log, eventData, store) {
        const { homeTeam, awayTeam } = store;
        const { goals } = store.translationData.statistics;
        const { period } = eventData;
        const periodText =
            period > 2
                ? i18n.t('time.overtime', { number: `${period - 2}` })
                : i18n.t('time.period', { number: `${period}` });

        log.push({
            component: 'PeriodLogEvent',
            firstTeamScore: goals[homeTeam].length,
            secondTeamScore: goals[awayTeam].length,
            period,
            periodText,
            type: 'periodStart',
            id: eventData.id,
        });
    },
    pushAdditionalEvents(event) {
        return event.period > 2
            ? [{ ...event, type: ModelMap.OvertimeStart }]
            : [{ ...event, type: ModelMap.PeriodStart }];
    },
    isPreventProcessing(event, store) {
        const { currentPeriod } = store.translationData.statistics;
        return (currentPeriod === 4 || currentPeriod === 6) && event.period === 2;
    },
});
