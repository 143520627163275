/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/football/constants';
// eslint-disable-next-line import/no-cycle
import { Circle } from './common/Circle';

export function FightAnimation() {
    const [translate] = useTranslation();
    const circleRef = useRectTimeline();

    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <Circle title={translate('action.fight.animation')} animationName="fight" ref={circleRef} />
        </div>
    );
}

FightAnimation.ANIMATION_NAME = AnimationMap.Fight;
