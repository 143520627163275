import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline, useRectTimeline } from 'hooks';
// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/football/constants';
import { FullRectangle } from './common/FullRectangle';

export function CompensationTimeAnimation() {
    const [translate] = useTranslation();
    const timeRef = useRectTimeline();
    const nodeRef = useNodeTimeline();
    const { current } = useContext(RootMachineContext);
    const { compensationTime, currentPeriod, hasPeriodEnded } = current.context.store.translationData.statistics;
    const compensationTimeValue = hasPeriodEnded[currentPeriod] ? null : compensationTime[currentPeriod];

    return (
        <div ref={nodeRef}>
            <FullRectangle />
            <div className="abs-center text-center font-medium">
                {compensationTimeValue && (
                    <p className="sm:text-4xl text-white" ref={timeRef}>
                        +{compensationTimeValue}’
                    </p>
                )}
                <p className="sm:text-3xl uppercase football-text-quaternary-light py-4">
                    {translate('action.compensationTime.animation')}
                </p>
            </div>
        </div>
    );
}

CompensationTimeAnimation.ANIMATION_NAME = AnimationMap.CompensationTime;
