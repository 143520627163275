/* eslint-disable max-len */
import React from 'react';

export function Field(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                fillRule="evenodd"
                d="M17 2H3C1.3 2 0 3.3 0 5v10c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3V5c0-1.7-1.3-3-3-3zM2 8h2v4H2V8zm7 8H3c-.6 0-1-.4-1-1v-1h4V6H2V5c0-.6.4-1 1-1h6v3.2c-1.2.4-2 1.5-2 2.8s.8 2.4 2 2.8V16zm1-5c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm8 1h-2V8h2v4zm0-6h-4v8h4v1c0 .6-.4 1-1 1h-6v-3.2c1.2-.4 2-1.5 2-2.8s-.8-2.4-2-2.8V4h6c.6 0 1 .4 1 1v1z"
                clipRule="evenodd"
            />
        </svg>
    );
}
