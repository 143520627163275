import React from 'react';
import { useTranslation } from 'react-i18next';
import { Lottie, useLottie } from 'react-lottie-hook';

import { useCallbackTL, useNodeTLCallback } from 'hooks';
import { animationDataMap } from 'features/beachVolleyball/animations/data';
import { AnimationMap } from 'features/beachVolleyball/constants';

export function TimeoutAnimation() {
    const [translate] = useTranslation();

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: animationDataMap.timeout,
    });

    const nodeRef = useNodeTLCallback();
    useCallbackTL(nodeRef);
    return (
        <div ref={nodeRef}>
            <div className="beach-volleyball-full_rectangle" />
            <div className="beach-volleyball-playing_status">
                <div className="beach-volleyball-playing_status--icon">
                    <Lottie lottieRef={lottieRef} width="100%" height="100%" />
                </div>
                <div className="beach-volleyball-playing_status--text">
                    <p>{translate('animation.timeout')}</p>
                </div>
            </div>
        </div>
    );
}

TimeoutAnimation.ANIMATION_NAME = AnimationMap.Timeout;
