import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/rugby/constants';

export const ScrumWinsModel = new Event({
    type: ModelMap.ScrumWins,
    calculate(store, eventData) {
        const { statistics } = store.translationData;

        statistics.scrumWins[eventData.team].push({
            time: eventData.regtime,
            period: eventData.period,
            id: eventData.id,
        });
    },
});
