import { AnimationMap, ModelMap } from 'features/basketball/constants';
import { Event } from 'features/common/models/event.model';

export const TimerPausedEvent = new Event({
    type: ModelMap.TimerPaused,
    // renames: ['time'],
    animationName: AnimationMap.TimerPaused,
    calculate(store) {
        const { statistics } = store.translationData;
        statistics.isTimerPaused = true;
    },
    isPreventProcessing(_, store) {
        const { info, statistics } = store.translationData;
        return info.gateway_code !== 37 || statistics.isMatchEnded;
    },
});
