import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const PeriodStartEvent = new Event({
    type: ModelMap.PeriodStart,
    // renames: ['period'],
    animationName: AnimationMap.PeriodStart,
    calculate(store) {
        // eslint-disable-next-line no-param-reassign
        store.translationData.statistics.isTimerPaused = false;
    },
    // setLastAction(eventData) {
    //     return {
    //         type: 'periodStart',
    //         team: -1,
    //         id: eventData.id,
    //     };
    // },
});
