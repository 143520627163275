import React from 'react';
import cn from 'classnames';

export const ViviLoader = ({ className }) => (
    <div className={cn('loader', className)}>
        <div className="vivi-spinner">
            <div className="vivi-spinner__dot" />
        </div>
    </div>
);
