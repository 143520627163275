/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AttackAnimation } from 'features/football/animations/attack.animation';
import { BallPossessionAnimation } from 'features/football/animations/ballPossession.animation';
import { DangerousAttackAnimation } from 'features/football/animations/dangerousAttack.animation';
import { AnimationMap } from 'features/football/constants';

export function BallPositionAnimation(props) {
    const { team } = props;
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;

    function getRelativePoint() {
        let coords = {
            x: null,
            y: null,
        };
        const { ballPoint } = store.translationData.statistics;
        if (ballPoint) {
            coords = {
                x: team === store.awayTeam ? 100 - ballPoint.x : ballPoint.x,
                y: ballPoint.y,
            };
        }
        return coords;
    }
    function getPossessionType() {
        const { x } = getRelativePoint();
        if (!x) {
            return 'ballPossession';
        }
        // eslint-disable-next-line no-nested-ternary
        return x <= 50
            ? BallPositionAnimation.animationNames.ballPossession
            : x <= 75
              ? BallPositionAnimation.animationNames.attack
              : BallPositionAnimation.animationNames.dangerousAttack;
    }

    // eslint-disable-next-line consistent-return
    const renderAnimationSwitch = (possessionType) => {
        // eslint-disable-next-line default-case
        switch (possessionType) {
            case 'ballPossession':
                return <BallPossessionAnimation {...props} />;
            case 'attack':
                return <AttackAnimation {...props} />;
            case 'dangerousAttack':
                return <DangerousAttackAnimation {...props} />;
        }
    };

    return <>{renderAnimationSwitch(getPossessionType())}</>;
}

BallPositionAnimation.animationNames = {
    ballPossession: 'ballPossession',
    attack: 'attack',
    dangerousAttack: 'dangerousAttack',
};

BallPositionAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

BallPositionAnimation.ANIMATION_NAME = AnimationMap.BallPosition;
