import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from '../constants';

export const BallPositionEvent = new Event({
    type: ModelMap.BallPosition,
    animationName: AnimationMap.BallPossession,
    calculate(store, eventData) {
        const { statistics } = store.translationData;

        // eslint-disable-next-line no-param-reassign
        store.translationData.lastAction = eventData;
        statistics.possessionTeam = eventData.team;
    },
    isPreventProcessing: (evtData) => !evtData.team,
});
