export const statistics = () => ({
    isMatchEnd: !1,
    upTo7PointsInSet: !1,
    score: {
        1: 0,
        2: 0,
    },
    sets: [
        {
            number: 1,
            score: {
                1: 0,
                2: 0,
            },
        },
    ],
    servesData: {
        currentTeam: undefined,
        serveNumber: 1,
    },
    scoreChanges: [],
    timeouts: {
        1: [],
        2: [],
    },
    hasBounces: !1,
    serves: [],
});
