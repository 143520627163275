import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function WithRefHalfRectangle(props, ref) {
    const { team, isYellow, isRed } = props;
    const isHome = team === 1;

    const classes = cx('half_rectangle', {
        _left: isHome,
        _right: !isHome,
        _yellow: isYellow,
        _red: isRed,
    });
    return (
        <div className={classes} ref={ref}>
            <div className="half_circle" />
        </div>
    );
}

export const HalfRectangle = React.forwardRef(WithRefHalfRectangle);

WithRefHalfRectangle.propTypes = {
    team: PropTypes.number.isRequired,
    isYellow: PropTypes.bool,
    isRed: PropTypes.bool,
};

WithRefHalfRectangle.defaultProps = {
    isYellow: false,
    isRed: false,
};
