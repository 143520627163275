import { AnimationMap } from 'features/beachVolleyball/constants';
import { AceAnimation } from './ace.animation';
import { FoulAnimation } from './foul.animation';
import { MatchCountdownAnimation } from './matchCountdown.animation';
import { MatchEndAnimation } from './matchEnd.animation';
import { PeriodCountdownAnimation } from './periodCountdown.animation';
import { PeriodEndAnimation } from './periodEnd.animation';
import { PeriodStartAnimation } from './periodStart.animation';
import { PlayersWarmupAnimation } from './playersWarmup.animation';
import { ScoreAnimation } from './score.animation';
import { ServeAnimation } from './serve.animation';
import { TimeoutAnimation } from './timeout.animation';

export const animationFunctions = [
    AceAnimation,
    FoulAnimation,
    MatchCountdownAnimation,
    MatchEndAnimation,
    PeriodCountdownAnimation,
    PeriodEndAnimation,
    PeriodStartAnimation,
    PlayersWarmupAnimation,
    ScoreAnimation,
    ServeAnimation,
    TimeoutAnimation,
];

export const animationFnMap = {
    [AnimationMap.Ace]: AceAnimation,
    [AnimationMap.Foul]: FoulAnimation,
    [AnimationMap.MatchCountdown]: MatchCountdownAnimation,
    [AnimationMap.MatchEnd]: MatchEndAnimation,
    [AnimationMap.PeriodCountdown]: PeriodCountdownAnimation,
    [AnimationMap.PeriodEnd]: PeriodEndAnimation,
    [AnimationMap.PeriodStart]: PeriodStartAnimation,
    [AnimationMap.PlayersWarmup]: PlayersWarmupAnimation,
    [AnimationMap.Score]: ScoreAnimation,
    [AnimationMap.Serve]: ServeAnimation,
    [AnimationMap.Timeout]: TimeoutAnimation,
};
