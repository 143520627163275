import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const PenaltyMissEvent = new Event({
    type: ModelMap.PenaltyMiss,
    // renames: ['team', 'time', 'period'],
    animationName: AnimationMap.PenaltyMiss,
    isPreventProcessing: (event) => !event.team,
    calculate(store, { team, time, period }) {
        // eslint-disable-next-line no-param-reassign
        store.translationData.statistics.gameTimePenalty = true;
        if (store.translationData.statistics.isPenaltyStarted) {
            store.translationData.statistics.penaltyShootout[team].push({
                time,
                type: 'miss',
                period,
            });
        }
    },
});
