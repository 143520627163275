export const getValueByParametrCssVariable = (cssVariable) => {
    const elementStyles = getComputedStyle(document.body);

    const value = elementStyles.getPropertyValue(`${cssVariable}`).trim();

    if (!value) {
        console.error(`getValueByParametrCssVariable don't get ${cssVariable}`);
    }

    return value;
};
