export const ModelMap = {
    BallPosition: 2681, // todo [drone] maybe remove
    BallPossession: [1421, 1113],
    Attack: 1106,
    DangerousAttack: 1107,
    FreeKick: [1116, 1117, 1169],
    FreeKickMiss: -1332,
    FreeKickWithPosition: -10002,
    PenaltyMiss: 1154,
    PenaltyShootoutStart: 1165,
    PenaltyShootoutEnd: 1166,
    PenaltyGoal: 1152,
    Penalty: 1110,
    PeriodStart: 1118,
    PlayersOnField: 1206,
    MatchEnd: 1103,
    HalfTimeEnd: 1102,
    Goal: 1100,
    CornerKick: [1101, -10007],
    CornerKickWithPosition: [2682],
    YellowCard: 1108,
    RedCard: 1109,
    Foul: 1197,
    TimePausedPreventFalse: 1503,
    TimePausedPreventTrue: 1504,
};
