/* eslint-disable max-len */
import React from 'react';

export function Statistics(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                fillRule="evenodd"
                d="M18 12h-3V9c0-.6-.4-1-1-1h-3V3c0-.6-.4-1-1-1H6c-.6 0-1 .4-1 1v5H2c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1h16c.6 0 1-.4 1-1v-4c0-.6-.4-1-1-1zM5 16H3v-6h2v6zm4 0H7V4h2v12zm4 0h-2v-6h2v6zm4 0h-2v-2h2v2z"
                clipRule="evenodd"
            />
        </svg>
    );
}
