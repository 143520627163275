import { AceEvent } from './ace.model';
import { BathroomBreakEvent } from './bathroomBreak.model';
import { CallForTrainerEvent } from './callFotTrainer.model';
import { ChallengeEvent } from './challenge.model';
import { ChallengeNoTeamEvent } from './challengeNoTeam.model';
// import { ChangeSvgColorEvent } from './changeSvgColor.model';
import { DoubleFoulEvent } from './doubleFoul.model';
import { MatchCountdownEvent } from './matchCountdown.model';
import { MatchEndEvent } from './matchEnd.model';
import { MedicalBreakEvent } from './medicalBreak.model';
import { PlayersWarmUpEvent } from './playersWarmUp.model';
import { ScoreEvent } from './score.model';
import { SecondServeEvent } from './secondServe.model';
import { ServeEvent } from './serve.model';
import { SetLastSetTieBreakEvent } from './setLastSetTieBreak.model';
import { SetLastSetTieBreakTypeEvent } from './setLastSetTieBreakType.model';
import { SetScoringAdvantageEvent } from './setScoringAdvantage.model';
import { SetScoringTeamEvent } from './setScoringTeam.model';
import { SetServerInPairEvent } from './setServerInPair.model';
import { SetServingTeamEvent } from './setServingTeam.model';
import { SetTeamsTypeEvent } from './setTeamsType.model';
import { SetTotalSetsCountEvent } from './setTotalSetsCount.model';
import { TennisGameEndEvent } from './tennisGameEnd.model';
import { TennisSetEndEvent } from './tennisSetEnd.model';
import { TimerPausedEvent } from './timerPaused.model';
import { TimerStartedEvent } from './timerStarted.model';
import { ViolationEvent } from './violation.model';
import { WeatherBreakEvent } from './weatherBreak.model';

export function EventFactory() {
    return [
        PlayersWarmUpEvent,
        SetTeamsTypeEvent,
        MatchCountdownEvent,
        AceEvent,
        BathroomBreakEvent,
        CallForTrainerEvent,
        ChallengeEvent,
        ChallengeNoTeamEvent,
        // ChangeSvgColorEvent,
        DoubleFoulEvent,
        MatchCountdownEvent,
        MatchEndEvent,
        MedicalBreakEvent,
        ScoreEvent,
        SecondServeEvent,
        ServeEvent,
        SetLastSetTieBreakEvent,
        SetLastSetTieBreakTypeEvent,
        SetScoringAdvantageEvent,
        SetScoringTeamEvent,
        SetServerInPairEvent,
        SetServingTeamEvent,
        SetTeamsTypeEvent,
        SetTotalSetsCountEvent,
        TennisGameEndEvent,
        TennisSetEndEvent,
        TimerPausedEvent,
        TimerStartedEvent,
        ViolationEvent,
        WeatherBreakEvent,
    ];
}
