import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/iceHockey/constants';
import { IconText } from './common/IconText';

export function TimeoutAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div ref={nodeRef}>
            <IconText icon="timeout" text={translate('action.timeout.animation')} />
        </div>
    );
}

TimeoutAnimation.ANIMATION_NAME = AnimationMap.Timeout;
