import { assign, fromPromise, setup } from 'xstate';

import { hasLength } from 'lib';
import { EventProcessor } from 'features/basketball/services/processor.service';
import { Animator } from 'features/common/services/animator.service';
import { getlastEventId, getTranslationData, getTranslationEvents } from 'features/common/services/event.service';
import { stateContext } from './state.context';

function processTranslationInfo(info) {
    const quarters = info.extra.quarters || '4x10';
    const [totalPeriods, periodDuration] = quarters.split('x').map(Number);
    let overtimeDuration = 5;
    if (totalPeriods === 4 && periodDuration === 8) overtimeDuration = 4;
    if (totalPeriods === 4 && periodDuration === 4) overtimeDuration = 2;
    if (totalPeriods === 4 && periodDuration === 5) overtimeDuration = 3;

    // eslint-disable-next-line no-param-reassign
    info.extra = {
        overtimeDuration,
        periodDuration,
        quarters,
        periodsCount: totalPeriods,
        ...info.extra,
    };

    return info;
}
const storeAction = ({ context, event }) => {
    context.eventProcessor.checkForNewEvents(event.output);
    const { log, statistics } = context.eventProcessor.store.translationData;
    return {
        ...context.eventProcessor.store,
        translationData: {
            ...context.store.translationData,
            log,
            statistics: { ...statistics },
        },
    };
};

const lastEventIdAction = ({ context, event }) => {
    if (hasLength(event.output)) {
        return getlastEventId(event.output);
    }
    return context.lastEventId;
};

export function rootMachine({ translationId }) {
    return setup({
        actors: {
            translationData: fromPromise(() => getTranslationData(translationId)),
            translationEvents: fromPromise(({ input }) => getTranslationEvents(input.translationId, input.lastEventId)),
            newEvents: fromPromise(({ input }) => getTranslationEvents(input.translationId, input.lastEventId)),
            disabled: fromPromise(({ input }) => getTranslationEvents(input.translationId, input.lastEventId)),
        },
        guards: {
            hasGotNoInitialEvents: ({ event }) => event.output.length === 0,
            isWidgetDisabled: ({ event }) => event.error.message.indexOf('disabledMatchError') > -1,
        },
    }).createMachine({
        id: 'root',
        initial: 'idle',
        context: {
            ...stateContext,
            translationId,
        },
        states: {
            idle: {
                invoke: {
                    id: 'idle-invoke',
                    src: 'translationData',
                    onDone: {
                        target: 'initialEvents',
                        actions: [
                            assign({
                                store: ({ context, event }) => {
                                    const { teamsreverse } = event.output;
                                    const homeTeam = teamsreverse ? 2 : 1;
                                    const awayTeam = teamsreverse ? 1 : 2;
                                    return {
                                        ...context.store,
                                        homeTeam,
                                        awayTeam,
                                        translationData: {
                                            ...context.store.translationData,
                                            info: processTranslationInfo(event.output),
                                        },
                                    };
                                },
                            }),
                            assign({
                                eventProcessor: ({ context }) => {
                                    const animator = new Animator(context.store);
                                    return new EventProcessor(context.store, animator);
                                },
                            }),
                        ],
                    },
                    onError: {
                        target: 'failure',
                        actions: assign({ error: ({ event }) => event.error }),
                    },
                },
            },
            failure: {
                type: 'final',
            },
            disabled: {
                invoke: {
                    id: 'disabled-invoke',
                    src: 'disabled',
                    input: ({ context }) => ({
                        translationId: context.translationId,
                        lastEventId: context.lastEventId,
                    }),
                    onDone: {
                        target: 'delay',
                        actions: assign({
                            store: storeAction,
                            lastEventId: lastEventIdAction,
                        }),
                    },
                    onError: {
                        target: 'disabledDelay',
                    },
                },
            },
            initialEvents: {
                invoke: {
                    id: 'initialEvents-invoke',
                    src: 'translationEvents',
                    input: ({ context }) => ({
                        translationId: context.translationId,
                        lastEventId: context.lastEventId,
                    }),
                    onDone: [
                        { target: 'initialEventsDelay', guard: 'hasGotNoInitialEvents' },
                        {
                            target: 'delay',
                            actions: assign({
                                store: ({ context, event }) => {
                                    context.eventProcessor.processInitialEvents(event.output);
                                    const { log, statistics } = context.eventProcessor.store.translationData;
                                    return {
                                        ...context.store,
                                        translationData: {
                                            ...context.store.translationData,
                                            log,
                                            statistics: { ...statistics },
                                        },
                                    };
                                },
                                lastEventId: ({ context, event }) => {
                                    if (hasLength(event.output)) {
                                        return getlastEventId(event.output);
                                    }
                                    return context.lastEventId;
                                },
                            }),
                        },
                    ],
                    onError: {
                        target: 'failure',
                        actions: assign({ error: ({ event }) => event.error }),
                    },
                },
            },
            newEvents: {
                invoke: {
                    id: 'newEvents-invoke',
                    src: 'newEvents',
                    input: ({ context }) => ({
                        translationId: context.translationId,
                        lastEventId: context.lastEventId,
                    }),
                    onDone: {
                        target: 'delay',
                        actions: assign({
                            store: storeAction,
                            lastEventId: lastEventIdAction,
                        }),
                    },
                    onError: [
                        {
                            target: 'disabledDelay',
                            guard: 'isWidgetDisabled',
                            actions: assign({ error: ({ event }) => event.error }),
                        },
                        {
                            target: 'delay',
                            actions: assign({ error: ({ event }) => event.error }),
                        },
                    ],
                },
            },
            delay: {
                after: { 1000: 'newEvents' },
            },
            initialEventsDelay: {
                after: { 1000: 'initialEvents' },
            },
            disabledDelay: {
                after: { 3000: 'disabled' },
            },
        },
    });
}
