import { ModelMap } from 'features/volleyball/constants';
import { Event } from './event.model';

export const SetOpeningTeamEvent = new Event({
    type: ModelMap.SetOpeningTeam,
    // renames: ['period', 'team'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        statistics.periods[0].firstServingTeam = evtData.team;
        statistics.servingTeam = evtData.team;
    },
    isPreventProcessing(_, store) {
        const { statistics } = store.translationData;
        return statistics.periods[0].firstServingTeam !== undefined;
    },
});
