/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const TimerPausedEvent = new Event({
    type: ModelMap.TimerPaused,
    animationName: AnimationMap.TimerPaused,
    calculate(store) {
        store.translationData.statistics.isTimerPaused = true;
    },
});

export const TimerPausedFalseEvent = new Event({
    type: ModelMap.TimerPausedFalse,
    // renames: ['time'],
    calculate(store) {
        store.translationData.statistics.isTimerPaused = false;
    },
});

export const TimerPausedTrueEvent = new Event({
    type: ModelMap.TimerPausedTrue,
    calculate(store) {
        store.translationData.statistics.isTimerPaused = true;
    },
});

export const TimePausedPreventFalse = new Event({
    type: ModelMap.TimePausedPreventFalse,
    calculate(store) {
        store.translationData.statistics.isTimerPaused = false;
    },
    isPreventProcessing: () => false,
});

export const TimePausedPreventTrue = new Event({
    type: ModelMap.TimePausedPreventTrue,
    calculate(store) {
        store.translationData.statistics.isTimerPaused = true;
    },
    isPreventProcessing: () => false,
});
