import attack from './attack.json';
import card from './card.json';
import cornerKick from './cornerKick.json';
import dangerousAttack from './dangerousAttack.json';
import fight from './fight.json';
import freeKick from './freeKick.json';
import goal from './goal.json';
import injury from './injury.json';
import kickFromGoal from './kickFromGoal.json';
import offside from './offside.json';
import out from './out.json';
import penalty from './penalty.json';
import penaltyGoal from './penaltyGoal.json';
import penaltyMiss from './penaltyMiss.json';
import penaltySeries from './penaltySeries.json';
import redCard from './redCard.json';
import substitution from './substitution.json';
import videoReferee from './videoReferee.json';
import whistle from './whistle.json';
import yellowCard from './yellowCard.json';

export const animationDataMap = {
    attack,
    card,
    substitution,
    goal,
    dangerousAttack,
    redCard,
    yellowCard,
    cornerKick,
    out,
    kickFromGoal,
    freeKick,
    fight,
    penaltySeries,
    videoReferee,
    whistle,
    offside,
    penaltyMiss,
    penaltyGoal,
    penalty,
    injury,
};
