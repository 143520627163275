import { Event } from 'features/common/models/event.model';
import { BallPossessionEvent } from './ballPossession.model';
import { FightEvent } from './fight.model';
import { FoulEvent } from './foul.model';
import { FreeThrowMinusEvent, FreeThrowPlusEvent } from './freeThrow.model';
import { FreeThrowCanceledEvent } from './freeThrowCanceled.model';
import { FreeThrowNotScoredEvent } from './freeThrowNotScored.model';
import { MatchCountdownEvent } from './matchCountdown.model';
import { MatchEndEvent } from './matchEnd.model';
import { MissEvent } from './miss.model';
import { PeriodCountdownEvent } from './periodCountdown.model';
import { PeriodEndEvent } from './periodEnd.model';
import { PeriodStartEvent } from './periodStart.model';
import { PlayersOnFieldEvent } from './playersOnField.model';
import { RemovalEvent } from './removal.model';
import { FreeThrowGoalEvent, ScoreChangeEvent } from './scoreChange.model';
import { TeamTimeoutEvent, TimeoutEvent } from './teamTimeout.model';
import { TimerPausedEvent } from './timerPaused.model';

const UnknownEvents = [
    new Event({
        type: 1562,
        // renames: [
        //     'team',
        //     'firstPeriod',
        //     'secondPeriod',
        //     'thirdPeriod',
        //     'fourthPeriod',
        //     'time',
        //     'period',
        //     'total',
        // ],
        calculate(store, evtData) {
            const { statistics } = store.translationData;
            statistics.score[evtData.team] = evtData.total;
        },
    }),
    new Event({
        type: 1565,
        // renames: ['team', 'score', 'time', 'period'],
        calculate(store, evtData) {
            const { statistics } = store.translationData;
            const { team, score } = evtData;
            statistics.score[team] = score;
        },
    }),
    new Event({
        type: 1504,
        // renames: ['time', 'period'],
        calculate(store) {
            const { statistics } = store.translationData;
            statistics.isTimerPaused = true;
        },
    }),
    new Event({
        type: 1503,
        // renames: ['time', 'period'],
        calculate(store) {
            const { statistics } = store.translationData;
            statistics.isTimerPaused = false;
        },
    }),
];

export function EventFactory() {
    return [
        MatchCountdownEvent,
        PeriodCountdownEvent,
        BallPossessionEvent,
        FoulEvent,
        FreeThrowPlusEvent,
        FreeThrowMinusEvent,
        TeamTimeoutEvent,
        TimeoutEvent,
        PlayersOnFieldEvent,
        ScoreChangeEvent,
        FreeThrowGoalEvent,
        PeriodStartEvent,
        TimerPausedEvent,
        PeriodEndEvent,
        MatchEndEvent,
        FreeThrowNotScoredEvent,
        RemovalEvent,
        MissEvent,
        FreeThrowCanceledEvent,
        FightEvent,
        ...UnknownEvents,
    ];
}
