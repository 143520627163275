import { formattedTimer } from 'features/common/utils';

export const getTime = (removal, realTimer, currentPeriod, periodDuration) => {
    const n = currentPeriod - removal.period;
    // eslint-disable-next-line max-len
    return realTimer - (removal.time + n * periodDuration * 60 - 60 * removal.removalTime) - removal.timerSubtract;
};

export const getTeamRemovals = (team, statistics, realTimer) =>
    statistics.removals[team]
        .filter((removal) => getTime(removal, realTimer, statistics.currentPeriod) > -1 && !removal.hidden)
        .map((removal) => ({
            id: removal.id,
            text: this.getRemovalText(removal, realTimer, statistics),
        }));

export const removalsFilter = (removals, realTimer, statistics) =>
    removals.filter((removal) => getTime(removal, realTimer, statistics.currentPeriod) > -1 && !removals.hidden);

export const getRemovalText = (removal, realTimer, currentPeriod, periodDuration) => {
    const i = getTime(removal, realTimer, currentPeriod, periodDuration);
    const n = i % 60;

    return `${Math.floor(i / 60)
        .toString()
        .padStart(2, '0')}:${n.toString().padStart(2, '0')}`;
};
// t e a i
export const getRealTime = (time, period, periodDuration, overtimeDuration, periodsCount) => {
    if (time === null) return null;
    // eslint-disable-next-line max-len
    const timeStamp =
        (period < periodsCount + 1
            ? period * periodDuration * 60
            : 60 * (periodDuration * periodsCount + Number(overtimeDuration))) - time;
    return Math.max(timeStamp, 0);
};

export const getRealFormattedTime = (...rest) => formattedTimer(getRealTime(...rest));
