import { ModelMap } from 'features/football/constants';
import { Event } from './event.model';

export const FoulEvent = new Event({
    type: ModelMap.Foul,
    // renames: ['team', 'period', 'time'],
    calculate(store, { team, period, time }) {
        store.translationData.statistics.fouls[team].push({
            time,
            period,
        });
    },
});
