import { AnimationMap, ModelMap } from 'features/basketball3x3/constants';
import { Event } from 'features/common/models/event.model';
import { getFreeThrowStatus } from './scoreChange.model';

export const FreeThrowNotScoredEvent = new Event({
    type: ModelMap.FreeThrowNotScored,
    // renames: ['team', 'time', 'period'],
    animationName: AnimationMap.FreeThrowNotScored,
    calculate(store) {
        const { statistics } = store.translationData;
        getFreeThrowStatus(statistics, 'missed');
    },
});
