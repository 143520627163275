import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/iceHockey/constants';

export const TeamScoreChangeEvent = new Event({
    type: ModelMap.TeamScoreChange,
    renames: ['team', 'score'],
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        const { team, score } = evtData;
        statistics.score[team] = score;
    },
});

export const BothTeamsScoreChange = new Event({
    type: ModelMap.BothTeamsScoreChange,
    renames: ['home', 'away'],
    calculate(store, evtData) {
        const { home, away } = evtData;
        const { statistics } = store.translationData;

        statistics.score[1] = home;
        statistics.score[2] = away;
    },
});
