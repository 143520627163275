export const ModelMap = {
    MatchCountdown: -2e4,
    PeriodCountdown: -20001,
    PeriodStart: 1118,
    PeriodEnd: 1102,
    Goal: 1100,
    Attack: [1320, 1321, 1312],
    Removal: 1352,
    RemovalEnd: 1353,
    PlayersOnField: 1206,
    UnpauseTimer: 1503,
    PauseTimer: 1504,
    Timeout: 1128,
};
