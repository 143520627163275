import ace from './ace.json';
import bathroomBreak from './bathroomBreak.json';
import challenge from './challenge.json';
import doubleFoul from './doubleFoul.json';
import medicalBreak from './medicalBreak.json';
import playersWarmUp from './playersWarmUp.json';
import score from './score.json';
import serve from './serve.json';
import callForTrainer from './trainerCall.json';
import violation from './violation.json';
import weatherBreak from './weatherBreak.json';

export const animationDataMap = {
    serve,
    score,
    medicalBreak,
    weatherBreak,
    challenge,
    doubleFoul,
    ace,
    playersWarmUp,
    callForTrainer,
    violation,
    bathroomBreak,
};
