/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/football/constants';
import { usePenaltyGoalStatistic } from 'features/football/hooks';
import { Circle } from './common/Circle';

export function PenaltyShootoutEndAnimation() {
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    const { homeStat, awayStat } = usePenaltyGoalStatistic();

    return (
        <div ref={nodeRef}>
            <Circle
                homeStat={homeStat}
                awayStat={awayStat}
                title={translate('action.PenaltyShootoutEnd.animation')}
                animationName="penaltySeries"
                ref={rectRef}
                withStats
            />
        </div>
    );
}

PenaltyShootoutEndAnimation.ANIMATION_NAME = AnimationMap.PenaltyShootoutEnd;
