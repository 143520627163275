import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

// Move to common logic
const pushToLog = (log, eventData, store) => {
    const { sets } = store.translationData.statistics;
    log.push({
        component: 'GeneralLogEvent',
        text: i18n.t('log.break'),
        id: eventData.id,
        type: 'break',
        translucent: !0,
        period: sets[sets.length - 1].number,
    });
};

export const WeatherBreakEvent = new Event({
    type: ModelMap.WeatherBreak,
    animationName: AnimationMap.WeatherBreak,
    renames: ['time', 'period'],
    pushToLog(log, eventData, store) {
        pushToLog(log, eventData, store);
    },
    // quotes
    // calculate() {
    //     const t = window.$store.getters['stats/actualGame'].number % 2 ? 15 : 10;
    //     lt(['game-result', 'set-result', 'set-total'], t);
    // }
});
