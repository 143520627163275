import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTargetName } from 'hooks';

function WithRefHalfRectangle(props, ref) {
    const { classMap: targetPrefix } = useTargetName((targets) => ({
        [targets.MOSTBET]: '_mostbet',
        [targets.BETANDREAS]: '_betandreas',
        [targets.VIVI]: '_vivi',
    }));

    const { team, isAnimated } = props;
    const isHome = team === 1;

    const classes = cx('bb-half_rectangle', targetPrefix, {
        _left: isHome,
        _right: !isHome,
        _isAnimated: !isAnimated,
    });

    return (
        <div className={classes} ref={ref}>
            <div className="bb-half_circle" />
        </div>
    );
}

export const HalfRectangle = React.forwardRef(WithRefHalfRectangle);

WithRefHalfRectangle.propTypes = {
    team: PropTypes.number.isRequired,
    isAnimated: PropTypes.bool,
};

WithRefHalfRectangle.defaultProps = {
    isAnimated: true,
};
