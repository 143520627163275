import { ModelMap } from 'features/tableTennis/constants';
import { Event } from './event.model';

export const TeamTimeoutEvent = new Event({
    type: ModelMap.TeamTimeout,
    // animationName: AnimationMap.TeamTimeout,
    // renames: ['period'],
    calculate(store, evt) {
        // eslint-disable-next-line no-param-reassign
        store.translationData.statistics.timeouts[evt.team].push({
            set: evt.period,
        });
    },
});
