/* eslint-disable max-len */
import * as React from 'react';

import { useTargetName } from 'hooks';

const RemovalEnd = (props) => {
    const { classMap: icon } = useTargetName((targets) => ({
        [targets.MOSTBET]: (
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M20 10c0 1.18-.2 2.33-.6 3.42-.19.52-.76.79-1.28.6a.998.998 0 0 1-.6-1.28c.32-.88.48-1.8.48-2.74 0-4.41-3.59-8-8-8s-8 3.59-8 8 3.59 8 8 8c.94 0 1.85-.16 2.72-.48a.998.998 0 0 1 1.28.6.998.998 0 0 1-.6 1.28c-1.09.4-2.23.6-3.4.6-5.51 0-10-4.49-10-10S4.49 0 10 0s10 4.49 10 10Zm-5.76-4.24a.996.996 0 0 0-1.41 0L10 8.59 7.17 5.76a.996.996 0 1 0-1.41 1.41L8.59 10l-2.83 2.83a.996.996 0 0 0 .71 1.7c.26 0 .51-.1.71-.29L10 11.41l2.83 2.83c.2.2.45.29.71.29.26 0 .51-.1.71-.29a.996.996 0 0 0 0-1.41L11.41 10l2.83-2.83a.996.996 0 0 0 0-1.41Z"
                    fill="#256569"
                />
            </svg>
        ),
        [targets.BETANDREAS]: (
            <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0Zm3.24 10.83a1 1 0 0 1-.326 1.626 1 1 0 0 1-.384.074 1 1 0 0 1-.71-.29L9 10.41l-1.83 1.83a1 1 0 0 1-.71.29 1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.41L7.59 9 5.76 7.17a1 1 0 1 1 1.41-1.41L9 7.59l1.83-1.83a1 1 0 1 1 1.41 1.41L10.41 9l1.83 1.83ZM16 10a1 1 0 0 1-1-1 6 6 0 1 0-6 6 1 1 0 0 1 0 2 8 8 0 1 1 8-8 1 1 0 0 1-1 1Z"
                    fill="#9393A9"
                />
            </svg>
        ),
        [targets.VIVI]: (
            <svg {...props} width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.15 9.23a6.15 6.15 0 1 0-12.3 0 6.15 6.15 0 0 0 12.3 0ZM4.54.63h4.92M11.92 3.08l-1.23 1.23M4.17 9h5.66"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    }));

    return icon;
};

export default RemovalEnd;
