import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap, ModelMap } from '../constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { HalfRectangle } from './common/HalfRectangle';

export function ScoreAnimation({ type, team, score }) {
    const translateTemplate = ((evtType) => {
        if (ModelMap.PenaltyKickScored === evtType) return 'action.penaltyKickScored.animation';
        if (ModelMap.DropGoal === evtType) return 'action.dropGoal.animation';
        return 'action.conversion.animation';
    })(type);

    const [translate] = useTranslation();
    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate(translateTemplate)}
                team={team}
                isWithoutStats
                homeTeam={`${translate('team1')} (+${score})`}
                awayTeam={`${translate('team2')} (+${score})`}
                isPercent
            />
            <FieldIcon animationName="penalty" team={team} />
        </div>
    );
}

ScoreAnimation.propTypes = {
    type: PropTypes.number.isRequired,
    team: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
};

ScoreAnimation.ANIMATION_NAME = AnimationMap.Score;
