export const AnimationMap = {
    MatchCountdown: 'matchCountdown',
    PeriodCountdown: 'periodCountdown',
    PeriodStart: 'periodStart',
    PeriodEnd: 'periodEnd',
    Goal: 'goal',
    Attack: 'attack',
    Removal: 'removal',
    RemovalEnd: 'removalEnd',
    PlayersOnField: 'playersOnField',
    Timeout: 'timeout',
};
