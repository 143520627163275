export const ModelMap = {
    MatchCountdown: -2e4,
    PeriodCountdown: -20001,
    Attack: [1320, 1321, 1312],
    PeriodStart: 1118,
    PeriodEnd: 1102,
    MatchEnd: 1103,
    Goal: 1100,
    PenaltyGoal: -1e4,
    PenaltyOffTarget: 1332,
    VideoReferee: 1067,
    Timeout: 1128,
    AdvertisingTimeout: -10003,
    TeamTimeout: -10002,
    Removal: 1352,
    RemovalEnd: 1353,
    EmptyGate: 1325,
    GoalkeeperInGate: 1326,
    Penalty: 1331,
    PenaltyShot: -10001,
    PenaltyStart: 1165,
    PenaltyEnd: 1166,
    PenaltyDelay: -10004,
    PlayersOnField: 1206,
    PlayersWarmUp: 1031,
    Fight: 1170,
    ShotOnGoal: [1315, 1114],
    FaceOff: 1310,
    FaceOffPoint: 1316,
    UnpauseTimer: 1503,
    PauseTimer: 1504,
    TeamScoreChange: 1200,
    BothTeamsScoreChange: 1411,
};
