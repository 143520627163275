import { useEffect } from 'react';

import { useTargetName } from './useTargetName';

export const useAddTargetNameClassToBody = () => {
    const { targetName } = useTargetName();
    useEffect(() => {
        document.body.classList.add(targetName.toLowerCase());
    }, [targetName]);
};
