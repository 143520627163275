import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useFoulStatistics = (subtextLabel = 'statistics.fouls') => {
    const [translate] = useTranslation();

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;
    const { fouls } = statistics;

    const homeStat = fouls[homeTeam].length;
    const awayStat = fouls[awayTeam].length;

    return {
        homeStat,
        awayStat,
        label: translate(subtextLabel),
    };
};
