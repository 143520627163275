import { ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

export const SetLastSetTieBreakEvent = new Event({
    type: ModelMap.SetLastSetTieBreak,
    renames: ['tieBreakLastSet'],
    calculate(store, evtData) {
        const { tieBreakLastSet } = evtData;
        // eslint-disable-next-line no-param-reassign
        store.tieBreakLastSet = !!tieBreakLastSet;
    },
});
