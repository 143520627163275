import React from 'react';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';

import { animationDataMap } from 'features/futsal/animations/data';

export function FieldIcon(props) {
    const { animationName, team } = props;

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: animationDataMap[animationName],
    });

    const leftOffset = team === 1 ? '58%' : '42%';
    const transform = team === 1 ? 'translate(-50%, -50%) scaleX(1)' : ' translate(-50%, -50%) scaleX(-1)';
    return (
        <div className="futsal-field-icon" style={{ left: leftOffset, transform }}>
            <Lottie lottieRef={lottieRef} width="100%" height="100%" />
        </div>
    );
}

FieldIcon.propTypes = {
    animationName: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
};
