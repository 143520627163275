/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

export const ServeEvent = new Event({
    type: ModelMap.Serve,
    animationName: AnimationMap.Serve,
    renames: [],
    calculate(store, evtData) {
        const { servesData } = store.translationData.statistics;
        evtData.team = servesData.currentTeam;
        evtData.serveCorner = servesData.currentCorner;
        evtData.serveNumber = servesData.currentNumber;
    },
});
