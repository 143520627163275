import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

// Move to common logic
const pushToLog = (log, eventData, store) => {
    const { sets } = store.translationData.statistics;
    log.push({
        component: 'GeneralLogEvent',
        text: i18n.t('log.break'),
        id: eventData.id,
        type: 'break',
        translucent: !0,
        period: sets[sets.length - 1].number,
    });
};

export const BathroomBreakEvent = new Event({
    type: ModelMap.BathroomBreak,
    animationName: AnimationMap.BathroomBreak,
    renames: ['team', 'time'],
    pushToLog(log, eventData, store) {
        pushToLog(log, eventData, store);
    },
});
