export const AnimationMap = {
    MatchCountdown: 'matchCountdown',
    Serve: 'serve',
    SecondServe: 'secondServe',
    Score: 'score',
    TennisGameEnd: 'tennisGameEnd',
    TennisSetEnd: 'tennisSetEnd',
    MedicalBreak: 'medicalBreak',
    WeatherBreak: 'weatherBreak',
    BathroomBreak: 'bathroomBreak',
    CallForTrainer: 'callForTrainer',
    TimerPaused: 'timerPaused',
    TimerStarted: 'timerStarted',
    PlayersWarmUp: 'playersWarmUp',
    Violation: 'violation',
    DoubleFoul: 'doubleFoul',
    Ace: 'ace',
    MatchEnd: 'matchEnd',
    Challenge: 'challenge',
    // ChallengeNoTeam: 'challengeNoTeam',
};
