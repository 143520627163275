export const AnimationMap = {
    BallPossession: 'ballPossession',
    PlayersWarmUp: 'playersWarmUp',
    Scrum: 'scrum',
    Try: 'try',
    FreeKick: 'freeKick',
    LineBreak: 'lineBreak',
    Conversion: 'conversion',
    Score: 'score',
    Substitution: 'substitution',
    Out: 'out',
    PeriodEnd: 'periodEnd',
    PlayersOnField: 'playersOnField',
    MatchEnd: 'matchEnd',
    PeriodStarted: 'periodStarted',
    Card: 'card',
    LineOut: 'lineOut',
    BallPosition: 'ballPosition',
};
