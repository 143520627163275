import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

class Out extends Event {
    constructor(props) {
        const { type, redirect, animationName } = props;
        super({
            type,
            redirect,
            animationName,
            // renames: ['team', 'outType', 'period', 'time'],
            calculate(store, { time, team, period }) {
                store.translationData.statistics.outs[team].push({
                    time,
                    period,
                });
                // eslint-disable-next-line no-param-reassign
                store.translationData.statistics.lastBypassBallPositionEventData = {
                    time,
                    team,
                };
            },
        });
    }
}

export const OutEvent = new Out({
    type: ModelMap.Out,
    animationName: AnimationMap.Out,
    redirect: (evt, statistics) => !!statistics.ballPoint && { ...evt, type: ModelMap.OutWithPosition },
});

export const OutWithPositionEvent = new Out({
    type: ModelMap.OutWithPosition,
    animationName: AnimationMap.OutWithPosition,
});
