import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useFreeThrowStatistic = (subtextLabel = 'statistics.freeThrows') => {
    const [translate] = useTranslation();

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;
    const { freeThrows, freeThrowsRealizations } = statistics;

    function getFreeThrowsRealizations(teamIndex) {
        return [
            freeThrowsRealizations[teamIndex].length,
            freeThrows[teamIndex].reduce((acc, curr) => acc + curr.count, 0),
        ];
    }

    const homeFreeThrowRatio = getFreeThrowsRealizations(homeTeam);
    const awayFreeThrowRatio = getFreeThrowsRealizations(awayTeam);

    const homeStat = `${homeFreeThrowRatio[0]} / ${homeFreeThrowRatio[1]}`;
    const awayStat = `${awayFreeThrowRatio[0]} / ${awayFreeThrowRatio[1]}`;

    return {
        homeStat,
        awayStat,
        label: translate(subtextLabel),
    };
};
