import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/bigTennis/constants';
import { FullRectangle } from './common/FullRectangle';

export function TimerStartedAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div className="text-white" ref={nodeRef}>
            <FullRectangle />
            <div className="abs-center items-center">
                <p className="big-tennis-text-primary-accent animation__field--text-action">
                    {translate('animation.timerStarted')}
                </p>
            </div>
        </div>
    );
}

TimerStartedAnimation.ANIMATION_NAME = AnimationMap.TimerStarted;
