import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Course } from 'ui/atoms/Icons/Course';
// eslint-disable-next-line import/no-cycle
import { CurrentScreen } from 'features/common/providers/CurrentScreenProvider';

export function CourseTab(props) {
    const [translate] = useTranslation('common');
    const { setActiveScreen, wrapperClasses, textClasses } = props;

    function handleClick() {
        setActiveScreen(CurrentScreen.course);
    }

    return (
        <div onClick={handleClick} className={wrapperClasses}>
            <Course className="h-6 fill-current mr-5px" width={18} height={21} />
            <p className={textClasses}>{translate('tabs.eventLog')}</p>
        </div>
    );
}

CourseTab.propTypes = {
    setActiveScreen: PropTypes.func.isRequired,
    wrapperClasses: PropTypes.string.isRequired,
    textClasses: PropTypes.string.isRequired,
};
