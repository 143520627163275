import { AnimationMap, ModelMap } from 'features/football/constants';
import { Event } from './event.model';

class FreeKick extends Event {
    constructor(props) {
        const { type, redirect, animationName } = props;
        super({
            type,
            redirect,
            animationName,
            // renames: ['team', 'period', 'time'],
            calculate(store, evt) {
                const { period, time, team } = evt;
                store.translationData.statistics.freeKicks[team].push({
                    period,
                    time,
                    isDangerous: evt.type === ModelMap.DangerousFreeKick,
                });
                // eslint-disable-next-line no-param-reassign
                store.translationData.statistics.lastBypassBallPositionEventData = {
                    time,
                    team,
                };
                // eslint-disable-next-line no-param-reassign
                store.translationData.statistics.freeThrowTeam = team;
            },
        });
    }
}

export const FreeKickEvent = new FreeKick({
    type: ModelMap.FreeKick,
    animationName: AnimationMap.FreeKick,
    redirect: (statistics, evt) => !!statistics.ballPoint && { ...evt, type: ModelMap.DangerousFreeKick },
});
export const FreeKickSportLevelEvent = new FreeKick({
    type: ModelMap.FreeKickSportLevel,
    animationName: AnimationMap.FreeKickSportLevel,
    redirect: (statistics, evt) => !!statistics.ballPoint && { ...evt, type: ModelMap.DangerousFreeKick },
});

export const FreeKickEventWithPosition = new FreeKick({
    type: ModelMap.FreeKickWithPosition,
    animationName: AnimationMap.FreeKickWithPosition,
});

export const FreeKickMiss = new Event({
    type: ModelMap.FreeKickMiss,
    // renames: ['team', 'time', 'period'],
    animationName: AnimationMap.FreeKickMiss,
    redirect(event, statistics) {
        const { gameTimePenalty, isPenaltyStarted, freeThrowTeam } = statistics;
        const isPenalty = isPenaltyStarted || gameTimePenalty;
        const team = isPenaltyStarted ? event.team : freeThrowTeam;
        return {
            ...event,
            team,
            // i1: team,
            type: isPenalty ? ModelMap.PenaltyMiss : ModelMap.FreeKickMiss[1],
        };
    },
    isPreventProcessing: (event) => event.type === ModelMap.FreeKickMiss[0],
});
