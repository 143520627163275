/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';

export const SecondServeEvent = new Event({
    type: ModelMap.SecondServe,
    animationName: AnimationMap.Serve,
    renames: [],
    calculate(store, eventData) {
        const {
            statistics: { servesData },
        } = store.translationData;
        const { currentTeam, currentCorner } = servesData;

        servesData.currentNumber = 2;
        eventData.team = currentTeam;
        eventData.serveCorner = currentCorner;
    },
    pushAdditionalEvents(evt, store) {
        let additionalEvents = [];
        if (store.translationData.info.gateway_code === 62) {
            additionalEvents = [
                {
                    ...evt,
                    isFakeEvent: true,
                    type: ModelMap.Serve,
                },
            ];
        }
        return additionalEvents;
    },
});
