import i18n from 'i18n';

import { AnimationMap, ModelMap } from 'features/bigTennis/constants';
import { Event } from 'features/common/models/event.model';
import { getTieBreakType } from '../utils/common';

// Move to common logic
const Q = (log, eventData, store) => {
    const { isSetEnd, team } = eventData;
    let { game } = eventData;
    const { translationData, homeTeam, awayTeam } = store;
    const { score, games } = translationData.statistics.sets.find((t) => t.number === eventData.set);
    const isGatewayCode62 = translationData.info.gateway_code === 62;
    const isFirstGame = isGatewayCode62 && game === 1 && games.length !== 1;
    const isTieBreak = (evtData) => !!getTieBreakType(evtData, store);
    if (!isSetEnd || !isFirstGame) game -= 1;
    if (!isTieBreak(eventData)) {
        const l = (t) => (score[t] === 50 ? 'Ad' : score[t]);
        const gameNumber = isSetEnd ? games.length : game;
        log.push({
            component: 'PeriodLogEvent',
            type: 'gameEnd',
            firstTeamScore: l(homeTeam),
            secondTeamScore: l(awayTeam),
            periodText: i18n.t('log.gameEnd', {
                game: `${gameNumber}`,
            }),
            period: eventData.set,
            game: gameNumber,
            id: log.length,
            wonTeam: team,
        });
    }
};

export const TennisSetEndEvent = new Event({
    type: ModelMap.TennisSetEnd,
    animationName: AnimationMap.TennisSetEnd,
    pushToLog(log, eventData, store) {
        Q(log, eventData, store);
        const { set } = eventData;
        const i = {
            1: eventData.score1,
            2: eventData.score2,
        };

        log.push({
            component: 'PeriodLogEvent',
            type: 'setEnd',
            firstTeamScore: i[window.HOME],
            secondTeamScore: i[window.AWAY],
            periodText: i18n.t('log.setEnd', {
                set: `${set}`,
            }),
            period: set,
            id: log.length,
        });
    },
    // quotes
    // calculate() {
    //     const t = window.$store.getters['stats/actualGame'].number % 2 ? 15 : 10;
    //     lt(['game-result', 'set-result', 'set-total'], t);
    // }
});
