/* eslint-disable max-len */
import * as React from 'react';

import { useTargetName } from 'hooks';

const Removal = (props) => {
    const { classMap: icon } = useTargetName((targets) => ({
        [targets.MOSTBET]: (
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M10 0C4.49 0 0 4.49 0 10s4.49 10 10 10 10-4.49 10-10S15.51 0 10 0Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm4.24-10.83L11.41 10l2.83 2.83a.996.996 0 0 1-.71 1.7c-.26 0-.51-.1-.71-.29L10 11.41l-2.83 2.83c-.2.2-.45.29-.71.29-.26 0-.51-.1-.71-.29a.996.996 0 0 1 0-1.41L8.59 10 5.76 7.17a.996.996 0 1 1 1.41-1.41L10 8.59l2.83-2.83a.996.996 0 1 1 1.41 1.41Z"
                    fill="#256569"
                />
            </svg>
        ),
        [targets.BETANDREAS]: (
            <svg {...props} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 2a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm3.24 7.17L11.41 11l1.83 1.83a1 1 0 0 1-.326 1.626 1 1 0 0 1-.384.074 1 1 0 0 1-.71-.29L10 12.41l-1.83 1.83a1 1 0 0 1-.71.29 1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.41L8.59 11 6.76 9.17a1 1 0 1 1 1.41-1.41L10 9.59l1.83-1.83a1 1 0 1 1 1.41 1.41Z"
                    fill="#9393A9"
                />
            </svg>
        ),
        [targets.VIVI]: (
            <svg {...props} width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.15 9.23a6.15 6.15 0 1 0-12.3 0 6.15 6.15 0 0 0 12.3 0ZM4.54.63h4.92M11.92 3.08l-1.23 1.23M5 11l4-4M5 7l4 4"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    }));

    return icon;
};

export default Removal;
