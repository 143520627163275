import * as Sentry from '@sentry/react';
import React from 'react';
import PropTypes from 'prop-types';

import { AppError } from './AppError';

export function ErrorBoundary({ children, isInline, fallback }) {
    const Error = fallback || (() => <AppError isInline={isInline} />);
    return <Sentry.ErrorBoundary fallback={<Error />}>{children}</Sentry.ErrorBoundary>;
}

ErrorBoundary.propTypes = {
    isInline: PropTypes.bool,
    fallback: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};
ErrorBoundary.defaultProps = {
    isInline: false,
    fallback: null,
};
