/* eslint-disable no-param-reassign */
import { Event } from 'features/common/models/event.model';
import { ModelMap } from 'features/handball/constants';

export const SevenMeterThrowMissedEvent = new Event({
    type: ModelMap.SevenMeterThrowMissed,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        statistics.sevenMeterThrowsFails[eventData.team].push({
            period: statistics.currentPeriod,
            time: eventData.time,
            id: eventData.id,
        });
    },
    redirect: (event, statistics) => !!statistics.penaltyShootoutStarted && { ...event, type: ModelMap.PenaltyMiss },
});
