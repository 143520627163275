/* eslint-disable no-param-reassign */
import { AnimationMap, ModelMap } from 'features/beachVolleyball/constants';
import { Event } from './event.model';

export const ServeEvent = new Event({
    type: ModelMap.Serve,
    animationName: AnimationMap.Serve,
    calculate(store, evtData) {
        const { statistics } = store.translationData;
        evtData.serveCorner = 'right';
        evtData.team = statistics.servingTeam;
    },
});
