import { AnimationMap, ModelMap } from 'features/futsal/constants';
import { Event } from './event.model';

export const PenaltyGoalEvent = new Event({
    type: ModelMap.PenaltyGoal,
    animationName: AnimationMap.PenaltyGoal,
    calculate(store, { team, period, time }) {
        if (store.translationData.statistics.isPenaltyStarted) {
            store.translationData.statistics.penaltyShootout[team].push({
                time,
                period,
                type: 'goal',
            });
        }
    },
});
