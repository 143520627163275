export const AnimationMap = {
    MatchCountdown: 'matchCountdown',
    PeriodCountdown: 'periodCountdown',
    BallPossession: 'ballPossession',
    Foul: 'foul',
    TeamTimeout: 'teamTimeout',
    Timeout: 'timeout',
    PlayersOnField: 'playersOnField',
    ScoreChange: 'scoreChange',
    Fight: 'fight',
    PeriodStart: 'periodStart',
    TimerPaused: 'timerPaused',
    PeriodEnd: 'periodEnd',
    // ↓ there is no such event in FB, they re-use period end for 1103
    MatchEnd: 'matchEnd',
    Removal: 'removal',
    Miss: 'miss',
    FreeThrow: 'freeThrow',
    FreeThrowGoal: 'freeThrowGoal',
    FreeThrowNotScored: 'freeThrowNotScored',
    FreeThrowCanceled: 'freeThrowCanceled',
};
