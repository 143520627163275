export const AnimationMap = {
    Attack: 'attack',
    BallPossession: 'ballPossession',
    BallPosition: 'ballPosition', //  todo [drone] maybe to remove
    DangerousAttack: 'dangerousAttack',
    FreeKick: 'freeKick',
    FreeKickMiss: 'freeKickMiss',
    FreeKickWithPosition: 'freeKickWithPosition',
    PenaltyMiss: 'penaltyMiss',
    PenaltyShootoutStart: 'penaltyShootoutStart',
    PenaltyShootoutEnd: 'penaltyShootoutEnd',
    PenaltyGoal: 'penaltyGoal',
    Penalty: 'penalty',
    PeriodStart: 'periodStart',
    PlayersOnField: 'playersOnField',
    MatchEnd: 'matchEnd',
    HalfTimeEnd: 'halfTimeEnd',
    Goal: 'goal',
    CornerKick: 'cornerKick',
    CornerKickWithPosition: 'cornerKickWithPosition',
    YellowCard: 'yellowCard',
    RedCard: 'redCard',
};
