export const WindowSize = {
    xs: 375,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
};

export const WindowProportions = {
    defaultWidthProportion: 700,
    defaultHeightProportion: 373,
    heightRatio() {
        return WindowProportions.defaultHeightProportion / WindowProportions.defaultWidthProportion;
    },
};
