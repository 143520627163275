import { getValueByParametrCssVariable } from './getValueByParametrCssVariable';

export const getWindowProportions = {
    heightRatio() {
        const width = getValueByParametrCssVariable('--field-width');
        const height = getValueByParametrCssVariable('--field-height');

        return height / width;
    },
};
