/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { Check } from 'ui/atoms/Icons/Check';
// eslint-disable-next-line import/no-cycle
import { Cross } from 'ui/atoms/Icons/Cross';
import { AnimationMap } from 'features/football/constants';
import { Circle } from './common/Circle';

export function PenaltySeriesAnimation() {
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <div className="penaltySeries _left">
                <div className="penaltySeries_status">
                    <Check className="w-2 h-2 sm:w-4 sm:h-4" />
                </div>
                <div className="penaltySeries_status">
                    <Check className="w-2 h-2 sm:w-4 sm:h-4" />
                </div>
                <div className="penaltySeries_status" />
                <div className="penaltySeries_status" />
                <div className="penaltySeries_status" />
            </div>
            <Circle
                title={translate('action.penaltySeries.animation')}
                subText={translate('team1')}
                animationName="penaltySeries"
                ref={rectRef}
                withStats
            />
            <div className="penaltySeries _right">
                <div className="penaltySeries_status">
                    <Cross className="w-2 h-2 sm:w-4 sm:h-4" />
                </div>
                <div className="penaltySeries_status">
                    <Check className="w-2 h-2 sm:w-4 sm:h-4" />
                </div>
                <div className="penaltySeries_status" />
                <div className="penaltySeries_status" />
                <div className="penaltySeries_status" />
            </div>
        </div>
    );
}

PenaltySeriesAnimation.ANIMATION_NAME = AnimationMap.PenaltySeries;
