export const ModelMap = {
    MatchCountdown: -2e4,
    ScoreWithAnimation: 1211,
    Score: 10999,
    Serve: -1211,
    Point: -1120,
    SetOpeningTeam: 1212,
    SetEndOfSet: 11102,
    MatchEnd: -1103,
    MatchEndWithAnimation: 1103,
    Challenge: 1241,
    YellowCard: 1108,
    RedCard: 1109,
    PlayersWarmUp: 1031,
    VideoReferee: 1100161,
    MedicalTimeout: 1111040,
    SetStart: 1118,
    PlayersOnField: 1206,
    GameIsDelayed: 1111034,
    GameStartsSoon: 1111035,
    TechnicalTimeout: 11110400,
    TechnicalTimeoutEnd: 11110401,
    Timeouts: 1111043,
    MatchResumed: 1111044,
};
