import { ModelMap } from 'features/tableTennis/constants';
import { Event } from './event.model';

export const BouncesEvent = new Event({
    type: ModelMap.Bounces,
    // animationName: AnimationMap.Bounces,
    isPreventProcessing(_, store) {
        const { serves } = store.translationData.statistics;
        const { bounces } = serves[serves.length - 1];
        return !bounces.length;
    },
    isDuplicate: (_, processedLastEventData) => processedLastEventData.type === ModelMap.Bounces,
});
